import React, { Component } from 'react';
import AuthIdentityContext, {
  AuthIdentityInterface
} from '../../Auth/AuthIdentityContext';
import { withRouter, RouteComponentProps } from 'react-router';
import gql from 'graphql-tag';
import { PageHeader, Typography, Button, Icon, Card, Spin } from 'antd';
import { Query, Mutation } from 'react-apollo';
import { Loading } from '../../Utils/Loading';
import { Error } from '../../Utils/Error';
import { TeamInviteContext } from '../../Invite/TeamInvite';
import './InviteJoining.less';

const loadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export const GET_TEAM_INVITE = gql`
  query TeamInvite($email: String!, $inviteContext: TeamInviteContext!) {
    teamInvite(email: $email, inviteContext: $inviteContext) {
      email
      uuid
      inviteToken
      businessUuid
      name
    }
  }
`;

const JOIN_BUSINESS = gql`
  mutation JoinBusiness(
    $memberId: String!
    $businessUuid: String!
    $inviteToken: String!
    $inviteContext: TeamInviteContext!
  ) {
    joinBusiness(
      memberId: $memberId
      businessUuid: $businessUuid
      inviteToken: $inviteToken
      inviteContext: $inviteContext
    ) {
      admins
      members
      membershipPlan
      name
      businessType
      uuid
    }
  }
`;

export const INVALIDATE_TEAM_INVITE = gql`
  mutation InvalidateTeamInvite(
    $businessUuid: String!
    $inviteContext: TeamInviteContext!
    $inviteToken: String!
  ) {
    invalidateTeamInvite(
      businessUuid: $businessUuid
      inviteContext: $inviteContext
      inviteToken: $inviteToken
    ) {
      businessUuid
      email
      inviteToken
      isActive
      name
      uuid
    }
  }
`;

interface Data {
  teamInvite: {
    email: string;
    inviteToken: string;
    uuid: string;
    businessUuid: string;
    name: string;
  };
}

interface Variables {
  email: string;
  inviteContext: string;
}

interface JoinBusinessMutationVariables {
  memberId: string;
  businessUuid: string | undefined;
  inviteToken: string | undefined;
  inviteContext: string;
}

interface JoinBusinessMutationData {
  joinBusiness: {
    admins: string[];
    members: string[];
    membershipPlan: string[];
    name: string;
    businessType: string;
    uuid: string;
  };
}

interface InvalidateInviteMutationVariables {
  businessUuid: string | undefined;
  inviteToken: string | undefined;
  inviteContext: string;
}

interface InvalidateInviteMutationData {
  invalidateTeamInvite: {
    businessUuid: string;
    inviteToken: string;
    isActive: boolean;
    email: string;
    name: string;
    uuid: string;
  };
}

interface InviteJoiningProps {
  context: TeamInviteContext;
  businessRefetch(): void;
}

class BusinessInviteJoining extends Component<
  RouteComponentProps & InviteJoiningProps
> {
  public static contextType: React.Context<
    AuthIdentityInterface
  > = AuthIdentityContext;

  public render(): JSX.Element {
    const context = TeamInviteContext[this.props.context];

    return (
      <div>
        <PageHeader title="makersPalm" />
        <div className="InviteJoiningContainer" data-testid="invite-joining">
          <Query<Data, Variables>
            query={GET_TEAM_INVITE}
            variables={{ email: this.context.email, inviteContext: context }}
          >
            {({ loading, error, data, refetch, networkStatus }) => {
              if (networkStatus === 4)
                return (
                  <Loading
                    data-testid="refetchSpinner"
                    className="InviteJoiningFormAnimation"
                    message="loading..."
                  />
                );
              if (loading)
                return (
                  <Loading
                    data-testid="loadingSpinner"
                    className="InviteJoiningFormAnimation"
                  />
                );
              if (error) return <Error />;

              return (
                <div className="InviteJoiningContent">
                  <Typography.Title level={1}>
                    Welcome,{' '}
                    {
                      this.context['http://makerspalm.com/user_metadata']
                        .first_name
                    }
                    !
                  </Typography.Title>

                  <Typography className="InviteJoiningContentSubText">
                    It doesn't look like your a member of any Teams. Pending
                    Invitations will appear below. Accept an invite to join
                    their Dashboard.
                  </Typography>

                  {data && data.teamInvite == undefined ? (
                    <Typography.Title
                      style={{ color: '#4ABDAC', marginTop: '30%' }}
                      level={3}
                    >
                      {' '}
                      <Icon type="mail" /> No Invites Found
                    </Typography.Title>
                  ) : (
                    <Mutation<
                      JoinBusinessMutationData,
                      JoinBusinessMutationVariables
                    >
                      onCompleted={() => this.props.businessRefetch()}
                      mutation={JOIN_BUSINESS}
                      variables={{
                        businessUuid: data
                          ? data.teamInvite.businessUuid
                          : undefined,
                        inviteContext: context,
                        inviteToken: data
                          ? data.teamInvite.inviteToken
                          : undefined,
                        memberId: this.context.sub
                      }}
                    >
                      {(
                        joinBusiness: () => void,
                        { loading, error, called }
                      ) => {
                        if (loading) return <Loading />;
                        if (error) return <Error />;
                        if (called)
                          return <Loading message="Joining your new Team!" />;
                        return (
                          <Card
                            size="small"
                            title="Pending Invitation"
                            extra={
                              <Icon
                                style={{ color: '#4ABDAC' }}
                                type="usergroup-add"
                              />
                            }
                            className="InvitationCard"
                          >
                            <p>
                              <Typography.Text
                                style={{ color: 'black' }}
                                strong
                              >
                                {data ? data.teamInvite.name : null}
                              </Typography.Text>
                            </p>
                            <p>has requested you to join their Team!</p>

                            <div
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Button
                                size="default"
                                data-testid="initial-continue-button"
                                type="primary"
                                onClick={() => joinBusiness()}
                              >
                                Accept
                              </Button>
                              <Mutation<
                                InvalidateInviteMutationData,
                                InvalidateInviteMutationVariables
                              >
                                onCompleted={() => refetch()}
                                mutation={INVALIDATE_TEAM_INVITE}
                                variables={{
                                  businessUuid: data
                                    ? data.teamInvite.businessUuid
                                    : undefined,
                                  inviteContext: context,
                                  inviteToken: data
                                    ? data.teamInvite.inviteToken
                                    : undefined
                                }}
                              >
                                {(
                                  invalidateInvite: () => void,
                                  { loading, error, called }
                                ) => {
                                  if (loading)
                                    return <Spin indicator={loadingIcon} />;
                                  if (error)
                                    return (
                                      <Icon
                                        style={{ color: 'red' }}
                                        type="warning"
                                      />
                                    );
                                  if (called)
                                    return <Spin indicator={loadingIcon} />;
                                  return (
                                    <Button
                                      size="small"
                                      style={{ color: 'gray' }}
                                      data-testid="initial-continue-button"
                                      type="link"
                                      onClick={() => invalidateInvite()}
                                    >
                                      Decline
                                    </Button>
                                  );
                                }}
                              </Mutation>
                            </div>
                          </Card>
                        );
                      }}
                    </Mutation>
                  )}
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export const InviteJoining = withRouter(BusinessInviteJoining);
