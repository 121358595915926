import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import { Tabs, Typography, Button, Icon, Drawer, Affix } from 'antd';
import { DesignerInvitesList } from './components/DesignerInvitesList';
import { FactoryInvitesList } from './components/FactoryInvitesList';
import { CreateInviteDrawerForm } from './components/CreateInviteDrawerForm';
import gql from 'graphql-tag';
import './AdminInvites.less';
import MediaQuery from 'react-responsive';

const DRAWER_WIDTH = 360;
const MOBILE_ADD_INVITE_BUTTON_OFFSET = 10;

export const GET_FACTORY_INVITES = gql`
  query {
    factoryInvites {
      email
      firstName
      lastName
      expirationDate
      uuid
      isExpired
      userSignedUp
      inviteToken
    }
  }
`;

export const GET_DESIGNER_INVITES = gql`
  query {
    designerInvites {
      email
      firstName
      lastName
      expirationDate
      uuid
      isExpired
      userSignedUp
      inviteToken
    }
  }
`;

export interface factoryInvite {
  email: string;
  firstName: string;
  lastName: string;
  expirationDate: string;
  uuid: string;
  isExpired: boolean;
  userSignedUp: boolean;
  inviteToken: string;
}

export interface designerInvite {
  email: string;
  firstName: string;
  lastName: string;
  expirationDate: string;
  uuid: string;
  isExpired: boolean;
  userSignedUp: boolean;
  inviteToken: string;
}

interface stateProps {
  visible: boolean;
}

const { TabPane } = Tabs;

class AdminInvitesComponent extends Component<RouteComponentProps> {
  public state: stateProps = { visible: false };

  public showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  public onClose = () => {
    this.setState({
      visible: false
    });
  };

  public renderNewInviteButton = () => (
    <Button
      data-testid="new-invite-button"
      onClick={this.showDrawer}
      type="primary"
      style={{ boxShadow: 'none', marginRight: 40 }}
    >
      <Icon type="plus" />
      New Invite
    </Button>
  );

  public render(): JSX.Element {
    return (
      <div>
        <div className="AdminInvitesHeaderContainer">
          <Typography.Title level={2}>Invites</Typography.Title>
          <Typography.Text>
            Send Invites to users to join the makersPalm Platform and create
            their Business
          </Typography.Text>
        </div>
        <MediaQuery query="(min-device-width: 768px)">
          <Tabs
            tabBarExtraContent={this.renderNewInviteButton()}
            tabBarStyle={{ paddingLeft: 40, borderColor: 'lightgray' }}
            animated={true}
            defaultActiveKey="designer"
          >
            <TabPane tab="Designer Invites" key="designer">
              <DesignerInvitesList />
            </TabPane>
            <TabPane tab="Factory Invites" key="factory">
              <FactoryInvitesList />
            </TabPane>
          </Tabs>

          <Drawer
            destroyOnClose={true}
            title="Create a new Invite"
            width={DRAWER_WIDTH}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <CreateInviteDrawerForm onClose={this.onClose} />
          </Drawer>
        </MediaQuery>

        <MediaQuery query="(max-device-width: 767px)">
          <Tabs
            animated={true}
            defaultActiveKey="designer"
            tabBarStyle={{ textAlign: 'center' }}
          >
            <TabPane tab="Designer Invites" key="designer">
              <DesignerInvitesList />
            </TabPane>
            <TabPane tab="Factory Invites" key="factory">
              <FactoryInvitesList />
            </TabPane>
          </Tabs>

          <Drawer
            destroyOnClose={true}
            title="Create a new Invite"
            width={'100%'}
            height={'100%'}
            placement="bottom"
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <CreateInviteDrawerForm onClose={this.onClose} />
          </Drawer>

          <Affix
            className="AdminInvitesAffix"
            offsetBottom={MOBILE_ADD_INVITE_BUTTON_OFFSET}
          >
            <Button
              size="large"
              data-testid="mobile-new-invite-button"
              shape="circle"
              type="primary"
              onClick={() => {
                this.showDrawer();
              }}
            >
              <Icon type="plus" />
            </Button>
          </Affix>
        </MediaQuery>
      </div>
    );
  }
}

export const AdminInvites = withRouter(AdminInvitesComponent);
