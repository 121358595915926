import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import './Projects.less';
import { BusinessContextInterface, pdProject } from '../../Routes';
import { Typography, Button, Modal, Layout } from 'antd';
import gql from 'graphql-tag';

import { ProjectsListView } from './components/ProjectsListView';
import { Query } from 'react-apollo';
import { InlineLoading, Loading } from '../../../Utils/Loading';
import { SimpleInlineError } from '../../../Utils/Error';
import { ProjectContentView } from './ProjectContent/ProjectContentView';
const { Sider, Header, Content } = Layout;
const { Title, Text } = Typography;
interface projectProps {
  businessContext: BusinessContextInterface;
  renderHeader: JSX.Element;
}

interface sourcingProjectData {
  pdProjects: pdProject[];
}

interface stateProps {
  selectedProjectId: string | undefined;
}

export const GET_PROJECTS = gql`
  query PdProjects {
    pdProjects {
      designerBusinessUuid
      designerBusinessName
      projectCategory
      projectContext
      projectName
      projectNotes
      projectStatus
      uuid
      projectFiles {
        authorContext
        authorId
        fileName
        isProjectAvatar
        storageKey
        uuid
        versionId
      }
    }
  }
`;

class ProjectsComponent extends Component<RouteComponentProps & projectProps> {
  public state: stateProps = {
    selectedProjectId: undefined
  };

  public handleProjectSelect = (selectedProjectId: string) => {
    this.setState(
      {
        selectedProjectId
      },
      () => {
        this.props.history.replace(
          `${this.props.match.url}/${selectedProjectId}/lifecycle`
        );
      }
    );
  };

  public getProjectById = (
    projectId: string,
    projects: pdProject[]
  ): pdProject | undefined => {
    return projects.find(project => project.uuid == projectId);
  };

  public render(): JSX.Element {
    return (
      <Query<sourcingProjectData> query={GET_PROJECTS}>
        {({ loading, error, data, refetch }) => {
          return (
            <Layout className="sourcing-projects-layout-container">
              <Sider
                data-testid="sourcing-dashboard-projects-side-view"
                className="sourcing-projects-side-view"
                collapsible={false}
              >
                <div
                  style={{
                    alignItems: 'left',
                    marginBottom: 8,
                    marginLeft: 8,
                    marginRight: 8,
                    marginTop: 16
                  }}
                >
                  <Title level={3}>Projects</Title>
                  {loading ? <Loading /> : null}
                  {!error ? (
                    <ProjectsListView
                      projects={data ? data.pdProjects : []}
                      handleProjectSelect={this.handleProjectSelect}
                      selectedProjectId={this.state.selectedProjectId}
                    />
                  ) : (
                    <SimpleInlineError />
                  )}
                </div>
              </Sider>

              <Layout>
                <Header className="dashboardHeader">
                  {this.props.renderHeader}
                </Header>

                <Content className="sourcing-projects-content">
                  <Switch>
                    <Route
                      path={`${this.props.match.path}/:projectUuid`}
                      render={props => (
                        <ProjectContentView
                          businessContext={this.props.businessContext}
                          project={
                            this.state.selectedProjectId
                              ? this.getProjectById(
                                  this.state.selectedProjectId,
                                  data ? data.pdProjects : ([] as pdProject[])
                                )
                              : ({} as pdProject)
                          }
                        />
                      )}
                    />
                    <Route render={props => this.renderNoProjectSelect()} />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          );
        }}
      </Query>
    );
  }

  private renderNoProjectSelect = (): JSX.Element => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        height: '100%'
      }}
    >
      <Text strong style={{ fontStyle: 'italic', fontSize: 18 }}>
        No project selected
      </Text>
    </div>
  );
}

export const Projects = withRouter(ProjectsComponent);
