import React, { Component } from 'react';
import { Typography, Icon } from 'antd';
import './Review.less';
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from 'react-apollo';
import { Loading } from '../../../../../Utils/Loading';
import { SimpleInlineError } from '../../../../../Utils/Error';
import DiscussionView, {
  fileAttachment
} from '../../../../components/DiscussionComponents/DiscussionView';
import { submittedProject, GET_SUBMITTED_PROJECTS } from '../../Projects';
import { SubmittedProjectDetailsView } from './components/SubmittedProjectDetailsView';
import _ from 'lodash';

const CONTEXT_TYPE = 'PROJECT';
const CONVO_TAG = 'REVIEW';

const { Text } = Typography;

const GET_CONVERSATION = gql`
  query Conversation(
    $contextType: String!
    $contextUuid: String!
    $convoTag: String!
  ) {
    conversation(
      contextType: $contextType
      contextUuid: $contextUuid
      convoTag: $convoTag
    ) {
      contextType
      contextUuid
      messages {
        authorEmail
        authorFirstName
        authorLastName
        authorUserId
        uuid
        messageText
        attachments {
          uuid
          fileName
          storageKey
        }
      }
      tag
      uuid
    }
  }
`;

const REQUEST_DESIGNER_SIGNED_STORAGE_URL = gql`
  query PdSignedStorageUrl($projectUuid: String!) {
    pdSignedStorageUrl(projectUuid: $projectUuid) {
      url
      urlParams
    }
  }
`;

interface ReviewProps {
  project: submittedProject;
}

export function Review(props: ReviewProps): JSX.Element {
  const client = useApolloClient();

  const { loading, error, data } = useQuery(GET_CONVERSATION, {
    variables: {
      contextType: CONTEXT_TYPE,
      contextUuid: props.project.uuid,
      convoTag: CONVO_TAG
    },
    fetchPolicy: 'cache-and-network'
  });

  const {
    loading: storageUrlLoading,
    error: storageUrlError,
    data: storageUrlData
  } = useQuery(REQUEST_DESIGNER_SIGNED_STORAGE_URL, {
    variables: {
      projectUuid: props.project.uuid
    }
  });

  const handleFileUploadMessage = (files: fileAttachment[]) => {
    const currentProjectFiles = client.readQuery({
      query: GET_SUBMITTED_PROJECTS,
      variables: {
        designerBusinessUuid: props.project.designerBusinessUuid
      }
    });

    console.log(currentProjectFiles);

    const projectIndex = currentProjectFiles.pdDesignerProjects.findIndex(
      (p: submittedProject) => p.uuid == props.project.uuid
    );

    const updatedFiles = files.map(f => {
      return {
        uuid: f.uuid,
        fileName: f.fileName,
        storageKey: f.storageKey,
        authorContext: f.authorContext,
        authorId: f.authorId,
        versionId: f.versionId,
        isProjectAvatar: false,
        __typename: 'Attachment'
      };
    });

    const newObj = _.cloneDeep(currentProjectFiles);

    newObj.pdDesignerProjects[
      projectIndex
    ].projectFiles = currentProjectFiles.pdDesignerProjects[
      projectIndex
    ].projectFiles.concat(updatedFiles);

    console.log(newObj.pdDesignerProjects);

    console.log(props.project.designerBusinessUuid);

    client.writeQuery({
      query: GET_SUBMITTED_PROJECTS,
      data: {
        pdDesignerProjects: newObj.pdDesignerProjects
      },
      variables: {
        designerBusinessUuid: props.project.designerBusinessUuid
      }
    });
  };

  if (loading || storageUrlLoading) {
    return <Loading />;
  }
  if (error || storageUrlError) {
    return (
      <div className="designer-review-project-message-container">
        <SimpleInlineError />
      </div>
    );
  }

  if (!data.conversation) {
    return (
      <div className="designer-review-project-message-container">
        <Text
          strong
          style={{ fontSize: 21 }}
          className="designer-review-project-message-text"
        >
          Our Sourcing Team is currently reviewing your Project! If we have any
          questions you'll get a notification to check back here.
        </Text>
        <Icon
          theme="filled"
          style={{ fontSize: 36, marginTop: 15, color: '#ff6961' }}
          type="heart"
        />
      </div>
    );
  }
  return (
    <div className="designer-review-items-container">
      <DiscussionView
        contextType={CONTEXT_TYPE}
        convoTag={CONVO_TAG}
        contextUuid={props.project.uuid}
        messages={data.conversation.messages}
        conversationUuid={data.conversation.uuid}
        designerBusinessUuid={props.project.designerBusinessUuid}
        storageUrlObj={storageUrlData.pdSignedStorageUrl}
        messageUploadCallback={handleFileUploadMessage}
      />
      <SubmittedProjectDetailsView
        project={props.project}
        storageUrlObj={storageUrlData.pdSignedStorageUrl}
      />
    </div>
  );
}
