import React, { Component } from 'react';
import './ReviewProjectDetailsView.less';
import { Row, Typography, Divider, Upload, Button, Icon } from 'antd';
import { pdSignedStorageUrlObj } from '../../../ProjectContentView';
import _ from 'lodash';
import { UploadFile } from 'antd/lib/upload/interface';
import { TOTAL_STEPS } from '../Review';
import { pdProjectFile, pdProject } from '../../../../../../Routes';

const { Text, Title } = Typography;

const LEVEL_4_HEADER_SIZE = 4;

interface ReviewProjectDetailsViewProps {
  currentStep: number;
  project: pdProject;
  storageUrlObj: pdSignedStorageUrlObj;
  minified: boolean;
  handleNext(): void;
  toggleDiscussion(): void;
}

const pStyle = {
  color: 'rgba(0,0,0,0.85)',
  display: 'block',
  fontSize: 16,
  lineHeight: '24px',
  marginBottom: 16
};

const renderContextMessage = (projectContext: string) => {
  switch (projectContext) {
    case 'SAMPLE':
      return 'Designer already has a sample made';
    case 'FILES':
      return 'Designer has CAD/Design files ready for the project';
    case 'SCRATCH':
      return 'Designer is starting from scratch';
    default:
      return 'Could not determine Project Context';
  }
};

class ReviewProjectDetailsViewComponent extends Component<
  ReviewProjectDetailsViewProps
> {
  public render(): JSX.Element {
    if (this.props.minified) {
      return (
        <div className="sourcing-review-project-details-container minified">
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingLeft: 10,
              paddingRight: 10,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Title style={{ padding: 10 }} level={LEVEL_4_HEADER_SIZE}>
              Review Project Details{' '}
              <Text style={{ fontSize: 12 }}>
                {this.props.currentStep}/{TOTAL_STEPS}{' '}
              </Text>
            </Title>

            <Button
              onClick={() => this.props.handleNext()}
              style={{ borderColor: '#4ABDAC' }}
              type="ghost"
            >
              Assign Factory
              <Icon type="arrow-right" />
            </Button>
          </span>
          <Divider />
          <Text style={{ padding: 10, fontSize: 16, color: 'black' }} strong>
            Project Context
          </Text>
          <Row style={{ padding: 15 }}>
            <Text strong>
              {renderContextMessage(this.props.project.projectContext)}
            </Text>
          </Row>
          <Divider />
          <Text style={{ padding: 10, fontSize: 16, color: 'black' }} strong>
            Project Files
          </Text>
          <Row style={{ padding: 15 }}>
            <Text strong>
              {this.renderProjectFiles(
                this.props.project.projectFiles,
                this.props.storageUrlObj
              )}
            </Text>
          </Row>
          <Divider style={{ width: 2 }} />
          <Text style={{ padding: 10, fontSize: 16, color: 'black' }} strong>
            Project Notes
          </Text>
          <Row style={{ padding: 15 }}>
            <Text strong>{this.props.project.projectNotes}</Text>
          </Row>
        </div>
      );
    }

    return (
      <div className="sourcing-review-project-details-container">
        <Title style={{ padding: 10 }} level={LEVEL_4_HEADER_SIZE}>
          Review Project Details{' '}
          <Text style={{ fontSize: 12 }}>
            {this.props.currentStep}/{TOTAL_STEPS}{' '}
          </Text>
        </Title>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 10,
            paddingRight: 10,
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Button
            icon="interaction"
            onClick={() => this.props.toggleDiscussion()}
            style={{ borderColor: '#4ABDAC' }}
            type="ghost"
          >
            Start Discussion
          </Button>
          <Button
            onClick={() => this.props.handleNext()}
            style={{ borderColor: '#4ABDAC' }}
            type="ghost"
          >
            Assign Factory
            <Icon type="arrow-right" />
          </Button>
        </span>
        <Divider />
        <Text style={{ padding: 10, fontSize: 16, color: 'black' }} strong>
          Project Context
        </Text>
        <Row style={{ padding: 15 }}>
          <Text strong>
            {renderContextMessage(this.props.project.projectContext)}
          </Text>
        </Row>
        <Divider />
        <Text style={{ padding: 10, fontSize: 16, color: 'black' }} strong>
          Project Files
        </Text>
        <Row style={{ padding: 15 }}>
          <Text strong>
            {this.renderProjectFiles(
              this.props.project.projectFiles,
              this.props.storageUrlObj
            )}
          </Text>
        </Row>
        <Divider />
        <Text style={{ padding: 10, fontSize: 16, color: 'black' }} strong>
          Project Notes
        </Text>
        <Row style={{ padding: 15 }}>
          <Text strong>{this.props.project.projectNotes}</Text>
        </Row>
      </div>
    );
  }

  private renderProjectFiles = (
    projectFiles: pdProjectFile[],
    storage_url_obj: pdSignedStorageUrlObj
  ): JSX.Element => (
    <span>
      <Upload
        style={{ height: '50%', display: 'flex', flexDirection: 'row' }}
        listType="picture-card"
        fileList={
          this.transformFileListToAntStandard(projectFiles) as UploadFile[]
        }
      ></Upload>
    </span>
  );

  private transformFileListToAntStandard = (projectFiles: pdProjectFile[]) => {
    //@ts-ignore
    const newList = _.reduce(
      projectFiles,
      (acc, cur) => {
        if (cur.authorContext == 'DESIGNER') {
          const transformedFile = {
            name: cur.fileName,
            size: 1,
            status: 'done',
            storageKey: cur.storageKey,
            thumbUrl:
              this.props.storageUrlObj.url +
              cur.storageKey +
              this.props.storageUrlObj.urlParams,
            type: 'image/jpeg',
            uid: cur.uuid,
            url:
              this.props.storageUrlObj.url +
              cur.storageKey +
              this.props.storageUrlObj.urlParams
          } as UploadFile;
          return acc.concat([transformedFile]);
        } else {
          return acc;
        }
      },
      [] as UploadFile[]
    );

    return newList;
  };
}

export const ReviewProjectDetailsView = ReviewProjectDetailsViewComponent;
