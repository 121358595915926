import React, { Component } from 'react';
import { Progress, PageHeader } from 'antd';
import './Onboarding.css';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import BusinessInvitesForm from '../components/BusinessInvitesForm';
import { WelcomeForm } from '../components/WelcomeForm';
import { BusinessNameForm } from '../components/BusinessNameForm';
import { BusinessPlanForm } from './components/BusinessPlanForm';
import { Loading } from '../../Utils/Loading';
import { Error } from '../../Utils/Error';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import AuthIdentityContext from '../../Auth/AuthIdentityContext';
import { withRouter } from 'react-router';

export const CREATE_DESIGNER_BUSINESS = gql`
  mutation CreateDesignerBusiness(
    $adminId: String!
    $membershipPlan: String!
    $emailInvitesList: [String]
    $name: String!
  ) {
    createDesignerBusiness(
      adminId: $adminId
      membershipPlan: $membershipPlan
      emailInvitesList: $emailInvitesList
      name: $name
    ) {
      admins
      uuid
      name
      members
      membershipPlan
      designerBusinessInvites {
        email
        isActive
        uuid
      }
    }
  }
`;

const RenderContent = (props: any, state: any) => {
  switch (props.currentStep) {
    case 0:
      return <WelcomeForm {...props} />;
    case 25:
      return <BusinessNameForm {...props} />;
    case 50:
      return <BusinessPlanForm {...props} />;
    case 75:
      return <BusinessInvitesForm {...props} />;
    default:
      return <div></div>;
  }
};

export interface OnboardingProps {
  next(): void;
  prev(): void;
}

export enum BusinessPlan {
  Beta,
  Member,
  Nonmember
}

interface DesignerBusinessInvites {
  email: string;
  isActive: boolean;
  uuid: string;
}

interface Data {
  createDesignerBusiness: {
    admins: string[];
    uuid: string;
    name: string;
    members: string[];
    membershipPlan: string;
    designerBusinessInvites: DesignerBusinessInvites[];
  };
}

interface Variables {
  adminId: string;
  membershipPlan: string;
  emailInvitesList: string[];
  name: string;
}

class BusinessOnboarding extends Component<any, any> {
  public static contextType: any = AuthIdentityContext;

  constructor(props: any) {
    super(props);
    this.state = {
      stepPercentage: 0,
      businessName: '',
      selectedBusinessPlan: BusinessPlan.Beta,
      businessEmailInvites: []
    };
  }

  public next(): void {
    const stepPercentage = this.state.stepPercentage + 25;
    this.setState({ stepPercentage });
  }

  public prev(): void {
    const stepPercentage = this.state.stepPercentage - 25;
    this.setState({ stepPercentage });
  }

  public handleBusinessNameChange(businessName: string): void {
    this.setState({
      businessName
    });
  }

  public handleBusinessPlanChange(selectedBusinessPlan: BusinessPlan): void {
    this.setState({
      selectedBusinessPlan
    });
  }

  public handleBusinessInvitesChange(
    businessEmailInvites: string[],
    complete: () => void
  ): void {
    this.setState(
      {
        businessEmailInvites: businessEmailInvites
      },
      () => {
        complete();
      }
    );
  }

  render() {
    return (
      <div>
        <PageHeader title="makersPalm" />
        <div className="OnboardingContainer" data-testid="onboarding">
          <Progress
            data-testid="progress-bar"
            percent={this.state.stepPercentage}
            status="active"
            strokeColor="#4ABDAC"
          />

          <Mutation<Data, Variables>
            onCompleted={() => this.props.businessRefetch()}
            mutation={CREATE_DESIGNER_BUSINESS}
            variables={{
              adminId: this.context.sub,
              emailInvitesList: this.state.businessEmailInvites,
              membershipPlan: BusinessPlan[this.state.selectedBusinessPlan],
              name: this.state.businessName
            }}
          >
            {(createDesignerBusiness: () => void, { loading, error }) => {
              if (loading) return <Loading message="Creating your Business" />;
              if (error) return <Error />;
              return (
                <RenderContent
                  currentStep={this.state.stepPercentage}
                  next={() => this.next()}
                  prev={() => this.prev()}
                  complete={() => {
                    this.next();
                    createDesignerBusiness();
                  }}
                  businessName={this.state.businessName}
                  businessPhoneNumber={this.state.businessPhoneNumber}
                  businessPhoneCountryCode={this.state.businessPhoneCountryCode}
                  selectedBusinessPlan={this.state.selectedBusinessPlan}
                  businessEmailInvites={this.state.businessEmailInvites}
                  handleBusinessPlanChange={(plan: BusinessPlan) =>
                    this.handleBusinessPlanChange(plan)
                  }
                  handleBusinessNameChange={(e: any) =>
                    this.handleBusinessNameChange(e.target.value)
                  }
                  handleBusinessInvitesChange={(
                    emails: string[],
                    complete: () => void
                  ) => this.handleBusinessInvitesChange(emails, complete)}
                />
              );
            }}
          </Mutation>
        </div>
      </div>
    );
  }
}

export const Onboarding = withRouter(BusinessOnboarding);
