import React, { Component, useContext } from 'react';
import { Route } from 'react-router-dom';
import Auth from '../Auth/Auth';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_PRIVATE_URL
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const businessType = localStorage.getItem('businessType');
  const businessUuid = localStorage.getItem('businessUuid');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: Auth.getAccessToken()
        ? `Bearer ${Auth.getAccessToken()}`
        : '',
      businessType: businessType ? businessType : '',
      businessUuid: businessUuid ? businessUuid : ''
    }
  };
});

const privateClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    dataIdFromObject: object => object.uuid || null
  })
});

const PrivateRoute = ({ component: Component, ...props }) => {
  localStorage.setItem('requestedPath', props.location.pathname);

  return (
    <Route
      {...props}
      render={innerProps =>
        Auth.isAuthenticated() ? (
          <ApolloProvider client={privateClient}>
            <Component {...innerProps} />
          </ApolloProvider>
        ) : (
          Auth.login()
        )
      }
    />
  );
};
export default PrivateRoute;
