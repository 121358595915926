import React, { Component } from 'react';
import Auth from '../Auth/Auth';
import { withRouter } from 'react-router';
import {
  AuthIdentityProvider,
  AuthIdentityInterface
} from '../Auth/AuthIdentityContext';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Loading } from '../Utils/Loading';
import { Error } from '../Utils/Error';
import { Routes } from '../Routes/Routes';

export const GET_BUSINESSES = gql`
  query Businesses($id: String!) {
    businesses(id: $id) {
      uuid
      admins
      businessType
      members
      name
      membershipPlan
    }
  }
`;

export interface business {
  uuid: string;
  admins: string[];
  businessType: string;
  members: string[];
  name: string;
  membershipPlan: string;
}

export interface Data {
  businesses: business[];
}

interface Variables {
  id: string;
}

class Home extends Component<any, any> {
  state = {
    authUserIdentity: Auth.getProfile() as AuthIdentityInterface
  };

  render() {
    this.resetBusinessContextHeaders();
    return (
      <Query<Data, Variables>
        query={GET_BUSINESSES}
        variables={{ id: this.state.authUserIdentity.sub }}
      >
        {({ loading, error, data, refetch }) => {
          if (error) return <Error />;

          if (loading)
            return (
              <div style={{ height: '100vh' }}>
                <Loading />
              </div>
            );

          if (data) {
            return (
              <AuthIdentityProvider value={this.state.authUserIdentity}>
                <Routes businessRefetch={refetch} data={data} />
              </AuthIdentityProvider>
            );
          }

          return null;
        }}
      </Query>
    );
  }

  private resetBusinessContextHeaders = () => {
    localStorage.removeItem('businessUuid');
    localStorage.removeItem('businessType');
  };
}

export default withRouter(Home);
