import auth0 from 'auth0-js';
import config from '../config';

class Auth {
  constructor(config) {
    this.clientID = config.clientID;

    this.auth0 = new auth0.WebAuth({
      domain: config.domain,
      clientID: config.clientID,
      redirectUri: config.callbackUrl,
      responseType: config.responseType,
      audience: config.audience,
      scope: config.scope
    });
  }

  // Returns the access token for use in authenticated XHR requests.
  getAccessToken = () => {
    return this.accessToken;
  };

  // Returns the idToken generated by Auth0 for the current user.
  getIdToken = () => {
    return this.idToken;
  };

  // Returns the profile of the authenticated user, if any.
  getProfile = () => {
    return this.profile;
  };

  // Called after the user is redirected from Auth0.  This method reads the hash segment
  // of the URL to fetch the user details and the id token.
  handleAuthentication(props) {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          resolve(authResult);
        } else if (err) {
          reject(err);
        }
      });
    });
  }

  // => Boolean
  isAuthenticated = () => {
    return new Date().getTime() < this.expiresAt;
  };

  // set auth creds on the instance
  setSession = (authResult, step) => {
    localStorage.setItem('isLoggedIn', 'true');
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
  };

  // Redirects to Auth0 login page
  login = () => {
    this.auth0.authorize();
  };

  // sets profile, id_token, expiresAt to null
  logout = () => {
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');

    this.auth0.logout({
      returnTo: window.location.origin,
      clientID: this.clientID
    });
  };

  renewSession = () => {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        console.log('me here');
        this.logout();
        console.log(err);
      }
    });
  };
}

const auth = new Auth(config.AUTH0);
export default auth;
