import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import { Typography, Tabs } from 'antd';
import './Settings.less';
import MediaQuery from 'react-responsive';
import { BusinessContextInterface } from '../../Routes';
import { TeamView } from '../../components/TeamViewSettings/TeamView';
import { TeamInviteContext } from '../../../Invite/TeamInvite';

const { TabPane } = Tabs;

interface SettingsProps {
  businessContext: BusinessContextInterface;
}

class SettingsComponent extends Component<RouteComponentProps & SettingsProps> {
  public renderSettingsPage = (isMobile: boolean) => (
    <div>
      <div className="SourcingSettingsHeaderContainer">
        <Typography.Title
          level={isMobile ? 3 : 2}
          data-testid="settings-page-header"
        >
          Settings
        </Typography.Title>
        <Typography.Text data-testid="settings-page-description">
          Manage settings related to your Business here
        </Typography.Text>
      </div>

      <Tabs
        animated={true}
        tabBarStyle={{ paddingLeft: 40, borderColor: 'lightgray' }}
        defaultActiveKey="team"
      >
        <TabPane
          className="SourcingSettingsTabPaneContainer"
          tab="Team"
          key="team"
        >
          <TeamView
            {...this.props}
            businessContext={this.props.businessContext}
            teamContext={TeamInviteContext.SOURCING_TEAM}
          />
        </TabPane>
      </Tabs>
    </div>
  );

  public render(): JSX.Element {
    return (
      <div>
        <MediaQuery query="(min-device-width: 768px)">
          {this.renderSettingsPage(false)}
        </MediaQuery>

        <MediaQuery query="(max-device-width: 767px)">
          {this.renderSettingsPage(true)}
        </MediaQuery>
      </div>
    );
  }
}

export const Settings = withRouter(SettingsComponent);
