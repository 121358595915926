import React, { Component } from 'react';
import { Typography, Divider, Button, Icon, List, Card } from 'antd';
import { TOTAL_STEPS } from '../Review';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Loading, InlineLoading } from '../../../../../../../Utils/Loading';
import { SimpleInlineError } from '../../../../../../../Utils/Error';
import './FactoryAssignView.less';
import { pdSignedStorageUrlObj } from '../../../ProjectContentView';

const { Meta } = Card;

const { Text, Title } = Typography;

const LEVEL_4_HEADER_SIZE = 4;

const GUTTER_SIZE_BIG = 32;
const GUTTER_SIZE_SMALL = 4;
const THREE_COLUMNS = 3;
const TWO_COLUMNS = 2;

interface factoryReference {
  uuid: string;
  name: string;
  logoStorageKey: string;
}

interface factoryReferenceData {
  factoryReferences: factoryReference[];
}

export const LIST_FACTORY_REFERENCES = gql`
  query FactoryReferences {
    factoryReferences {
      logoStorageKey
      name
      uuid
    }
  }
`;

interface FactoryAssignViewProps {
  assignedFactoryUuid: string | undefined;
  currentStep: number;
  minified: boolean;
  storageUrlObj: pdSignedStorageUrlObj;
  advanceProject(): void;
  handleAssignFactory(uuid: string): void;
  handleBack(): void;
}

class FactoryAssignViewComponent extends Component<FactoryAssignViewProps> {
  public render(): JSX.Element {
    return (
      <div
        className={
          'sourcing-review-factory-assign-container ' +
          (this.props.minified ? ' minified' : '')
        }
      >
        <Title style={{ padding: 10 }} level={LEVEL_4_HEADER_SIZE}>
          Assign a Factory{' '}
          <Text style={{ fontSize: 12 }}>
            {this.props.currentStep}/{TOTAL_STEPS}{' '}
          </Text>
        </Title>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 10,
            paddingRight: 10,
            justifyContent: 'flext-start',
            alignItems: 'left'
          }}
        >
          <Button
            onClick={() => this.props.handleBack()}
            icon="arrow-left"
            style={{ borderColor: '#4ABDAC' }}
            type="ghost"
          >
            Back
          </Button>
        </span>
        <span>
          <Divider />
        </span>
        <Query<factoryReferenceData> query={LIST_FACTORY_REFERENCES}>
          {({ loading, error, data }) => {
            if (loading) {
              return <InlineLoading />;
            }
            if (error) {
              return <SimpleInlineError />;
            }

            return (
              <div
                style={{
                  height: 'inherit',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignContent: 'center ',
                  paddingLeft: '15%',
                  paddingRight: '15%'
                }}
              >
                <List
                  grid={{
                    gutter: this.props.minified
                      ? GUTTER_SIZE_BIG
                      : GUTTER_SIZE_SMALL,
                    column: this.props.minified ? TWO_COLUMNS : THREE_COLUMNS
                  }}
                  itemLayout="horizontal"
                  dataSource={data ? data.factoryReferences : []}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        onClick={() =>
                          this.props.handleAssignFactory(item.uuid)
                        }
                        hoverable
                        style={{
                          width: 175,
                          height: 151,
                          padding: 5,
                          borderRadius: 10,
                          borderWidth: 1.5,
                          borderColor:
                            this.props.assignedFactoryUuid == item.uuid
                              ? '#4ABDAC'
                              : '#e8e8e8'
                        }}
                        cover={
                          <img
                            style={{ height: 65, padding: 10 }}
                            alt="factory"
                            src={
                              this.props.storageUrlObj.url +
                              item.logoStorageKey +
                              this.props.storageUrlObj.urlParams
                            }
                          />
                        }
                      >
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Text strong> {item.name}</Text>
                          {this.props.assignedFactoryUuid == item.uuid ? (
                            <Icon
                              type="check-circle"
                              style={{ color: '#4ABDAC', fontSize: 36 }}
                              theme="filled"
                            />
                          ) : (
                            <span />
                          )}
                        </span>
                      </Card>
                    </List.Item>
                  )}
                />
                <Button
                  onClick={() => this.props.advanceProject()}
                  style={{ marginBottom: '10%' }}
                  disabled={this.props.assignedFactoryUuid == undefined}
                  type="primary"
                >
                  Proceed to Development Stage
                  <Icon type="arrow-right" />
                </Button>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export const FactoryAssignView = FactoryAssignViewComponent;
