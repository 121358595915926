import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import './TeamInvite.css';
import { Typography } from 'antd';
import Lottie from 'react-lottie';
import animationData from '../Animations/Lotties/animation-invalid-invite.json';
import errorAnimation from '../Animations/Lotties/error-cloud.json';
import auth0 from 'auth0-js';
import { RouteComponentProps } from 'react-router-dom';
import { UserSignUpForm, SignUpFormProps } from './UserSignUpForm';
import { Loading } from '../Utils/Loading';
import config from '../config';

var webAuth = new auth0.WebAuth({
  clientID: config.AUTH0.clientID!,
  domain: config.AUTH0.domain!,
  redirectUri: config.AUTH0.callbackUrl,
  responseType: config.AUTH0.responseType,
  audience: config.AUTH0.audience,
  scope: config.AUTH0.scope
});

export const GET_TEAM_INVITE = gql`
  query TeamInvite($inviteToken: String!, $inviteContext: TeamInviteContext!) {
    teamInvite(inviteToken: $inviteToken, inviteContext: $inviteContext) {
      email
      uuid
      inviteToken
      businessUuid
      isActive
    }
  }
`;

interface Data {
  teamInvite: {
    email: string;
    isActive: boolean;
    inviteToken: string;
    uuid: string;
    businessUuid: string;
  };
}

interface contextData {
  signUpContext: string;
  signUpSubContext: string;
  businessOnboardEligible: boolean;
}

export enum TeamInviteContext {
  FACTORY_TEAM, // user is being onboarded to an existing factory business
  DESIGNER_TEAM,
  SOURCING_TEAM
}

interface Variables {
  inviteToken: string;
  inviteContext: string;
}

interface matchParams {
  token: string;
}

interface inviteProps {
  context: TeamInviteContext;
}

class TeamInviteComponent extends Component<
  RouteComponentProps<matchParams> & inviteProps
> {
  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitAccount = this.submitAccount.bind(this);
  }

  public state = {
    checkedInviteValidity: false,
    formValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      phoneCountryCode: '',
      phoneNumber: ''
    },
    isSubmitting: false
  };

  handleSubmit(values: SignUpFormProps) {
    this.setState({
      formValues: values,
      isSubmitting: true
    });
  }

  public submitAccount() {
    const {
      signUpContext,
      signUpSubContext,
      businessOnboardEligible
    } = this.handleContextDetails(this.props.context);

    const { token } = this.props.match.params;

    webAuth.redirect.signupAndLogin(
      {
        connection: 'Username-Password-Authentication',
        email: this.state.formValues.email,
        password: this.state.formValues.password,
        // @ts-ignore
        user_metadata: {
          business_onboard_eligible: businessOnboardEligible.toString(),
          first_name: this.state.formValues.firstName,
          last_name: this.state.formValues.lastName,
          phone_country_code: this.state.formValues.phoneCountryCode,
          phone_number: this.state.formValues.phoneNumber,
          sign_up_context: signUpContext,
          sign_up_invite_token: token,
          sign_up_sub_context: signUpSubContext
        }
      },
      function(err: any) {
        console.log(err);
      }
    );
  }

  render(): JSX.Element {
    const invalidInviteOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const generalErrorOptions = {
      loop: true,
      autoplay: true,
      animationData: errorAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const { token } = this.props.match.params;

    const context = TeamInviteContext[this.props.context];

    return (
      <div className="teamInviteContainer">
        <Query<Data, Variables>
          query={GET_TEAM_INVITE}
          variables={{ inviteToken: token, inviteContext: context }}
        >
          {({ loading, error, data, refetch, networkStatus }) => {
            if (networkStatus === 4)
              return (
                <Loading
                  data-testid="refetchSpinner"
                  className="teamInviteFormAnimation"
                  message="loading..."
                />
              );
            if (loading)
              return (
                <Loading
                  data-testid="loadingSpinner"
                  className="teamInviteFormAnimation"
                  message="Fetching your invite.....!"
                />
              );
            if (error)
              return (
                <div
                  data-testid="generalError"
                  className="teamInviteFormAnimation"
                >
                  <Lottie options={generalErrorOptions} />
                  <Typography.Title
                    className="teamInviteAnimationMessage"
                    level={3}
                  >
                    Oh no!...Somethings not right
                  </Typography.Title>

                  <Typography.Title
                    className="teamInviteAnimationMessage"
                    level={4}
                  >
                    So sorry about this... The Techies have already been alerted
                    and will try to fix it as soon as possible
                  </Typography.Title>
                  <Typography.Title
                    level={3}
                    className="teamInviteAnimationMessage"
                  >
                    <a href="mailto: support@makerspalm.com">
                      support@makerspalm.com
                    </a>
                  </Typography.Title>
                </div>
              );

            if (
              data &&
              (undefined == data.teamInvite || !data.teamInvite.isActive)
            ) {
              return (
                <div
                  data-testid="expiredInvite"
                  className="teamInviteFormAnimation"
                >
                  <Lottie options={invalidInviteOptions} />
                  <Typography.Title
                    className="teamInviteAnimationMessage"
                    level={3}
                  >
                    Oops!...Looks like this Invite isn't valid anymore
                  </Typography.Title>

                  <Typography.Title
                    className="teamInviteAnimationMessage"
                    level={4}
                  >
                    If you think you recieved this in error, Send us an email to
                    request a new one
                  </Typography.Title>
                  <Typography.Title
                    level={3}
                    className="teamInviteAnimationMessage"
                  >
                    <a href="mailto: support@makerspalm.com">
                      support@makerspalm.com
                    </a>
                  </Typography.Title>
                </div>
              );
            }

            if (this.state.isSubmitting) {
              this.submitAccount();

              return (
                <Loading
                  className="teamInviteFormAnimation"
                  data-testid="creatingAccount"
                  message="Creating your Account!"
                />
              );
            }

            return (
              <UserSignUpForm
                email={data ? data.teamInvite.email : ''}
                refetch={refetch}
                submitForm={this.handleSubmit}
                subTitle={this.handleFormSubtitle(this.props.context)}
                countryCode={this.handleCountryCode(this.props.context)}
                data-testid="signupform"
              />
            );
          }}
        </Query>
      </div>
    );
  }

  private handleFormSubtitle = (
    inviteContext: TeamInviteContext
  ): string | undefined => {
    return "Let's get you setup!";
  };

  private handleCountryCode = (
    invteContext: TeamInviteContext
  ): string | undefined => {
    switch (invteContext) {
      case TeamInviteContext.FACTORY_TEAM:
        return 'IN'; //India
      default:
        return undefined; // defaults to US (United States)
    }
  };

  private handleContextDetails = (
    inviteContext: TeamInviteContext
  ): contextData => {
    const initialContextData: contextData = {
      businessOnboardEligible: false,
      signUpContext: '',
      signUpSubContext: ''
    };

    switch (inviteContext) {
      case TeamInviteContext.FACTORY_TEAM:
        initialContextData.signUpContext = 'Factory';
        initialContextData.signUpSubContext = 'Team';
        initialContextData.businessOnboardEligible = false;
        return initialContextData;

      case TeamInviteContext.DESIGNER_TEAM:
        initialContextData.signUpContext = 'Designer';
        initialContextData.signUpSubContext = 'Team';
        initialContextData.businessOnboardEligible = false;
        return initialContextData;

      case TeamInviteContext.SOURCING_TEAM:
        initialContextData.signUpContext = 'Sourcing';
        initialContextData.signUpSubContext = 'Team';
        initialContextData.businessOnboardEligible = false;
        return initialContextData;
      default:
        return initialContextData;
    }
  };
}

export const TeamInvite = TeamInviteComponent;
