import React, { Component } from 'react';
import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
  Redirect
} from 'react-router';
import './Review.less';
import { Typography } from 'antd';
import { ReviewProjectDetailsView } from './components/ReviewProjectDetailsView';
import { FactoryAssignView } from './components/FactoryAssignView';
import { pdSignedStorageUrlObj } from '../../ProjectContentView';
import QueueAnim from 'rc-queue-anim';
import {
  SwitchTransition,
  TransitionGroup,
  CSSTransition
} from 'react-transition-group';
import { Mutation, Query } from 'react-apollo';
import { Loading, InlineLoading } from '../../../../../../Utils/Loading';
import { SimpleInlineError } from '../../../../../../Utils/Error';
import gql from 'graphql-tag';
import { BusinessContextInterface, pdProject } from '../../../../../Routes';
import DiscussionView from '../../../../../components/DiscussionComponents/DiscussionView';

export const TOTAL_STEPS = 2;

export const GET_CONVERSATION = gql`
  query Conversation(
    $contextType: String!
    $contextUuid: String!
    $convoTag: String!
  ) {
    conversation(
      contextType: $contextType
      contextUuid: $contextUuid
      convoTag: $convoTag
    ) {
      contextType
      contextUuid
      messages {
        authorEmail
        authorFirstName
        authorLastName
        authorUserId
        uuid
        messageText
        attachments {
          uuid
          fileName
          storageKey
        }
      }
      tag
      uuid
    }
  }
`;

export const INITIATE_CONVERSATION = gql`
  mutation InitiateConvo(
    $contextType: ContextType!
    $contextUuid: String!
    $convoTag: String!
    $targetBusinessType: BusinessType!
    $targetBusinessUuid: String!
  ) {
    initiateConvo(
      contextType: $contextType
      contextUuid: $contextUuid
      convoTag: $convoTag
      targetBusinessType: $targetBusinessType
      targetBusinessUuid: $targetBusinessUuid
    ) {
      tag
      messages {
        uuid
        authorEmail
        authorFirstName
        authorLastName
        authorUserId
        messageText
        attachments {
          uuid
          fileName
          storageKey
        }
      }
      uuid
      contextType
      contextUuid
    }
  }
`;

export const ADVANCE_PROJECT_TO_DEVELOP = gql`
  mutation AdvanceProjectToDevelop(
    $designerBusinessUuid: String!
    $projectUuid: String!
    $sourcingBusinessUuid: String!
    $assignFactoryUuid: String!
  ) {
    advanceProjectToDevelop(
      designerBusinessUuid: $designerBusinessUuid
      projectUuid: $projectUuid
      sourcingBusinessUuid: $sourcingBusinessUuid
      assignFactoryUuid: $assignFactoryUuid
    ) {
      designerBusinessName
      designerBusinessUuid
      projectStatus
      projectCategory
      projectContext
      projectName
      projectNotes
      projectFiles {
        authorContext
        authorId
        fileName
        storageKey
        isProjectAvatar
        uuid
        versionId
      }
    }
  }
`;

export interface message {
  uuid: string;
  messageText: string;
  authorUserId: string;
  authorFirstName: string;
  authorLastName: string;
  authorEmail: string;
  attachments: attachment[];
}

export interface attachment {
  uuid: string;
  fileName: string;
  storageKey: string;
}

export interface conversation {
  uuid: string;
  tag: string;
  contextType: string;
  contextUuid: string;
  messages: message[];
}

interface conversationData {
  conversation: conversation;
}

interface conversationVariables {
  contextType: string;
  contextUuid: string;
  convoTag: string;
}

interface initiateConvoData {
  initiateConvo: conversation;
}

interface initiateConvoVariables {
  contextType: string;
  contextUuid: string;
  convoTag: string;
  targetBusinessType: string;
  targetBusinessUuid: string;
}

interface advanceProjectToDevelopData {
  advanceProjectToDevelop: pdProject;
}

interface advanceProjectToDevelopVariables {
  designerBusinessUuid: string;
  projectUuid: string;
  sourcingBusinessUuid: string;
  assignFactoryUuid: string | undefined;
}

interface ReviewProps {
  businessContext: BusinessContextInterface;
  project: pdProject;
  storageUrlObj: pdSignedStorageUrlObj;
  handleChangeStatus(step: number): void;
}

interface matchParams {
  projectUuid: string;
}

interface stateProps {
  currentStep: number;
  assignedFactoryUuid: string | undefined;
  convoInitiated: boolean;
}

const { Title, Text } = Typography;

const CONTEXT_TYPE = 'PROJECT';
const CONVO_TAG = 'REVIEW';
const TARGET_BUSINESS_TYPE = 'DESIGNER';

class ReviewComponent extends Component<
  RouteComponentProps<matchParams> & ReviewProps
> {
  public state: stateProps = {
    currentStep: 1,
    assignedFactoryUuid: undefined,
    convoInitiated: false
  };

  public handleNext = () => {
    this.setState({
      currentStep: this.state.currentStep + 1
    });
  };

  public handleBack = () => {
    this.setState({
      currentStep: this.state.currentStep - 1
    });
  };

  public handleAssignFactory = (uuid: string) => {
    this.setState({
      assignedFactoryUuid: uuid
    });
  };

  public handleConvoInitiated = () => {
    this.setState({
      convoInitiated: true
    });
  };

  public loadingOverlay = () => (
    <div className="sourcing-lifecycle-review-loading">
      <Text strong style={{ fontSize: 18 }}>
        Initiating Discussion..
      </Text>

      <InlineLoading />
    </div>
  );

  public render(): JSX.Element {
    return (
      <div className="sourcing-lifecycle-review-parent-container">
        <Query<conversationData, conversationVariables>
          query={GET_CONVERSATION}
          variables={{
            contextType: CONTEXT_TYPE,
            contextUuid: this.props.project.uuid,
            convoTag: CONVO_TAG
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <SimpleInlineError />;
            }

            return (
              <Mutation<
                advanceProjectToDevelopData,
                advanceProjectToDevelopVariables
              >
                onCompleted={() => {
                  this.props.handleChangeStatus(1);
                  this.props.history.replace(
                    `/sourcing/projects/${this.props.project.uuid}/lifecycle/development`
                  );
                }}
                mutation={ADVANCE_PROJECT_TO_DEVELOP}
                variables={{
                  designerBusinessUuid: this.props.project.designerBusinessUuid,
                  projectUuid: this.props.project.uuid,
                  sourcingBusinessUuid: this.props.businessContext.uuid,
                  assignFactoryUuid: this.state.assignedFactoryUuid
                }}
              >
                {(advanceProject: () => void, { loading, error }) => {
                  if (loading) {
                    return <Loading />;
                  }
                  if (error) {
                    return <SimpleInlineError />;
                  }
                  if (data && data.conversation != null) {
                    return (
                      <div className="sourcing-lifecycle-review-items-container">
                        <DiscussionView
                          contextType={CONTEXT_TYPE}
                          convoTag={CONVO_TAG}
                          designerBusinessUuid={
                            this.props.project.designerBusinessUuid
                          }
                          contextUuid={this.props.project.uuid}
                          conversationUuid={data.conversation.uuid}
                          messages={data.conversation.messages}
                          storageUrlObj={this.props.storageUrlObj}
                        />
                        {this.state.currentStep == 1 ? (
                          <ReviewProjectDetailsView
                            minified={true}
                            key="1"
                            storageUrlObj={this.props.storageUrlObj}
                            currentStep={this.state.currentStep}
                            project={this.props.project}
                            handleNext={this.handleNext}
                            toggleDiscussion={this.handleConvoInitiated}
                          />
                        ) : (
                          <FactoryAssignView
                            minified={true}
                            key="2"
                            handleBack={this.handleBack}
                            storageUrlObj={this.props.storageUrlObj}
                            currentStep={this.state.currentStep}
                            assignedFactoryUuid={this.state.assignedFactoryUuid}
                            handleAssignFactory={this.handleAssignFactory}
                            advanceProject={advanceProject}
                          />
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <Mutation<initiateConvoData, initiateConvoVariables>
                        onCompleted={() => {
                          refetch();
                        }}
                        mutation={INITIATE_CONVERSATION}
                        variables={{
                          contextType: CONTEXT_TYPE,
                          contextUuid: this.props.project.uuid,
                          convoTag: CONVO_TAG,
                          targetBusinessType: TARGET_BUSINESS_TYPE,
                          targetBusinessUuid: this.props.project
                            .designerBusinessUuid
                        }}
                      >
                        {(
                          initiateConvo: () => void,
                          {
                            loading: initiateConvoLoading,
                            error: initiateConvoError
                          }
                        ) => {
                          if (initiateConvoLoading)
                            return (
                              <div className="sourcing-lifecycle-review-items-full-container">
                                {this.loadingOverlay()}
                              </div>
                            );
                          return (
                            <div className="sourcing-lifecycle-review-items-full-container">
                              {this.state.currentStep == 1 ? (
                                <ReviewProjectDetailsView
                                  minified={false}
                                  key="1"
                                  storageUrlObj={this.props.storageUrlObj}
                                  currentStep={this.state.currentStep}
                                  project={this.props.project}
                                  handleNext={this.handleNext}
                                  toggleDiscussion={initiateConvo}
                                />
                              ) : (
                                <FactoryAssignView
                                  minified={false}
                                  key="2"
                                  handleBack={this.handleBack}
                                  storageUrlObj={this.props.storageUrlObj}
                                  currentStep={this.state.currentStep}
                                  assignedFactoryUuid={
                                    this.state.assignedFactoryUuid
                                  }
                                  handleAssignFactory={this.handleAssignFactory}
                                  advanceProject={advanceProject}
                                />
                              )}
                            </div>
                          );
                        }}
                      </Mutation>
                    );
                  }
                }}
              </Mutation>
            );
          }}
        </Query>
      </div>
    );
  }
}

export const Review = withRouter(ReviewComponent);
