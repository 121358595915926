import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Alert,
  Divider,
  Typography,
  Avatar,
  List,
  Drawer,
  Modal
} from 'antd';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import './EditTeamMemberDrawer.less';
import { BusinessContextInterface } from '../../Routes';
import { teamInvite, userDetail, getMemberType } from './TeamView';
import {
  AuthIdentityConsumer,
  AuthIdentityInterface
} from '../../../Auth/AuthIdentityContext';
import { business } from '../../../Home/Home';
import { RouteComponentProps, withRouter } from 'react-router';
import { TeamInviteContext } from '../../../Invite/TeamInvite';

const { Text } = Typography;

const { confirm } = Modal;

const COLUMN_SPAN_SIZE = 12;

const DRAWER_WIDTH = 360;

export const LEAVE_BUSINESS = gql`
  mutation LeaveBusiness(
    $businessUuid: String!
    $targetUserId: String!
    $businessContext: TeamInviteContext!
  ) {
    leaveBusiness(
      businessUuid: $businessUuid
      targetUserId: $targetUserId
      businessContext: $businessContext
    ) {
      uuid
      admins
      businessType
      members
      name
      membershipPlan
    }
  }
`;

export const UPDATE_BUSINESS_MEMBER_TYPE = gql`
  mutation UpdateBusinessMemberType(
    $businessUuid: String!
    $targetUserId: String!
    $businessContext: TeamInviteContext!
    $memberType: BusinessMemberType!
  ) {
    updateBusinessMemberType(
      businessUuid: $businessUuid
      targetUserId: $targetUserId
      businessContext: $businessContext
      memberType: $memberType
    ) {
      uuid
      admins
      businessType
      members
      name
      membershipPlan
    }
  }
`;

enum memberType {
  ADMIN,
  MEMBER
}

enum confirmType {
  REMOVE_MEMBER,
  UPDATE_MEMBER_TYPE
}

interface EditTeamMemberDrawerProps {
  businessContext: BusinessContextInterface;
  isMobile: boolean;
  user: userDetail;
  teamContext: TeamInviteContext;
  onClose(): void;
}

interface stateProps {
  confirmDrawer: boolean;
  confirmType: confirmType;
}

interface leaveBusinessMutationData {
  leaveBusiness: business;
}

interface leaveBusinessMutationVariables {
  businessContext: string;
  businessUuid: string;
  targetUserId: string;
}

interface updateBusinessMemberTypeMutationData {
  updateBusinessMemberType: business;
}

interface updateBusinessMemberTypeMutationVariables {
  businessContext: string;
  businessUuid: string;
  targetUserId: string;
  memberType: string;
}

const pStyle = {
  color: 'rgba(0,0,0,0.85)',
  display: 'block',
  fontSize: 16,
  lineHeight: '24px',
  marginBottom: 16
};

const DescriptionItem = ({
  title,
  content
}: {
  title: string;
  content: string;
}) => (
  <div
    style={{
      color: 'rgba(0,0,0,0.65)',
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7
    }}
  >
    <p
      style={{
        color: 'rgba(0,0,0,0.85)',
        display: 'inline-block',
        marginRight: 8
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

class EditTeamMemberDrawerComponent extends Component<
  EditTeamMemberDrawerProps & RouteComponentProps
> {
  public state: stateProps = {
    confirmDrawer: false,
    confirmType: {} as confirmType
  };

  public showConfirmDrawer = (confirmTypeValue: confirmType): void => {
    this.setState(
      {
        confirmType: confirmTypeValue
      },
      () => {
        this.setState({
          confirmDrawer: true
        });
      }
    );
  };

  public onConfirmDrawerClose = (): void => {
    this.setState({
      confirmDrawer: false
    });
  };

  public canEditCurrentUser = (authContext: AuthIdentityInterface): boolean => {
    return (
      this.props.businessContext.isAdmin ||
      this.props.user.userId == authContext.sub
    );
  };

  public renderUpdateMemberType = () => {
    const requestedMemberType: memberType =
      getMemberType(this.props.user.userId, this.props.businessContext) ==
      'Admin'
        ? memberType.MEMBER
        : memberType.ADMIN;

    return (
      <Mutation<
        updateBusinessMemberTypeMutationData,
        updateBusinessMemberTypeMutationVariables
      >
        onCompleted={() => {
          this.onConfirmDrawerClose();
          this.props.onClose();
        }}
        onError={() => {
          //do nothing
        }}
        mutation={UPDATE_BUSINESS_MEMBER_TYPE}
        variables={{
          businessContext: TeamInviteContext[this.props.teamContext],
          businessUuid: this.props.businessContext.uuid,
          memberType: memberType[requestedMemberType],
          targetUserId: this.props.user.userId
        }}
      >
        {(updateBusinessMemberType: () => void, { loading, error, called }) => (
          <div data-testid="update-member-type-confirm-drawer">
            {error ? (
              <Alert
                data-testid="update-member-type-error-alert-dialog"
                message="Oops"
                description={
                  error
                    ? error.message.replace('GraphQL error: ', '')
                    : 'something went wrong'
                }
                type="error"
                showIcon
              />
            ) : (
              <Alert
                message={
                  requestedMemberType == memberType.MEMBER
                    ? 'Are you sure you want to revoke this persons Admin privileges ?'
                    : 'Are you sure you want give this person Admin Privileges ?'
                }
                description={
                  requestedMemberType == memberType.MEMBER
                    ? ''
                    : 'They will have access to remove and edit other members details and permissions including other Admins such as yourself'
                }
                type="warning"
                showIcon
              />
            )}
            <div className="TeamMemberDrawerButtonLeaveTeamContainer">
              <Button
                data-testid="update-member-type-cancel-button"
                disabled={loading}
                onClick={this.onConfirmDrawerClose}
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>
              <Button
                data-testid="update-member-type-update-button"
                ghost
                loading={loading}
                disabled={loading}
                htmlType="submit"
                type="primary"
                onClick={() => updateBusinessMemberType()}
              >
                Update
              </Button>
            </div>
          </div>
        )}
      </Mutation>
    );
  };

  public renderRemoveMemberFromTeam = () => (
    <Mutation<leaveBusinessMutationData, leaveBusinessMutationVariables>
      onCompleted={() => this.props.onClose()}
      mutation={LEAVE_BUSINESS}
      variables={{
        businessContext: TeamInviteContext[this.props.teamContext],
        businessUuid: this.props.businessContext.uuid,
        targetUserId: this.props.user.userId
      }}
    >
      {(leaveBusiness: () => void, { loading, error, called }) => (
        <div>
          <Alert
            message="Are you sure you want to remove this person from the team ?"
            description="They can only rejoin if they accept a new invite from an admin"
            type="warning"
            showIcon
          />

          <div className="TeamMemberDrawerButtonLeaveTeamContainer">
            <Button
              data-testid="create-invite-cancel-button"
              disabled={loading}
              onClick={this.onConfirmDrawerClose}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              data-testid="create-invite-submit-button"
              ghost
              loading={loading}
              disabled={loading}
              htmlType="submit"
              type="danger"
              onClick={() => leaveBusiness()}
            >
              Remove
            </Button>
          </div>
        </div>
      )}
    </Mutation>
  );

  public renderRemoveSelfFromTeam = () => {
    return (
      <Mutation<leaveBusinessMutationData, leaveBusinessMutationVariables>
        onCompleted={() => window.location.reload()}
        mutation={LEAVE_BUSINESS}
        variables={{
          businessContext: TeamInviteContext[this.props.teamContext],
          businessUuid: this.props.businessContext.uuid,
          targetUserId: this.props.user.userId
        }}
      >
        {(leaveBusiness: () => void, { loading, error, called }) => (
          <div>
            {error ? (
              <Alert
                message="Oops"
                description={
                  error
                    ? error.message.replace('GraphQL error: ', '')
                    : 'something went wrong'
                }
                type="error"
                showIcon
              />
            ) : (
              <Alert
                message="Are you sure you want to leave the team ?"
                description="You can only rejoin the team if another member sends you an invite"
                type="warning"
                showIcon
              />
            )}

            <div className="TeamMemberDrawerButtonLeaveTeamContainer">
              <Button
                data-testid="create-invite-cancel-button"
                disabled={loading}
                onClick={this.onConfirmDrawerClose}
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>
              <Button
                data-testid="create-invite-submit-button"
                ghost
                disabled={loading}
                loading={loading}
                htmlType="submit"
                type="danger"
                onClick={() => leaveBusiness()}
              >
                Leave
              </Button>
            </div>
          </div>
        )}
      </Mutation>
    );
  };

  public render(): JSX.Element {
    return (
      <AuthIdentityConsumer>
        {authContext => (
          <div data-testid="edit-team-member-drawer">
            <Text style={pStyle} strong>
              Profile
            </Text>
            <Row>
              <Col span={COLUMN_SPAN_SIZE}>
                <DescriptionItem
                  title="Full Name"
                  content={
                    this.props.user.firstName.charAt(0).toUpperCase() +
                    this.props.user.firstName.slice(1) +
                    ' ' +
                    this.props.user.lastName.charAt(0).toUpperCase() +
                    this.props.user.lastName.slice(1)
                  }
                />{' '}
                <DescriptionItem
                  title="Email"
                  content={this.props.user.email}
                />
                <DescriptionItem
                  title="Phone Country Code"
                  content={this.props.user.phoneCountryCode}
                />
                <DescriptionItem
                  title="Phone"
                  content={this.props.user.phoneNumber}
                />
              </Col>
              <Col
                style={{
                  alignContent: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                span={COLUMN_SPAN_SIZE}
              >
                <Avatar
                  style={{
                    alignContent: 'center',
                    backgroundColor: '#edfcf8',
                    borderColor: '#4ABDAC',
                    borderStyle: 'solid',
                    borderWidth: 2.2,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  className="TeamMemberEditAvatarView"
                  data-testid="edit-team-member-avatar"
                  size="large"
                >
                  <Text strong className="TeamMemberEditAvatarText">
                    {this.props.user.firstName.substr(0, 1).toUpperCase()}
                  </Text>
                </Avatar>
              </Col>
            </Row>

            <Divider />
            <Text style={pStyle} strong>
              Settings
            </Text>
            <Row>
              <List itemLayout="horizontal">
                <List.Item>
                  <List.Item.Meta
                    title="Membership Type"
                    description={getMemberType(
                      this.props.user.userId,
                      this.props.businessContext
                    )}
                  />
                  <Button
                    data-testid="member-type-settings-action-button"
                    type="link"
                    disabled={!this.props.businessContext.isAdmin}
                    onClick={() =>
                      this.showConfirmDrawer(confirmType.UPDATE_MEMBER_TYPE)
                    }
                  >
                    {getMemberType(
                      this.props.user.userId,
                      this.props.businessContext
                    ) == 'Admin'
                      ? 'Revoke Admin Privileges'
                      : 'Make an Admin'}
                  </Button>
                </List.Item>
              </List>
            </Row>

            <Divider />
            <Text style={pStyle} strong>
              Danger Zone
            </Text>
            <Row>
              <Button
                data-testid="danger-leave-team-button"
                onClick={() =>
                  this.showConfirmDrawer(confirmType.REMOVE_MEMBER)
                }
                ghost
                type="danger"
                disabled={!this.canEditCurrentUser(authContext)}
              >
                {this.props.user.userId == authContext.sub
                  ? 'Leave Team'
                  : 'Remove from Team'}
              </Button>
            </Row>

            <Drawer
              title="Confirm"
              width={this.props.isMobile ? '100%' : DRAWER_WIDTH}
              height={this.props.isMobile ? '90%' : undefined}
              placement={this.props.isMobile ? 'bottom' : 'right'}
              closable={false}
              destroyOnClose
              onClose={this.onConfirmDrawerClose}
              visible={this.state.confirmDrawer}
            >
              {this.renderConfirmType(authContext)}
            </Drawer>
          </div>
        )}
      </AuthIdentityConsumer>
    );
  }

  private renderConfirmType = (authContext: AuthIdentityInterface) => {
    if (this.state.confirmType == confirmType.UPDATE_MEMBER_TYPE) {
      return this.renderUpdateMemberType();
    } else {
      return this.props.user.userId == authContext.sub
        ? this.renderRemoveSelfFromTeam()
        : this.renderRemoveMemberFromTeam();
    }
  };
}

export const EditTeamMemberDrawer = withRouter(EditTeamMemberDrawerComponent);
