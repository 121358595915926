import React from 'react';
import {
  Table,
  Popconfirm,
  Alert,
  Typography,
  Icon,
  Spin,
  List,
  Affix,
  Button
} from 'antd';
import { Query, Mutation } from 'react-apollo';
import { GET_DESIGNER_INVITES, designerInvite } from '../AdminInvites';
import { Loading, InlineLoading } from '../../../../Utils/Loading';
import { Error, SimpleInlineError } from '../../../../Utils/Error';
import moment from 'moment';
import gql from 'graphql-tag';
import MediaQuery from 'react-responsive';

export const INVALIDATE_DESIGNER_INVITE = gql`
  mutation InvalidateDesignerInvite($email: String!, $inviteToken: String!) {
    invalidateDesignerInvite(email: $email, inviteToken: $inviteToken) {
      email
      firstName
      lastName
      expirationDate
      uuid
      isExpired
      userSignedUp
      inviteToken
    }
  }
`;

interface Data {
  designerInvites: designerInvite[];
}

interface mutationData {
  designerInvite: designerInvite;
}

interface mutationVariables {
  email: string;
  inviteToken: string;
}

interface designerInviteTableView extends designerInvite {
  key: string;
}

const renderInviteActions = (invite: designerInviteTableView) => {
  if (!invite.userSignedUp && !invite.isExpired) {
    return (
      <Mutation<mutationData, mutationVariables>
        mutation={INVALIDATE_DESIGNER_INVITE}
        variables={{
          email: invite.email,
          inviteToken: invite.inviteToken
        }}
      >
        {(invalidateDesignerInvite: () => void, { loading, error, called }) => {
          if (loading) return <InlineLoading />;
          if (error) return <SimpleInlineError />;
          return (
            <Popconfirm
              okType="danger"
              data-test-id="cancel-invite-pop-confirm"
              title="Sure you wanna Cancel?"
              onConfirm={() => invalidateDesignerInvite()}
            >
              <a
                data-testid="designer-cancel-invite-link"
                style={{ color: '#FFA464' }}
              >
                Cancel Invite
              </a>
            </Popconfirm>
          );
        }}
      </Mutation>
    );
  } else
    return <Typography.Text disabled>No actions available</Typography.Text>;
};

const renderInviteStatus = (invite: designerInviteTableView) => {
  if (invite.userSignedUp)
    return (
      <Typography.Text>
        <Icon theme="filled" style={{ color: '#52c41a' }} type="check-circle" />{' '}
        Invite Accepted
      </Typography.Text>
    );
  if (invite.isExpired)
    return (
      <Typography.Text>
        <Icon theme="filled" style={{ color: '#f5222d' }} type="close-circle" />{' '}
        Invite Expired/Canceled
      </Typography.Text>
    );

  return (
    <span>
      <Typography.Text strong>
        <Icon theme="filled" style={{ color: '#1890ff' }} type="info-circle" />{' '}
        Invite Pending -
      </Typography.Text>
      <Typography.Text>
        {' '}
        expires {moment.unix(parseInt(invite.expirationDate)).fromNow()}
      </Typography.Text>
    </span>
  );
};

const columns = [
  {
    dataIndex: 'email',
    key: 'email',
    title: 'Email'
  },
  {
    dataIndex: 'firstName',
    key: 'firstName',
    title: 'First name'
  },
  {
    dataIndex: 'lastName',
    key: 'lastName',
    title: 'Last name'
  },
  {
    dataIndex: 'status',
    render: (text: unknown, record: designerInviteTableView) => {
      return renderInviteStatus(record);
    },
    title: 'Status'
  },
  {
    dataIndex: 'actions',
    render: (text: unknown, record: designerInviteTableView) => {
      return renderInviteActions(record);
    },
    title: 'Actions'
  }
];

const formatDataResponse = (
  data: Data | undefined
): designerInviteTableView[] => {
  if (data == undefined) {
    return [];
  }

  return data.designerInvites.map(invite => {
    return {
      email: invite.email,
      expirationDate: invite.expirationDate,
      firstName: invite.firstName,
      inviteToken: invite.inviteToken,
      isExpired: invite.isExpired,
      key: invite.uuid,
      lastName: invite.lastName,
      userSignedUp: invite.userSignedUp,
      uuid: invite.uuid
    };
  });
};

export const DesignerInvitesList = () => {
  return (
    <Query<Data> query={GET_DESIGNER_INVITES}>
      {({ loading, error, data, refetch }) => {
        if (error) return <Error />;

        if (loading) return <Loading />;

        return (
          <div>
            <MediaQuery query="(min-device-width: 768px)">
              <Table
                data-testid="designer-invites-table"
                bodyStyle={{
                  backgroundColor: 'white',
                  borderRadius: 8,
                  padding: 10
                }}
                style={{ padding: 10 }}
                columns={columns}
                dataSource={formatDataResponse(data)}
              />
            </MediaQuery>

            <MediaQuery query="(max-device-width: 767px)">
              <List
                data-testid="designer-invites-mobile-list"
                style={{
                  backgroundColor: 'white',
                  borderRadius: 8,
                  padding: 10
                }}
                itemLayout="vertical"
                dataSource={formatDataResponse(data)}
                renderItem={(item: designerInviteTableView, index: number) => (
                  <List.Item
                    data-testid={'designer-invites-list-item-' + index}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span style={{ paddingBottom: 5 }}>{item.email}</span>

                    <span style={{ paddingBottom: 5 }}>
                      {item.firstName} {item.lastName ? item.lastName : ''}
                    </span>

                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: 5
                      }}
                    >
                      {renderInviteStatus(item)}
                      {renderInviteActions(item)}
                    </span>
                  </List.Item>
                )}
              />
            </MediaQuery>
          </div>
        );
      }}
    </Query>
  );
};
