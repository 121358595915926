import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { pdProject } from '../../../../../Routes';

interface DevelopmentProps {
  project: pdProject;
}

interface matchParams {
  projectUuid: string;
}

class DevelopmentComponent extends Component<
  RouteComponentProps<matchParams> & DevelopmentProps
> {
  public render(): JSX.Element {
    return <div>DEVELOPMENT STAGE!</div>;
  }
}

export const Development = withRouter(DevelopmentComponent);
