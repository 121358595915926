import React, { Component } from 'react';
import './BusinessInvitesForm.css';
import { Form, Input, Icon, Button, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { OnboardingProps } from '../Designer/Onboarding';
let id = 0;

interface BusinessInvitesFormProps extends FormComponentProps, OnboardingProps {
  businessEmailInvites: string[];
  handleBusinessInvitesChange: any;
  complete(): void;
}

class BusinessInvitesForm extends Component<BusinessInvitesFormProps> {
  remove = (k: any) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys: any = form.getFieldValue('keys');

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key: any) => key !== k)
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys: any = form.getFieldValue('keys');

    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    this.props.form.validateFields((err, values: any) => {
      if (!err) {
        const { keys, emails } = values;

        this.props.handleBusinessInvitesChange(emails, this.props.complete);
      } else {
        console.log(err);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    getFieldDecorator('keys', { initialValue: [] });
    const keys: any = getFieldValue('keys');
    const formItems = keys.map((k: any, index: number) => (
      <Form.Item required={false} key={k}>
        {getFieldDecorator(`emails[${k}]`, {
          validateTrigger: 'onBlur',

          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Please include a valid email address'
            },
            {
              type: 'email',
              message: "this doesn't look like a valid email address"
            }
          ]
        })(
          <Input
            autoFocus
            data-testid={`email-input-${k}`}
            size="large"
            placeholder="Email address"
            className="emailAddressFormInput"
          />
        )}
        <Icon
          data-testid={`email-input-remove-${k}`}
          className="dynamic-delete-button"
          type="minus-circle-o"
          onClick={() => this.remove(k)}
        />
      </Form.Item>
    ));
    return (
      <div>
        <div className="BusinessInviteFormContent">
          <Typography.Title level={1}>
            Do you want to invite anyone to join your Team?
          </Typography.Title>

          <Typography className="BusinessInviteFormContentSubText">
            Invite as many people as you like to join your Team by adding their
            email address below. We will send them an invite link and you'll get
            a notified once they joined
          </Typography>
        </div>
        <div className="BusinessInviteFormContent">
          <Form onSubmit={this.handleSubmit}>
            {formItems}
            <Form.Item>
              <Button
                data-testid="add-invite-button"
                size="large"
                type="dashed"
                onClick={this.add}
                className="addInviteButton"
              >
                <Icon type="plus" /> Add Invite
              </Button>
            </Form.Item>

            <Form.Item>
              <div className="BusinessInviteFormContentButtons">
                <Button
                  type="link"
                  style={{ marginRight: 15 }}
                  size="large"
                  onClick={() => this.props.prev()}
                >
                  Back
                </Button>
                <Button
                  size="large"
                  data-testid="finish-button"
                  type="primary"
                  disabled={keys.length < 1}
                  htmlType="submit"
                >
                  Create my Business
                </Button>
              </div>
            </Form.Item>
          </Form>
          {keys.length > 0 ? null : (
            <Typography style={{ textAlign: 'center', marginTop: 50 }}>
              Don't need to invite anyone right now?{' '}
              <Button
                data-testid="skip-invite-button"
                style={{ marginBottom: 50 }}
                type="primary"
                ghost
                size="small"
                onClick={() => this.props.complete()}
              >
                Skip this step
              </Button>
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

export default Form.create()(BusinessInvitesForm);
