import React, { Component } from 'react';
import './SubmittedProjectDetailsView.less';
import { Row, Typography, Divider, Upload, Button, Icon } from 'antd';
import { pdProject, pdProjectFile } from '../../../../../Routes';
import { submittedProject, projectContextEnum } from '../../../Projects';
import { pdSignedStorageUrlObj } from '../../../../../Sourcing/Projects/ProjectContent/ProjectContentView';
import { UploadFile } from 'antd/lib/upload/interface';

const { Text, Title } = Typography;

const LEVEL_4_HEADER_SIZE = 4;

interface SubmittedProjDetailsProps {
  project: submittedProject;
  storageUrlObj: pdSignedStorageUrlObj;
}

const renderContextMessage = (
  projectContext: projectContextEnum | undefined
) => {
  if (projectContext) {
    switch (projectContext) {
      case 'SAMPLE':
        return 'You already have a sample made';
      case 'FILES':
        return 'You have CAD/Design files ready for the project';
      case 'SCRATCH':
        return 'You are starting from scratch';
      default:
        return 'Could not determine Project Context';
    }
  }
};

const renderProjectFiles = (
  projectFiles: pdProjectFile[],
  storage_url_obj: pdSignedStorageUrlObj
): JSX.Element => (
  <span>
    <Upload
      style={{ height: '50%', display: 'flex', flexDirection: 'row' }}
      listType="picture-card"
      fileList={
        transformFileListToAntStandard(
          storage_url_obj,
          projectFiles
        ) as UploadFile[]
      }
    ></Upload>
  </span>
);

const transformFileListToAntStandard = (
  storageUrlObj: pdSignedStorageUrlObj,
  projectFiles: pdProjectFile[]
) => {
  //@ts-ignore
  const newList = _.reduce(
    projectFiles,
    (acc, cur) => {
      if (cur.authorContext == 'DESIGNER') {
        const transformedFile = {
          name: cur.fileName,
          size: 1,
          status: 'done',
          storageKey: cur.storageKey,
          thumbUrl:
            storageUrlObj.url + cur.storageKey + storageUrlObj.urlParams,
          type: 'image/jpeg',
          uid: cur.uuid,
          url: storageUrlObj.url + cur.storageKey + storageUrlObj.urlParams
        } as UploadFile;
        return acc.concat([transformedFile]);
      } else {
        return acc;
      }
    },
    [] as UploadFile[]
  );

  return newList;
};

export function SubmittedProjectDetailsView(
  props: SubmittedProjDetailsProps
): JSX.Element {
  return (
    <div className="designer-review-project-details-container">
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 10,
          paddingRight: 10,
          justifyContent: 'space-between',
          alignItems: 'left'
        }}
      >
        <Title style={{ padding: 10 }} level={LEVEL_4_HEADER_SIZE}>
          Submitted Project Details
        </Title>
        <Text className="designer-review-project-details-context-message">
          {renderContextMessage(props.project.projectContext)}
        </Text>
      </span>
      <Divider />
      <Text style={{ padding: 10, fontSize: 16, color: 'black' }} strong>
        Project Files
      </Text>
      <Row style={{ padding: 15 }}>
        <Text strong>
          {renderProjectFiles(props.project.projectFiles, props.storageUrlObj)}
        </Text>
      </Row>
      <Divider />
      <Text style={{ padding: 10, fontSize: 16, color: 'black' }} strong>
        Project Notes
      </Text>
      <Row style={{ padding: 15 }}>
        <Text strong>{props.project.projectNotes}</Text>
      </Row>
    </div>
  );
}
