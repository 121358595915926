import React from 'react';
import { Typography, Button } from 'antd';
import { projectDetails } from '../Submit';
import { projectContextEnum } from '../../../Projects';
import './ProjectContextForm.less';

export interface projectContextFormProps {
  projectDetails: projectDetails;
  handleProjectContextSelect(projectContext: projectContextEnum): void;
  handleUpdateAndNext(updateProjectDetails: () => void): void;
  updateProjectDetails(): void;
}

export const ProjectContextForm = (props: projectContextFormProps) => {
  return (
    <div
      data-testid="project-context-form"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'inherit',
        justifyContent: 'space-between'
      }}
    >
      <span data-testid="project-context-form-header">
        <Typography.Title level={2}>
          What's your Project's starting point?
        </Typography.Title>
      </span>
      <span className="project-context-form-buttons-container">
        <span className="project-context-inline-button-span">
          <Button
            data-testid="project-context-sample-button"
            type={
              props.projectDetails.projectContext == projectContextEnum.SAMPLE
                ? 'primary'
                : 'ghost'
            }
            shape="round"
            size="large"
            onClick={() =>
              props.handleProjectContextSelect(projectContextEnum.SAMPLE)
            }
          >
            Sample
          </Button>
          <section style={{ marginTop: 10 }}>
            <Typography.Text
              style={{ fontSize: 16 }}
              strong={
                props.projectDetails.projectContext == projectContextEnum.SAMPLE
              }
            >
              I have a physical sample already made
            </Typography.Text>
          </section>
        </span>
        <span className="project-context-inline-button-span">
          <Button
            data-testid="project-context-files-button"
            type={
              props.projectDetails.projectContext == projectContextEnum.FILES
                ? 'primary'
                : 'ghost'
            }
            shape="round"
            size="large"
            style={{ width: 100 }}
            onClick={() =>
              props.handleProjectContextSelect(projectContextEnum.FILES)
            }
          >
            Files
          </Button>
          <section style={{ marginTop: 10 }}>
            <Typography.Text
              style={{ fontSize: 16 }}
              strong={
                props.projectDetails.projectContext == projectContextEnum.FILES
              }
            >
              I have a product spec, design or CAD files ready
            </Typography.Text>
          </section>
        </span>
        <span className="project-context-inline-button-span">
          <Button
            data-testid="project-context-scratch-button"
            type={
              props.projectDetails.projectContext == projectContextEnum.SCRATCH
                ? 'primary'
                : 'ghost'
            }
            shape="round"
            size="large"
            style={{ width: 100 }}
            onClick={() =>
              props.handleProjectContextSelect(projectContextEnum.SCRATCH)
            }
          >
            Scratch
          </Button>
          <section style={{ marginTop: 10 }}>
            <Typography.Text
              style={{ fontSize: 16 }}
              strong={
                props.projectDetails.projectContext ==
                projectContextEnum.SCRATCH
              }
            >
              I'm starting from scratch but I have an idea of what I want
            </Typography.Text>
          </section>
        </span>
      </span>
      <span>
        <Button
          disabled={props.projectDetails.projectContext == undefined}
          type="primary"
          data-testid="project-context-form-next-button"
          size="large"
          className="project-context-next-button"
          onClick={() => props.handleUpdateAndNext(props.updateProjectDetails)}
        >
          Next
        </Button>
      </span>
    </div>
  );
};
