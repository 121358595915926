import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import { Typography } from 'antd';
import { Lifecycle } from './Lifecycle/Lifecycle';
import { SimpleInlineError } from '../../../../Utils/Error';
import './ProjectContentView.less';
import { withApollo, WithApolloClient, graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { BusinessContextInterface, pdProject } from '../../../Routes';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-boost';
import { Loading } from '../../../../Utils/Loading';
const { Text } = Typography;

interface projectContentViewProps {
  project: pdProject | undefined;
  businessContext: BusinessContextInterface;
  requestSourcingSignedStorageUrl: pdSignedStorageUrlData;
}

export interface pdSignedStorageUrlObj {
  url: string;
  urlParams: string;
}

interface pdSignedStorageUrlData {
  loading: boolean;
  error: ApolloError;
  pdSignedStorageUrl: pdSignedStorageUrlObj;
}

export const REQUEST_SOURCING_SIGNED_STORAGE_URL = gql`
  query RequestSourcingSignedStorageUrl {
    pdSignedStorageUrl {
      url
      urlParams
    }
  }
`;

class ProjectContentViewComponent extends Component<
  RouteComponentProps & projectContentViewProps
> {
  public render(): JSX.Element {
    if (this.props.requestSourcingSignedStorageUrl.loading) {
      return <Loading />;
    }
    if (this.props.requestSourcingSignedStorageUrl.error) {
      return <SimpleInlineError />;
    }
    if (this.props.project == undefined) {
      return (
        <div>
          <SimpleInlineError />
        </div>
      );
    } else {
      return (
        <div className="sourcing-project-content-view-container">
          <Switch>
            <Route
              path={`${this.props.match.path}/lifecycle`}
              render={props => {
                if (this.props.project) {
                  return (
                    <Lifecycle
                      businessContext={this.props.businessContext}
                      storageUrlObj={
                        this.props.requestSourcingSignedStorageUrl
                          .pdSignedStorageUrl
                      }
                      project={this.props.project}
                    />
                  );
                }
              }}
            />
          </Switch>
        </div>
      );
    }
  }
}

export const ProjectContentView = compose(
  graphql(REQUEST_SOURCING_SIGNED_STORAGE_URL, {
    name: 'requestSourcingSignedStorageUrl'
  })
)(withRouter(ProjectContentViewComponent));
