import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import './Submit.less';
import { BusinessContextInterface } from '../../../../Routes';
import {
  unsubmittedProject,
  projectContextEnum,
  projectFile
} from '../../Projects';
import {
  ProjectContextForm,
  projectContextFormProps
} from './components/ProjectContextForm';
import gql from 'graphql-tag';
import { Typography } from 'antd';
import { Mutation } from 'react-apollo';
import {
  ProjectFilesUploadForm,
  externalRequiredProjectFilesUploadFormProps
} from './components/ProjectFilesUploadForm';

import {
  ProjectNotesForm,
  projectNotesFormProps
} from './components/ProjectNotesForm';
import {
  ProjectSubmitConfirm,
  projectSubmitConfirmProps
} from './components/ProjectSubmitConfirm';

import QueueAnim from 'rc-queue-anim';
import _ from 'lodash';
import { UploadFile } from 'antd/lib/upload/interface';

const STEP_ONE = 1;
const STEP_TWO = 2;
const STEP_THREE = 3;
const STEP_FOUR = 4;

export const UPDATE_PROJECT_DETAILS = gql`
  mutation UpdateProjectDetails(
    $designerBusinessUuid: String!
    $projectUuid: String!
    $projectDetails: ProjectDetails!
  ) {
    updateProjectDetails(
      designerBusinessUuid: $designerBusinessUuid
      projectUuid: $projectUuid
      projectDetails: $projectDetails
    ) {
      designerBusinessUuid
      isSubmitted
      projectCategory
      projectContext
      projectName
      projectNotes
      uuid
    }
  }
`;

export const SUBMIT_PROJECT = gql`
  mutation SubmitProject(
    $designerBusinessUuid: String!
    $projectUuid: String!
  ) {
    submitProject(
      designerBusinessUuid: $designerBusinessUuid
      projectUuid: $projectUuid
    ) {
      designerBusinessUuid
      isSubmitted
      projectCategory
      projectName
      projectContext
      projectNotes
      uuid
      projectFiles {
        fileName
        isProjectAvatar
        storageKey
        uuid
      }
    }
  }
`;

interface updateProjectDetailsMutationData {
  updateProjectDetails: {
    designerBusinessUuid: string;
    isSubmitted: boolean;
    projectCategory: string;
    projectContext: string;
    projectName: string;
    projectNotes: string;
    uuid: string;
  };
}

interface updateProjectDetailsMutationVariables {
  designerBusinessUuid: string;
  projectUuid: string;
  projectDetails: projectDetails;
}

interface submitProjectMutationData {
  submitProject: {
    designerBusinessUuid: string;
    isSubmitted: boolean;
    projectCategory: string;
    projectContext: string;
    projectName: string;
    projectNotes: string;
    uuid: string;
    projectFiles: projectFile[];
  };
}

export interface enhancedUploadFile extends UploadFile {
  storageKey: string;
  mpUuid?: string;
}

interface submitProjectMutationVariables {
  designerBusinessUuid: string;
  projectUuid: string;
}

export interface projectDetails {
  projectContext: projectContextEnum | undefined;
  projectNotes: string | undefined;
}

const TOTAL_STEPS = 4;

interface SubmitProps {
  businessContext: BusinessContextInterface;
  project: unsubmittedProject;
}

interface stateProps {
  projectDetails: projectDetails;
  currentUploadFiles: enhancedUploadFile[];
  currentStep: number;
}

interface privateStateProps extends stateProps {
  readyForSubmit: boolean;
  projectFiles: projectFile[];
}

type contentProps = projectContextFormProps &
  stateProps &
  externalRequiredProjectFilesUploadFormProps &
  projectNotesFormProps &
  projectSubmitConfirmProps;

const RenderContent = (props: contentProps) => {
  switch (props.currentStep) {
    case STEP_ONE:
      return <ProjectContextForm {...props} />;
    case STEP_TWO:
      return <ProjectFilesUploadForm {...props} />;
    case STEP_THREE:
      return <ProjectNotesForm {...props} />;
    case STEP_FOUR:
      return <ProjectSubmitConfirm {...props} />;
    default:
      return <div></div>;
  }
};

/* use for transition animation later
const RenderContentV2 = (props: contentProps) => {
  return(
    <div>
      {props.currentStep == 1 ?  <ProjectContextForm key="1" {...props} /> : null}
      {props.currentStep == 2 ?  <ProjectFilesUploadForm key="2" {...props} /> : null}
      {props.currentStep == 3 ?  <ProjectNotesForm key="3" {...props} /> : null}
      {props.currentStep == 4 ?  <ProjectSubmitConfirm key="4" {...props} /> : null}
    </div>
  );
}
*/

class SubmitComponent extends Component<RouteComponentProps & SubmitProps> {
  public state: privateStateProps = {
    currentStep: 1,
    currentUploadFiles: [],
    projectDetails: {
      projectContext:
        this.props.project.projectContext == null
          ? undefined
          : this.props.project.projectContext,
      projectNotes:
        this.props.project.projectNotes == null
          ? undefined
          : this.props.project.projectNotes
    },
    projectFiles:
      this.props.project.projectFiles == null
        ? []
        : this.props.project.projectFiles,
    readyForSubmit: false
  };

  public handleUpdateFile = (updatedFile: enhancedUploadFile) => {
    const updatedCurrentUploadFiles = this.state.currentUploadFiles;

    const index = this.state.currentUploadFiles.findIndex(
      e => e.name === updatedFile.name
    );

    if (index === -1) {
      updatedCurrentUploadFiles.push(updatedFile as enhancedUploadFile);
    } else {
      updatedCurrentUploadFiles[index] = updatedFile as enhancedUploadFile;
    }
    this.setState({
      currentUploadFiles: updatedCurrentUploadFiles
    });
  };

  public handleRemoveFile = (removableFile: UploadFile) => {
    const index = _.findIndex(
      this.state.currentUploadFiles,
      (f: UploadFile) => {
        return f.uid === removableFile.uid;
      }
    );
    const updatedCurrentUploadFiles = this.state.currentUploadFiles;
    updatedCurrentUploadFiles.splice(index, 1);

    this.setState({
      currentUploadFiles: updatedCurrentUploadFiles
    });
  };

  public handleProjectContextSelect = (projectContext: projectContextEnum) => {
    const newProjectDetails = this.state.projectDetails;

    newProjectDetails.projectContext = projectContext;

    this.setState({
      projectDetails: newProjectDetails
    });
  };

  public handleProjectNotesInput = (projectNotes: string) => {
    const newProjectDetails = this.state.projectDetails;

    newProjectDetails.projectNotes = projectNotes;

    this.setState({
      projectDetails: newProjectDetails
    });
  };

  public handleSubmitProject = (updateProjectDetails: () => void) => {
    this.setState(
      {
        readyForSubmit: true
      },
      () => {
        updateProjectDetails();
      }
    );
  };

  public handleUpdateAndNext = (updateProjectDetails: () => void) => {
    this.setState(
      {
        currentStep: this.state.currentStep + 1
      },
      () => {
        updateProjectDetails();
      }
    );
  };

  public handleNext = () => {
    this.setState({
      currentStep: this.state.currentStep + 1
    });
  };

  public handleBack = () => {
    this.setState({
      currentStep: this.state.currentStep - 1
    });
  };

  public componentDidMount(): void {
    this.setState({
      currentUploadFiles: this.transformFileListToAntStandard(
        this.props.project.projectFiles
      ).reverse()
    });
  }

  public render(): JSX.Element {
    return (
      <div>
        <Mutation<submitProjectMutationData, submitProjectMutationVariables>
          onCompleted={() => this.handleNext()}
          mutation={SUBMIT_PROJECT}
          variables={{
            designerBusinessUuid: this.props.project.designerBusinessUuid,
            projectUuid: this.props.project.uuid
          }}
        >
          {(
            submitProject: () => void,
            { loading: submitProjectLoading, error: submitProjectError }
          ) => {
            return (
              <Mutation<
                updateProjectDetailsMutationData,
                updateProjectDetailsMutationVariables
              >
                onCompleted={() =>
                  this.state.readyForSubmit ? submitProject() : null
                }
                mutation={UPDATE_PROJECT_DETAILS}
                variables={{
                  designerBusinessUuid: this.props.project.designerBusinessUuid,
                  projectDetails: this.state.projectDetails,
                  projectUuid: this.props.project.uuid
                }}
              >
                {(updateProjectDetails: () => void, { loading, error }) => {
                  return (
                    <div className="submit-project-content-container">
                      <Typography.Text>
                        {this.state.currentStep}/{TOTAL_STEPS}
                      </Typography.Text>
                      <RenderContent
                        handleProjectContextSelect={
                          this.handleProjectContextSelect
                        }
                        handleProjectNotesInput={this.handleProjectNotesInput}
                        projectDetails={this.state.projectDetails}
                        currentStep={this.state.currentStep}
                        handleUpdateAndNext={this.handleUpdateAndNext}
                        updateProjectDetails={updateProjectDetails}
                        handleBack={this.handleBack}
                        handleSubmitProject={this.handleSubmitProject}
                        handleUpdateFile={this.handleUpdateFile}
                        project={this.props.project}
                        currentUploadFiles={this.state.currentUploadFiles}
                        handleRemoveFile={this.handleRemoveFile}
                        submitLoading={submitProjectLoading}
                      />
                    </div>
                  );
                }}
              </Mutation>
            );
          }}
        </Mutation>
      </div>
    );
  }

  private transformFileListToAntStandard = (projectFiles: projectFile[]) => {
    const newlist = _.map(projectFiles, (project: projectFile) => {
      return {
        mpUuid: project.uuid,
        name: project.fileName,
        size: null,
        status: 'done',
        storageKey: project.storageKey,
        thumbUrl: null,
        type: null,
        uid: project.uuid,
        url: null
      };
    });
    return newlist;
  };
}

export const Submit = withRouter(SubmitComponent);
