import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import { Typography, Collapse, Card, Icon, Badge, List } from 'antd';
import './ProjectsListView.less';
import _ from 'lodash';
import { projectCategoryMapping, pdProject } from '../../../Routes';

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface projectsListViewProps {
  projects: pdProject[];
  selectedProjectId: string | undefined;
  handleProjectSelect(id: string): void;
}

interface stateProps {
  inReview: pdProject[];
  inDevelop: pdProject[];
  inApproval: pdProject[];
}

const customPanelStyle = {
  background: '#e3e8ee',
  border: 0,
  borderRadius: 10,
  marginBottom: 20,
  marginLeft: 0,
  overflow: 'hidden'
};

class ProjectsListViewComponent extends Component<
  RouteComponentProps & projectsListViewProps
> {
  public state: stateProps = {
    inApproval: [],
    inDevelop: [],
    inReview: []
  };

  public static getDerivedStateFromProps(
    props: projectsListViewProps,
    state: stateProps
  ): Partial<stateProps> | null {
    const partitionedByStatus = _(props.projects)
      .groupBy(x => x.projectStatus)
      .value();

    const updateObj: stateProps = {} as stateProps;

    if (
      state.inReview !== partitionedByStatus['REVIEW'] &&
      partitionedByStatus['REVIEW'] !== undefined
    ) {
      updateObj.inReview = partitionedByStatus['REVIEW'];
    }
    if (
      state.inDevelop !== partitionedByStatus['DEVELOPMENT'] &&
      partitionedByStatus['DEVELOPMENT'] !== undefined
    ) {
      updateObj.inDevelop = partitionedByStatus['DEVELOPMENT'];
    }
    if (
      state.inApproval !== partitionedByStatus['APPROVAL'] &&
      partitionedByStatus['APPROVAL'] !== undefined
    ) {
      updateObj.inApproval = partitionedByStatus['APPROVAL'];
    }
    if (Object.keys(updateObj).length !== 0) {
      return updateObj;
    } else {
      return null;
    }
  }

  public renderCardList = (datasource: pdProject[]) => (
    <List
      itemLayout="vertical"
      dataSource={datasource}
      renderItem={(item: pdProject) => (
        <Card
          onClick={() => this.props.handleProjectSelect(item.uuid)}
          size="small"
          className={
            this.props.selectedProjectId == item.uuid
              ? 'sourcing-projects-list-view-card-selected'
              : 'sourcing-projects-list-view-card'
          }
        >
          <Text className="card-text-project-name">{item.projectName}</Text>
          <br />
          <Text className="card-text-business-name">
            {item.designerBusinessName}
          </Text>
          <br />
          <Text className="card-text-factory-assign">Factory - Unassigned</Text>
        </Card>
      )}
    />
  );

  public render(): JSX.Element {
    return (
      <div className="designer-project-list-view-container">
        <Collapse
          style={{ background: '#e3e8ee', marginTop: 25 }}
          bordered={false}
        >
          <Panel
            header="Review"
            key="1"
            style={customPanelStyle}
            extra={
              <span style={{ verticalAlign: 'center' }}>
                {' '}
                <Badge
                  count={this.state.inReview.length}
                  style={{
                    backgroundColor: '#FFC864',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                    fontWeight: 'bold'
                  }}
                />
              </span>
            }
          >
            {this.renderCardList(this.state.inReview)}
          </Panel>
          <Panel
            header="Develop"
            key="2"
            style={customPanelStyle}
            extra={
              <span style={{ verticalAlign: 'center' }}>
                {' '}
                <Badge
                  count={this.state.inDevelop.length}
                  style={{
                    backgroundColor: '#5880C3',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                    fontWeight: 'bold'
                  }}
                />
              </span>
            }
          >
            {this.renderCardList(this.state.inDevelop)}
          </Panel>
          <Panel
            header="Approval"
            key="3"
            style={customPanelStyle}
            extra={
              <span style={{ verticalAlign: 'center' }}>
                {' '}
                <Badge
                  count={this.state.inApproval.length}
                  style={{
                    backgroundColor: '#4ABDAC',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                    fontWeight: 'bold'
                  }}
                />
              </span>
            }
          >
            {this.renderCardList(this.state.inApproval)}
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export const ProjectsListView = withRouter(ProjectsListViewComponent);
