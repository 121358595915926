import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';
import { Error } from '../../Utils/Error';
import { BusinessContextInterface } from '../Routes';
import _ from 'lodash';
import { Data, business } from '../../Home/Home';
import { Dashboard } from './Dashboard';
import { Loading } from '../../Utils/Loading';
import AuthIdentityContext, {
  AuthIdentityInterface
} from '../../Auth/AuthIdentityContext';
import { Onboarding } from './Onboarding';
import { InviteJoining } from '../components/InviteJoining';
import { TeamInviteContext } from '../../Invite/TeamInvite';

interface factoryProps {
  businessContext: BusinessContextInterface;
  data: Data;
  businessRefetch(): void;
  businessContextChange(business: business): void;
}

class FactoryComponent extends Component<RouteComponentProps & factoryProps> {
  public static contextType: React.Context<
    AuthIdentityInterface
  > = AuthIdentityContext;

  public componentDidMount(): void {
    if (
      this.props.businessContext == undefined ||
      _.isEmpty(this.props.businessContext)
    ) {
      // we know that a user specifically requested '/factory' path in the url if we are inside this function.
      // this could be a user specifically typing it in the browser url bar
      // or just trying refreshing the factory dashboard

      this.handleInitialFactoryBusinessContext();
    }
  }

  public render(): JSX.Element {
    if (
      this.props.data.businesses.length == 0 &&
      this.context['http://makerspalm.com/app_metadata']
        .businessOnboardEligible == true
    ) {
      return <Onboarding businessRefetch={this.props.businessRefetch} />;
    }

    if (this.props.data.businesses.length == 0) {
      return (
        <InviteJoining
          context={TeamInviteContext.FACTORY_TEAM}
          businessRefetch={this.props.businessRefetch}
        />
      );
    }

    if (
      this.props.businessContext != undefined &&
      !_.isEmpty(this.props.businessContext)
    ) {
      return <Dashboard {...this.props} />;
    } else {
      return <Loading />;
    }
  }

  private handleInitialFactoryBusinessContext(): void {
    const firstFactoryBusinessFound = this.props.data.businesses.find(function(
      element
    ) {
      return element.businessType == 'FACTORY';
    });

    if (firstFactoryBusinessFound == undefined) {
      const firstAnyBusinessType = this.props.data.businesses[0];
      if (firstAnyBusinessType == undefined) {
        // do nothing. the render method below will handle the 'no business found' state
      } else {
        this.props.businessContextChange(firstAnyBusinessType);
      }
    } else {
      this.props.businessContextChange(firstFactoryBusinessFound);
    }
  }
}

export const Factory = withRouter(FactoryComponent);
