import React, { Component } from 'react';
import './UserSignUpForm.css';
import { Input, Icon, Button, Typography, Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Data } from './Invite';
import countries from '../Utils/countries';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.i18n';

export interface SignUpFormProps extends FormComponentProps {
  email: string;
  firstName?: string;
  lastName?: string;
  password: string;
  phoneCountryCode: string;
  phoneNumber: string;
}

interface DataProps {
  refetch: any;
  email: string;
  firstName?: string;
  lastName?: string;
  submitForm: any;
  title?: string;
  subTitle?: string;
  countryCode?: string;
}

interface StringMap {
  [s: string]: string;
}

const InputGroup = Input.Group;
const { Option } = Select;
const countryCodes: StringMap = countries;

type OmitFormDetails = Omit<
  SignUpFormProps,
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'password'
  | 'phoneCountryCode'
  | 'phoneNumber'
>;

class UserSignUpFormComponent extends Component<DataProps & SignUpFormProps> {
  state = {
    confirmDirty: false,
    phoneCountryCode:
      this.props.countryCode == undefined ? 'US' : this.props.countryCode,
    phoneNumber: ''
  };

  public handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values: any) => {
      if (!err) {
        values['phoneNumber'] = this.state.phoneNumber; //converting to raw value
        values['phoneCountryCode'] = this.state.phoneCountryCode; // adding country code

        //We need to refetch the data before submitting the form to double check that the invite is not past the expiration date (this is an edge case)
        this.props.refetch().then(() => {
          this.props.submitForm(values);
        });
      } else {
        console.log('ERROR!');
      }
    });
  };

  public handleConfirmBlur = (e: { target: { value: any } }) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  public compareToFirstPassword = (
    rule: unknown,
    value: unknown,
    callback: any
  ) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback("Passwords don't match");
    } else {
      callback();
    }
  };

  public validateToNextPassword = (
    rule: unknown,
    value: unknown,
    callback: any
  ) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  public handlePhoneNumberChange = (event: any) => {
    this.setState({
      phoneNumber: event.target.rawValue
    });
  };

  public handlePhoneCountryCodeChange = (phoneCountryCode: string) => {
    this.setState({
      phoneCountryCode
    });
  };

  public render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Typography.Title
          data-testid="sign-up-form-title"
          className="formTitle"
        >
          {this.props.title == undefined
            ? 'Welcome to makersPalm'
            : this.props.title}
        </Typography.Title>
        <Typography.Title
          data-testid="sign-up-form-sub-title"
          className="formTitle"
          level={3}
        >
          {this.props.subTitle == undefined
            ? "Let's get started!"
            : this.props.subTitle}
        </Typography.Title>

        <Form.Item hasFeedback validateStatus="success">
          {getFieldDecorator('email', {
            initialValue: this.props.email,
            rules: [{ required: true, message: 'Please input your email' }]
          })(
            <Input
              size="large"
              disabled
              aria-label="email-input"
              prefix={<Icon type="mail" style={{ color: '#4ABDAC' }} />}
            />
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('firstName', {
            rules: [
              { required: true, message: 'Please input your First name!' }
            ],
            initialValue:
              this.props.firstName != null ? this.props.firstName : null
          })(
            <Input
              size="large"
              aria-label="firstname-input"
              placeholder={'First name'}
              prefix={<Icon type="user" style={{ color: '#4ABDAC' }} />}
            />
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('lastName', {
            rules: [
              {
                whitespace: true,
                required: true,
                message: 'Please input your Last name!'
              }
            ],
            initialValue:
              this.props.lastName != null ? this.props.lastName : null
          })(
            <Input
              size="large"
              aria-label="lastname-input"
              placeholder={'Last name'}
              prefix={<Icon type="user" style={{ color: '#4ABDAC' }} />}
            />
          )}
        </Form.Item>

        <Form.Item hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                whitespace: true,
                pattern: RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
                message:
                  'At least 8 characters including a lower-case letter, an upper-case letter, and a number'
              },
              {
                validator: this.validateToNextPassword
              }
            ]
          })(
            <Input.Password
              size="large"
              aria-label="password-input"
              placeholder="Enter a password"
              prefix={<Icon type="lock" style={{ color: '#4ABDAC' }} />}
            />
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: "Passwords don't match"
              },
              {
                validator: this.compareToFirstPassword
              }
            ]
          })(
            <Input.Password
              onBlur={this.handleConfirmBlur}
              size="large"
              aria-label="passwordconfirm-input"
              placeholder="Confirm your password"
              prefix={<Icon type="lock" style={{ color: '#4ABDAC' }} />}
            />
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          <InputGroup compact>
            <Select
              data-testid="phone-country-select"
              style={{ fontSize: 14, width: '38%' }}
              size="large"
              defaultValue={this.state.phoneCountryCode}
              onChange={this.handlePhoneCountryCodeChange}
            >
              {Object.keys(countryCodes).map(function(
                keyName: string,
                keyIndex
              ) {
                return (
                  <Option style={{ fontSize: 14 }} value={keyName}>
                    <img
                      className="flagImg"
                      src={'/images/flags/' + keyName + '.svg'}
                    />

                    {countryCodes[keyName]}
                  </Option>
                );
              })}
            </Select>
            {getFieldDecorator('phoneNumber', {
              rules: [
                {
                  whitespace: true,
                  required: true,
                  message: 'Please enter a phone number!'
                }
              ]
            })(
              <Cleave
                aria-label="phone-input"
                style={{ width: '62%', textAlign: 'left' }}
                className="ant-input ant-input-lg"
                placeholder="phone number"
                options={{
                  phone: true,
                  phoneRegionCode: this.state.phoneCountryCode
                }}
                onChange={this.handlePhoneNumberChange}
              />
            )}
          </InputGroup>
        </Form.Item>
        <Typography className="PhoneSubText">
          We takes your privacy very seriously. We do not sell or share your
          contact information with any external companies. No unwanted calls
          ever!
        </Typography>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Create my account
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export const UserSignUpForm = Form.create<OmitFormDetails & DataProps>()(
  UserSignUpFormComponent
);
