import React, { useState, useRef, useEffect } from 'react';
import './DiscussionFilesUpload.less';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { InlineLoading } from '../../../Utils/Loading';
import { SimpleInlineError } from '../../../Utils/Error';
import { Upload, Icon, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { enhancedUploadFile } from '../../Designer/Projects/Lifecycle/Submit/Submit';
import _ from 'lodash';

const { Dragger } = Upload;

const parser = new DOMParser();

const REQUEST_SOURCING_SIGNED_UPLOAD_URL = gql`
  query PdSignedUploadUrl($projectUuid: String!) {
    PdSignedUploadUrl(projectUuid: $projectUuid) {
      url
      urlFields
    }
  }
`;

interface discussionFilesUploadProps {
  contextUuid: string;
  uploadFiles: enhancedUploadFile[];
  setUploadFiles: any;
}

const updateFileState = (
  file: UploadFile,
  CurrentUploadFilesList: enhancedUploadFile[],
  updateFileStateFn: any
): void => {
  const index = CurrentUploadFilesList.findIndex(e => e.uid === file.uid);
  if (index === -1) {
    updateFileStateFn((uploadedFiles: enhancedUploadFile[]) => [
      ...uploadedFiles,
      file
    ]);
  } else {
    const newUploadFilesList = [...CurrentUploadFilesList];
    newUploadFilesList[index] = file as enhancedUploadFile;
    updateFileStateFn(newUploadFilesList);
  }
};

const handleChange = (
  file: enhancedUploadFile,
  setUploadFiles: any,
  uploadFilesList: enhancedUploadFile[]
) => {
  const { status, uid, name } = file;

  if (status === 'uploading') {
    const index = uploadFilesList.findIndex(e => e.uid === uid);
    updateFileState(file, uploadFilesList, setUploadFiles);
  } else if (status === 'done') {
    if (file.response != undefined) {
      const xmlResponse = parser.parseFromString(file.response, 'text/xml');
      const storageKey = xmlResponse.getElementsByTagName('Key')[0]
        .childNodes[0].nodeValue;

      if (storageKey) {
        file.storageKey = storageKey;
      }

      updateFileState(file, uploadFilesList, setUploadFiles);
      message.success(`${file.name} file uploaded successfully.`);
    } else {
      message.error(`error uploading File ${file.name}`);
    }
  } else if (status === 'error') {
    updateFileState(file, uploadFilesList, setUploadFiles);
    message.error(`${file.name} file upload failed.`);
  }
};

const handleRemove = (
  file: enhancedUploadFile,
  setUploadFiles: any,
  uploadFilesList: enhancedUploadFile[]
) => {
  const fileIndex = _.findIndex(uploadFilesList, (f: enhancedUploadFile) => {
    return f.uid === file.uid;
  });

  if (fileIndex != -1) {
    const updatedUploadFilesList = [...uploadFilesList];
    updatedUploadFilesList.splice(fileIndex, 1);
    setUploadFiles(updatedUploadFilesList);
  }
};

export default function DiscussionFilesUpload(
  props: discussionFilesUploadProps
): JSX.Element {
  const { loading, error, data } = useQuery(
    REQUEST_SOURCING_SIGNED_UPLOAD_URL,
    {
      variables: {
        projectUuid: props.contextUuid
      }
    }
  );

  if (loading) {
    return <InlineLoading />;
  }
  if (error) {
    return <SimpleInlineError />;
  }

  return (
    <div className="discussion-files-upload-container">
      <Dragger
        onRemove={file =>
          handleRemove(
            file as enhancedUploadFile,
            props.setUploadFiles,
            props.uploadFiles
          )
        }
        onChange={info =>
          handleChange(
            info.file as enhancedUploadFile,
            props.setUploadFiles,
            props.uploadFiles
          )
        }
        fileList={props.uploadFiles}
        name="file"
        action={data.PdSignedUploadUrl.url}
        multiple={true}
        listType="picture"
        data={(file: UploadFile) => {
          return {
            ...JSON.parse(data.PdSignedUploadUrl.urlFields),
            'Content-Type': file.type
          };
        }}
      >
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">
          Click or drag file(s) to this area to upload
        </p>
        <p className="ant-upload-hint">
          You can upload multiple files at once. Files will automatically get
          added to the project
        </p>
      </Dragger>
    </div>
  );
}
