import React, { Component } from 'react';
import './App.css';
import Callback from './Callback/Callback';
import { withRouter, Switch } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import Invite, { InviteContext } from './Invite/Invite';
import { TeamInvite, TeamInviteContext } from './Invite/TeamInvite';
import Home from './Home/Home';
import Login from './Login/Login';
import { ApolloProvider } from 'react-apollo';
import { Loading } from './Utils/Loading';
import Auth from './Auth/Auth';
import ApolloClient from 'apollo-boost';
import PrivateRoute from './components/PrivateRoute';

export const AuthIdentityContext = React.createContext({});

const publicClient = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_CLIENT_PUBLIC_URL,
  request: operation => {
    operation.setContext((context: { headers: any }) => ({
      headers: {
        ...context.headers
      }
    }));
  }
});

class App extends Component<any, any> {
  componentDidMount() {
    const { renewSession } = Auth;

    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
  }

  checkAuthentication = (props: any) => {
    if (/access_token|id_token|error/.test(props.location.hash)) {
      Auth.handleAuthentication(props)
        .then(() => {
          const redirectpath = localStorage.getItem('requestedPath');
          localStorage.removeItem('requestedPath');
          props.history.push(redirectpath);
        })
        .catch(err => {
          console.log(err);
          props.history.push('/');
        });
    }
  };

  render() {
    return (
      <div className="App">
        <Switch>
          <Route
            exact
            path="/invite/designer/business/:token"
            render={props => (
              <ApolloProvider client={publicClient}>
                <Invite context={InviteContext.DESIGNER_BUSINESS} {...props} />
              </ApolloProvider>
            )}
          />
          <Route
            exact
            path="/invite/designer/team/:token"
            render={props => (
              <ApolloProvider client={publicClient}>
                <TeamInvite
                  context={TeamInviteContext.DESIGNER_TEAM}
                  {...props}
                />
              </ApolloProvider>
            )}
          />
          <Route
            exact
            path="/invite/factory/business/:token"
            render={props => (
              <ApolloProvider client={publicClient}>
                <Invite context={InviteContext.FACTORY_BUSINESS} {...props} />
              </ApolloProvider>
            )}
          />
          <Route
            exact
            path="/invite/factory/team/:token"
            render={props => (
              <ApolloProvider client={publicClient}>
                <TeamInvite
                  context={TeamInviteContext.FACTORY_TEAM}
                  {...props}
                />
              </ApolloProvider>
            )}
          />
          <Route
            exact
            path="/invite/sourcing/team/:token"
            render={props => (
              <ApolloProvider client={publicClient}>
                <TeamInvite
                  context={TeamInviteContext.SOURCING_TEAM}
                  {...props}
                />
              </ApolloProvider>
            )}
          />
          <Route
            path="/callback"
            render={props => {
              this.checkAuthentication(props);
              return <Callback />;
            }}
          />

          <PrivateRoute path="/" component={Home} />

          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
