import React, { Component } from 'react';
import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
  Redirect
} from 'react-router';
import './Lifecycle.less';
import {
  BusinessContextInterface,
  projectCategoryMapping,
  pdProject
} from '../../../Routes';
import {
  unsubmittedProject,
  submittedProject,
  isSubmittedProject
} from '../Projects';
import { Submit } from './Submit/Submit';
import { Steps, Typography, Select, Progress } from 'antd';
import MediaQuery from 'react-responsive';
import { number } from 'prop-types';
import { Review } from './Review/Review';
import gql from 'graphql-tag';

const { Step } = Steps;

const TWENTY = 20;

const SubmittedProjectStatusToStep: Record<string, number> = {
  REVIEW: 1,
  DEVELOPMENT: 2,
  APPROVAL: 3,
  COMPLETE: 4
};

interface LifecycleProps {
  businessContext: BusinessContextInterface;
  project: unsubmittedProject | submittedProject;
}

interface matchParams {
  projectUuid: string;
}

interface stateProps {
  current: number;
}

class LifecycleComponent extends Component<
  RouteComponentProps<matchParams> & LifecycleProps
> {
  public state: stateProps = {
    current: isSubmittedProject(this.props.project)
      ? SubmittedProjectStatusToStep[this.props.project.projectStatus]
      : 0
  };

  public onChange = (current: number) => {
    this.setState({ current });
  };

  public render(): JSX.Element {
    const { current } = this.state;
    return (
      <div>
        <div className="lifecycle-parent-container">
          <span className="lifecycle-header-container">
            <Typography.Text strong style={{ fontSize: 18 }}>
              {this.props.project.projectName}
            </Typography.Text>
            <Typography.Text disabled>
              {projectCategoryMapping[this.props.project.projectCategory]}
            </Typography.Text>
          </span>
          <MediaQuery query="(min-device-width: 768px)">
            <Steps
              current={current}
              onChange={this.onChange}
              style={{ padding: 20 }}
            >
              <Step title="Submit" />
              <Step title="Review" />
              <Step title="Develop/Sample" />
              <Step title="Approval" />
              <Step status="wait" title="Complete" />
            </Steps>
          </MediaQuery>
          <MediaQuery query="(max-device-width: 767px)">
            <Progress
              strokeColor="#4ABDAC"
              className="mobile-progress-stepper"
              size="small"
              type="circle"
              percent={TWENTY}
              format={percent => '1.\nSubmit'}
            />
          </MediaQuery>
        </div>
        <Switch>
          <Route
            path={`${this.props.match.path}/submit`}
            render={props => (
              <Submit
                project={this.props.project}
                businessContext={this.props.businessContext}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/review`}
            render={props => (
              <Review project={this.props.project as submittedProject} />
            )}
          />
          <Route
            render={() => {
              const lifecycleStatus = isSubmittedProject(this.props.project)
                ? this.props.project.projectStatus.toLowerCase()
                : 'submit';

              return (
                <Redirect
                  to={`/designer/projects/${this.props.match.params.projectUuid}/lifecycle/${lifecycleStatus}`}
                />
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}

export const Lifecycle = withRouter(LifecycleComponent);
