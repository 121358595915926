import React from 'react';
import { OnboardingProps } from '../Designer/Onboarding';
import { Typography, Button, Icon, Input } from 'antd';
import './BusinessNameForm.css';

interface BusinessNameFormProps extends OnboardingProps {
  handleBusinessNameChange: any;
  businessName: string;
  title?: string;
  subTitle?: string;
}

export const BusinessNameForm = (props: BusinessNameFormProps) => {
  return (
    <div className="BusinessNameFormContent">
      <Typography.Title level={1}>
        {props.title == undefined ? "What's the name of your Business?" : props.title}
      </Typography.Title>

      <Typography className="BusinessNameFormContentSubText">
        {props.subTitle == undefined ? "If it's just you, feel free to put your name. You can always change the name later" : props.subTitle}
      </Typography>
      <Input
        className="BusinessNameFormContentInput"
        aria-label="business-name-input"
        placeholder="e.g. My awesome Business"
        size="large"
        value={props.businessName}
        onChange={props.handleBusinessNameChange}
      ></Input>
      <div className="BusinessNameFormContentButtons">
        <Button
          type="link"
          style={{ marginRight: 15 }}
          size="large"
          onClick={() => props.prev()}
        >
          Back
        </Button>
        <Button
          size="large"
          data-testid="continue-button"
          type="primary"
          disabled={props.businessName.length < 1}
          onClick={() => props.next()}
        >
          Continue
          <Icon type="arrow-right" />
        </Button>
      </div>
    </div>
  );
};
