import React, { Component } from 'react';
import loadingAnimation from '../Animations/Lotties/loading-animation.json';
import { Typography, Spin, Icon } from 'antd';
import Lottie from 'react-lottie';
import './Loading.css';

const loadingAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const loadingIcon = <Icon type="loading" spin />;

interface LoadingProps {
  message?: string;
}

export const InlineLoading = () => (
  <Spin data-testid="inline-loading-spinner" indicator={loadingIcon} />
);

export class Loading extends Component<
  LoadingProps & React.HTMLAttributes<HTMLDivElement>
> {
  render() {
    return (
      <div {...this.props} aria-label="loading" className="loadingContainer">
        <Spin
          data-testid="loading-spinner"
          tip={this.props.message}
          size="large"
          indicator={loadingIcon}
        />
      </div>
    );
  }
}
