export default {
  AUTH0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    callbackUrl: `${window.location.origin}/callback`,
    returnTo: `${window.location.origin}`,
    responseType: 'token id_token',
    scope: 'openid profile email user_metadata app_metadata',
    audience: 'https://makerspalm.com',
    realm: 'Username-Password-Authentication'
  }
};
