import React, { Component } from 'react';
import { Typography, Button, Collapse, Icon } from 'antd';
import { projectDetails } from '../Submit';
import { RouteComponentProps, withRouter } from 'react-router';
import './ProjectSubmitConfirm.less';
const { Panel } = Collapse;

export interface projectSubmitConfirmProps {
  projectDetails: projectDetails;
  handleUpdateAndNext(updateProjectDetails: () => void): void;
  handleProjectNotesInput(projectNotes: string): void;
  updateProjectDetails(): void;
  handleBack(): void;
}

class ProjectSubmitConfirmComponent extends Component<
  RouteComponentProps & projectSubmitConfirmProps
> {
  public render(): JSX.Element {
    return (
      <div
        data-testid="project-submit-confirm-form"
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'inherit',
          justifyContent: 'space-between'
        }}
      >
        <span>
          <Typography.Title level={2}>
            Your Project has been submitted!
          </Typography.Title>
          <Typography.Text className="project-submit-confirm-subtext">
            Our Sourcing team will review it and get back to you in a jiffy
          </Typography.Text>
        </span>
        <span className="project-submit-confirm-icon-container">
          <Icon
            style={{ fontSize: 100, color: '#4ABDAC' }}
            type="check-circle"
          />
        </span>
        <span>
          <Button
            type="primary"
            data-testid="new-project-button"
            size="large"
            className="project-submit-confirm-button"
            onClick={() => this.props.history.replace('/designer/projects')}
          >
            Back to projects
          </Button>
        </span>
      </div>
    );
  }
}

export const ProjectSubmitConfirm = withRouter(ProjectSubmitConfirmComponent);
