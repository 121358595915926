import React, { Component } from 'react';
import { Typography, Icon, Button } from 'antd';
import Lottie from 'react-lottie';
import errorAnimation from '../Animations/Lotties/error-cloud.json';
import './Error.css';
const generalErrorOptions = {
  animationData: errorAnimation,
  autoplay: true,
  loop: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const HEADER_TEXT_SIZE_VALUE_3 = 3;
const HEADER_TEXT_SIZE_VALUE_4 = 4;

export const SimpleInlineError = () => (
  <Typography.Text type="warning">
    <Icon theme="filled" style={{ color: '#FFA464' }} type="warning" /> Error
  </Typography.Text>
);

export class Error extends Component<React.HTMLAttributes<HTMLDivElement>> {
  public render(): JSX.Element {
    return (
      <div data-testid="generalError" className="errorContainer">
        <Lottie height={'20%'} width={'20%'} options={generalErrorOptions} />
        <Typography.Title
          className="errorMessage"
          level={HEADER_TEXT_SIZE_VALUE_3}
        >
          Oh no!...Somethings not right
        </Typography.Title>

        <Typography.Title
          className="errorMessage"
          level={HEADER_TEXT_SIZE_VALUE_4}
        >
          The Techies have already been alerted and will try to fix it as soon
          as possible
        </Typography.Title>
        <Typography.Title level={3} className="errorMessage">
          <a href="mailto: support@makerspalm.com">support@makerspalm.com</a>
        </Typography.Title>
      </div>
    );
  }
}
