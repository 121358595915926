import React from 'react';
import { OnboardingProps } from '../Designer/Onboarding';
import { Typography, Button, Icon } from 'antd';
import './WelcomeForm.css';

interface WelcomeFormProps {
  subTitle?: string;
  title?: string;
  next(): void;
  prev(): void;
}

export const WelcomeForm = (props: WelcomeFormProps) => {
  return (
    <div className="WelcomeFormContent">
      <Typography.Title level={1}>
        {props.title == undefined
          ? "Welcome, Let's get you setup!"
          : props.title}
      </Typography.Title>

      <Typography className="WelcomeFormContentSubText">
        {props.subTitle == undefined
          ? "To get things started, we are just going to collect some basic info about your Business. We promise this won't take more than 5 minutes!"
          : props.subTitle}
      </Typography>
      <div className="WelcomeFormContentButtons">
        <Button
          size="large"
          data-testid="initial-continue-button"
          type="primary"
          onClick={() => props.next()}
        >
          Continue
          <Icon type="arrow-right" />
        </Button>
      </div>
    </div>
  );
};
