import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import Designer from './Designer/Designer';
import { Data, business } from '../Home/Home';
import AuthIdentityContext, {
  AuthIdentityInterface
} from '../Auth/AuthIdentityContext';
import { Sourcing } from './Sourcing/Sourcing';
import { Factory } from './Factory/Factory';

import _ from 'lodash';

export interface BusinessContextInterface {
  uuid: string;
  name: string;
  businessType: string;
  admins: string[];
  members: string[];
  isAdmin: boolean;
}

export interface pdProjectFile {
  authorContext: string;
  authorId?: string;
  fileName: string;
  isProjectAvatar: boolean;
  storageKey: string;
  uuid: string;
  versionId?: string;
}

export interface pdProject {
  uuid: string;
  projectCategory: string;
  projectName: string;
  projectStatus: string;
  designerBusinessUuid: string;
  designerBusinessName: string;
  projectContext: string;
  projectNotes?: string;
  projectFiles: pdProjectFile[];
}

export const projectCategoryMapping: Record<string, string> = {
  BAGS: 'Bags',
  FURNITURE: 'Furniture',
  HOME_DECOR: 'Home decor',
  JEWELRY: 'jewlery',
  SHOES: 'Shoes',
  TEXTILES: 'Textiles'
};

interface DashboardRoutesProps {
  data: Data;
  businessRefetch(): void;
}

class BusinessRoutes extends Component<
  RouteComponentProps & DashboardRoutesProps
> {
  public static contextType: React.Context<
    AuthIdentityInterface
  > = AuthIdentityContext;

  state = {
    businessContext: {} as BusinessContextInterface
  };

  public handleBusinessContextChange = (business: business) => {
    const businessContext = _.merge(business, {
      isAdmin: business.admins.includes(this.context.sub)
    });

    if (this.state.businessContext.uuid == businessContext.uuid) {
      this.setState({
        businessContext
      });
    } else if (
      this.state.businessContext.businessType == businessContext.businessType
    ) {
      this.setState({
        businessContext
      });
    } else {
      this.setState(
        {
          businessContext
        },
        () => {
          const route = '/' + businessContext.businessType.toLowerCase();
          // if business context change is of a different type of business then we want to refresh app after setting context
          this.props.history.replace(route);
        }
      );
    }
  };

  public componentDidUpdate(prevProps: DashboardRoutesProps): void {
    // update business context when new Business is onboarded
    if (this.props.data.businesses != prevProps.data.businesses) {
      const newOrUpdatedBusiness = _.differenceWith(
        this.props.data.businesses,
        prevProps.data.businesses,
        _.isEqual
      );

      if (newOrUpdatedBusiness.length != 0) {
        this.handleBusinessContextChange(newOrUpdatedBusiness[0]);
      }
    }
  }

  public componentDidMount(): void {
    const context = this.context['http://makerspalm.com/app_metadata']
      .signupContext;

    if (
      this.props.location.pathname == '/' ||
      this.props.location.pathname == undefined
    ) {
      switch (context) {
        case 'Designer':
          this.handleInitialBusinessContext('DESIGNER');
          break;
        case 'Sourcing':
          this.handleInitialBusinessContext('SOURCING');
          break;
        case 'Factory':
          this.handleInitialBusinessContext('FACTORY');
          break;
      }
    }
  }

  public render(): JSX.Element {
    const {
      businessType: setType,
      uuid: businessUuid
    } = this.state.businessContext;

    this.setBusinessContextHeaders(setType, businessUuid);

    return (
      <Switch>
        <Route
          path="/designer"
          render={props => (
            <Designer
              businessContextChange={this.handleBusinessContextChange}
              businessContext={this.state.businessContext}
              businessRefetch={this.props.businessRefetch}
              data={this.props.data}
              {...props}
            />
          )}
        />
        <Route
          path="/sourcing"
          render={props => (
            <Sourcing
              key={this.state.businessContext.uuid}
              businessContextChange={this.handleBusinessContextChange}
              businessContext={this.state.businessContext}
              businessRefetch={this.props.businessRefetch}
              data={this.props.data}
              {...props}
            />
          )}
        />
        <Route
          path="/factory"
          render={props => (
            <Factory
              businessContextChange={this.handleBusinessContextChange}
              businessContext={this.state.businessContext}
              businessRefetch={this.props.businessRefetch}
              data={this.props.data}
              {...props}
            />
          )}
        />
      </Switch>
    );
  }

  private handleInitialBusinessContext(requestedContext: string): void {
    const firstContextBusinessFound = this.props.data.businesses.find(function(
      element
    ) {
      return element.businessType == requestedContext.toUpperCase();
    });

    if (firstContextBusinessFound == undefined) {
      const firstAnyBusinessType = this.props.data.businesses[0];

      if (firstAnyBusinessType == undefined) {
        // if no business is found then push them towards their requested context and let specific Route handle the empty state.
        // this is done because each route handles empty state differently. For example. Designer route will go to onboarding mode and have them create a new business.
        const route = '/' + requestedContext.toLowerCase();
        this.props.history.replace(route);
      } else {
        const route = '/' + firstAnyBusinessType.businessType.toLowerCase();
        this.setState(
          {
            businessContext: _.merge(firstAnyBusinessType, {
              isAdmin: firstAnyBusinessType.admins.includes(this.context.sub)
            })
          },
          () => {
            this.props.history.replace(route);
          }
        );
      }
    } else {
      this.setState(
        {
          businessContext: _.merge(firstContextBusinessFound, {
            isAdmin: firstContextBusinessFound.admins.includes(this.context.sub)
          })
        },
        () => {
          const route =
            '/' + firstContextBusinessFound.businessType.toLowerCase();
          this.props.history.replace(route);
        }
      );
    }
  }

  private setBusinessContextHeaders = (
    businessType: string,
    businessUuid: string
  ) => {
    localStorage.setItem('businessType', businessType);
    localStorage.setItem('businessUuid', businessUuid);
  };
}

export const Routes = withRouter(BusinessRoutes);
