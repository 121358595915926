import React, { Component } from 'react';
import { Button, Dropdown, Layout, Menu, Icon, Typography, Drawer } from 'antd';
import Auth from '../../Auth/Auth';
import { Data, business } from '../../Home/Home';
import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
  Redirect
} from 'react-router';
import './Dashboard.less';
import { ClickParam } from 'antd/lib/menu';
import MediaQuery from 'react-responsive';
import {
  AuthIdentityConsumer,
  AuthIdentityInterface
} from '../../Auth/AuthIdentityContext';

import { BusinessContextInterface } from '../Routes';
import _ from 'lodash';
import { AdminInvites } from './AdminInvites/AdminInvites';
import { Link } from 'react-router-dom';
import { Settings } from './Settings/Settings';
import { Projects } from '../Sourcing/Projects/Projects';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;
const { SubMenu } = Menu;

interface SourcingDashboardProps {
  data: Data;
  businessContext: BusinessContextInterface;
  businessContextChange(business: business): void;
}

interface dashboardStateProps {
  collapsed: boolean;
  mobileDrawerVisible: boolean;
}

const sideBarMenu = (
  props: RouteComponentProps,
  authContext: AuthIdentityInterface,
  businessContext: BusinessContextInterface
) => (
  <Menu
    data-testid="side-bar-menu"
    className="sourcingSideBarMenu"
    mode="inline"
    defaultSelectedKeys={['1']}
  >
    <Menu.Item key="1">
      <Link to={`${props.match.url}/projects`}>
        <Icon type="edit" />
        <span>Projects</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Icon type="container" />
      <span>Orders</span>
    </Menu.Item>
    <Menu.Item key="3">
      <Icon type="credit-card" />
      <span>Payments</span>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to={`${props.match.url}/settings`}>
        <Icon type="setting" />
        <span>Settings</span>
      </Link>
    </Menu.Item>
    {!_.includes(businessContext.admins, authContext.sub) ? null : (
      <SubMenu
        key="5"
        data-testid="admin-submenu"
        title={
          <span>
            <Icon type="control" />
            <span>Admin</span>
          </span>
        }
      >
        <Menu.ItemGroup key="6" title="Admin options">
          <Menu.Item key="7">
            <Link to={`${props.match.url}/admin/invites`}>
              <Icon type="mail" />
              <span>Invites</span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>
      </SubMenu>
    )}
  </Menu>
);

class SourcingDashboard extends Component<
  RouteComponentProps & SourcingDashboardProps
> {
  public state: dashboardStateProps = {
    collapsed: true,
    mobileDrawerVisible: false
  };

  public onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  public showMobileDrawer = () => {
    this.setState({
      mobileDrawerVisible: true
    });
  };

  public onMobileDrawerClose = () => {
    this.setState({
      mobileDrawerVisible: false
    });
  };

  public businessMenu = () => {
    const businessMenuItems = this.props.data.businesses.map(
      (biz: business, index: number) => (
        <Menu.Item className="switchDashboardMenuItem" key={biz.uuid}>
          <Icon type={biz.businessType == 'SOURCING' ? 'team' : 'shop'} />
          {biz.name}
        </Menu.Item>
      )
    );

    return (
      <Menu
        data-testid="dashboard-switch-menu"
        onClick={this.handleBusinessMenuClick}
      >
        <Menu.ItemGroup key="g1" title="Switch Dashboards">
          {businessMenuItems}
        </Menu.ItemGroup>
      </Menu>
    );
  };

  public profileHeader = (authContext: AuthIdentityInterface) => (
    <div data-testid="profile-header-container">
      <Text strong>Hello, </Text>
      <Dropdown trigger={['click']} overlay={this.profileMenu}>
        <span>
          <Text
            data-testid="profile-welcome-name"
            underline
            strong
            className="profile-menu"
          >
            {authContext['http://makerspalm.com/user_metadata'].first_name}{' '}
            {authContext['http://makerspalm.com/user_metadata'].last_name}
          </Text>
          <Icon className="profile-menu" type="down" />
        </span>
      </Dropdown>
    </div>
  );

  public handleBusinessMenuClick = (e: ClickParam) => {
    const selectedBusinessContext = _.find(this.props.data.businesses, {
      uuid: e.key
    });
    if (selectedBusinessContext != undefined) {
      this.props.businessContextChange(selectedBusinessContext);
    }
  };

  public profileMenu = () => (
    <Menu
      data-testid="profile-menu-dropdown"
      onClick={this.handleProfileMenuClick}
    >
      <Menu.Item key="1">
        <Text strong>Log out</Text>
      </Menu.Item>
    </Menu>
  );

  public handleProfileMenuClick = (e: ClickParam) => {
    Auth.logout();
  };

  public render(): JSX.Element {
    return (
      <AuthIdentityConsumer>
        {authContext => (
          <Layout className="sourcingDashboard">
            <MediaQuery query="(min-device-width: 768px)">
              <Sider
                data-testid="sourcing-dashboard-sidebar"
                theme="light"
                className="sourcingSideBar"
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
              >
                <div>
                  <Dropdown
                    className="businessesSelect"
                    trigger={['click']}
                    overlay={this.businessMenu}
                  >
                    <Button
                      data-testid="dashboard-switch-button"
                      size="large"
                      shape={this.state.collapsed ? 'circle' : undefined}
                      style={{ boxShadow: 'none' }}
                    >
                      <Icon type="team" />
                      {this.state.collapsed
                        ? null
                        : this.props.businessContext.name}
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </div>
                {sideBarMenu(
                  this.props,
                  authContext,
                  this.props.businessContext
                )}
              </Sider>

              <Layout>
                <Content style={{ backgroundColor: '#e3e8ee' }}>
                  <Switch>
                    <Route
                      path={`${this.props.match.path}/projects`}
                      render={props => (
                        <Projects
                          renderHeader={this.profileHeader(authContext)}
                          businessContext={this.props.businessContext}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/admin/invites`}
                      component={AdminInvites}
                    />
                    <Route
                      path={`${this.props.match.path}/settings`}
                      render={props => (
                        <Settings
                          businessContext={this.props.businessContext}
                        />
                      )}
                    />
                    <Redirect exact from="/sourcing" to="/sourcing/projects" />
                  </Switch>
                </Content>
              </Layout>
            </MediaQuery>

            <MediaQuery query="(max-device-width: 767px)">
              <Layout>
                <Header className="dashboardHeaderMobile">
                  <Button
                    data-testid="mobile-drawer-show-button"
                    size="large"
                    shape="circle"
                    onClick={this.showMobileDrawer}
                  >
                    <Icon type="menu"></Icon>
                  </Button>
                  {this.profileHeader(authContext)}
                </Header>
                <Drawer
                  placement="left"
                  closable={true}
                  bodyStyle={{ background: '#e3e8ee', minHeight: '100%' }}
                  onClose={this.onMobileDrawerClose}
                  visible={this.state.mobileDrawerVisible}
                >
                  <div
                    className="mobileDrawerContent"
                    data-testid="mobile-dashboard-drawer"
                  >
                    <Dropdown
                      className="businessesSelect"
                      trigger={['click']}
                      overlay={this.businessMenu}
                    >
                      <Button
                        data-testid="dashboard-switch-mobile-button"
                        size="large"
                        style={{ boxShadow: 'none' }}
                      >
                        <Icon type="team" />
                        {this.props.businessContext.name}
                        <Icon type="down" />
                      </Button>
                    </Dropdown>
                  </div>
                  {sideBarMenu(
                    this.props,
                    authContext,
                    this.props.businessContext
                  )}
                </Drawer>
                <Content style={{ backgroundColor: '#e3e8ee' }}>
                  <Route
                    path={`${this.props.match.path}/projects`}
                    render={props => (
                      <Projects
                        renderHeader={this.profileHeader(authContext)}
                        businessContext={this.props.businessContext}
                      />
                    )}
                  />
                  <Switch>
                    <Route
                      path={`${this.props.match.path}/admin/invites`}
                      component={AdminInvites}
                    />
                    <Route
                      path={`${this.props.match.path}/settings`}
                      render={props => (
                        <Settings
                          businessContext={this.props.businessContext}
                        />
                      )}
                    />
                  </Switch>
                </Content>
              </Layout>
            </MediaQuery>
          </Layout>
        )}
      </AuthIdentityConsumer>
    );
  }
}

export const Dashboard = withRouter(SourcingDashboard);
