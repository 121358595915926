import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PageHeader, Progress } from 'antd';
import './Onboarding.less';
import { WelcomeForm } from '../components/WelcomeForm';
import { BusinessNameForm } from '../components/BusinessNameForm';
import BusinessInvitesForm from '../components/BusinessInvitesForm';
import { BusinessAddressForm } from './components/BusinessAddressForm';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Loading } from '../../Utils/Loading';
import { Error } from '../../Utils/Error';
import AuthIdentityContext, {
  AuthIdentityInterface
} from '../../Auth/AuthIdentityContext';

export const CREATE_FACTORY_BUSINESS = gql`
  mutation CreateFactoryBusiness(
    $adminId: String!
    $emailInvitesList: [String]
    $name: String!
    $address: FactoryOnboardingAddressInput!
  ) {
    createFactoryBusiness(
      adminId: $adminId
      address: $address
      emailInvitesList: $emailInvitesList
      name: $name
    ) {
      admins
      members
      name
      uuid
      factoryBusinessInvites {
        email
        isActive
        uuid
      }
      factoryBusinessAddresses {
        name
        addressLine1
        addressLine2
        city
        postalCode
        countryCode
        isPrimary
        nickname
      }
    }
  }
`;

const WELCOME_FORM_SUB_TITLE =
  'To get things started, we are just going to collect some basic info about your Factory Business and then you will be ready to receive Projects!';
const BUSINESS_NAME_FORM_SUB_TITLE =
  'Typically your Business name would be the same name your Company is registered under.';

const RenderContent = (props: any) => {
  switch (props.currentStep) {
    case 0:
      return <WelcomeForm subTitle={WELCOME_FORM_SUB_TITLE} {...props} />;
    case 25:
      return (
        <BusinessNameForm subTitle={BUSINESS_NAME_FORM_SUB_TITLE} {...props} />
      );
    case 50:
      return <BusinessAddressForm {...props} />;
    case 75:
      return <BusinessInvitesForm {...props} />;
    default:
      return <div></div>;
  }
};

export interface OnboardingProps {
  next(): void;
  prev(): void;
}

interface factoryBusinessOnboardingProps {
  businessRefetch(): void;
}
export interface businessAddress {
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
}

export enum addressType {
  name = 'name',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  postalCode = 'postalCode',
  state = 'state',
  countryCode = 'countryCode'
}

interface factoryBusinessOnboardingState {
  stepPercentage: number;
  businessName: string;
  businessEmailInvites: string[];
  businessAddress: businessAddress;
}

interface FactoryBusinessInvites {
  email: string;
  isActive: boolean;
  uuid: string;
}

interface FactoryBusinessAddresses {
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  countryCode: string;
  isPrimary: boolean;
  nickname: string;
}

interface Data {
  createFactoryBusiness: {
    admins: string[];
    uuid: string;
    name: string;
    members: string[];
    designerBusinessInvites: FactoryBusinessInvites[];
    factoryBusinessAddresses: FactoryBusinessAddresses[];
  };
}

interface Variables {
  adminId: string;
  emailInvitesList: string[];
  name: string;
  address: businessAddress;
}

class FactoryBusinessOnboarding extends Component<
  RouteComponentProps & factoryBusinessOnboardingProps
> {
  public static contextType: React.Context<
    AuthIdentityInterface
  > = AuthIdentityContext;

  public state: factoryBusinessOnboardingState = {
    stepPercentage: 0,
    businessName: '',
    businessEmailInvites: [],
    businessAddress: {
      addressLine1: '',
      city: '',
      countryCode: 'IN',
      name: '',
      postalCode: '',
      state: ''
    }
  };

  public next(): void {
    const stepPercentage = this.state.stepPercentage + 25;
    this.setState({ stepPercentage });
  }

  public prev(): void {
    const stepPercentage = this.state.stepPercentage - 25;
    this.setState({ stepPercentage });
  }

  public handleBusinessNameChange(businessName: string): void {
    this.setState({
      businessName
    });
    // setting default value for address name
    this.handleBusinessAddressChange(businessName, addressType.name);
  }

  public handleBusinessAddressChange(
    value: string,
    addressType: addressType
  ): void {
    const updatedAddress = { ...this.state.businessAddress };

    updatedAddress[addressType] = value;

    this.setState({
      businessAddress: updatedAddress
    });
  }

  public handleBusinessInvitesChange(
    businessEmailInvites: string[],
    complete: () => void
  ): void {
    this.setState(
      {
        businessEmailInvites: businessEmailInvites
      },
      () => {
        complete();
      }
    );
  }

  public render(): JSX.Element {
    return (
      <div>
        <PageHeader title="makersPalm" />
        <div className="FactoryOnboardingContainer" data-testid="onboarding">
          <Progress
            data-testid="progress-bar"
            percent={this.state.stepPercentage}
            status="active"
            strokeColor="#4ABDAC"
          />

          <Mutation<Data, Variables>
            onCompleted={() => this.props.businessRefetch()}
            mutation={CREATE_FACTORY_BUSINESS}
            variables={{
              address: this.state.businessAddress,
              adminId: this.context.sub,
              emailInvitesList: this.state.businessEmailInvites,
              name: this.state.businessName
            }}
          >
            {(createFactoryBusiness: () => void, { loading, error }) => {
              if (loading) return <Loading message="Creating your Business" />;
              if (error) return <Error />;
              return (
                <RenderContent
                  currentStep={this.state.stepPercentage}
                  next={() => this.next()}
                  prev={() => this.prev()}
                  complete={() => {
                    this.next();
                    createFactoryBusiness();
                  }}
                  businessName={this.state.businessName}
                  businessEmailInvites={this.state.businessEmailInvites}
                  businessAddress={this.state.businessAddress}
                  handleBusinessNameChange={(e: {
                    target: { value: string };
                  }) => this.handleBusinessNameChange(e.target.value)}
                  handleBusinessInvitesChange={(
                    emails: string[],
                    complete: () => void
                  ) => this.handleBusinessInvitesChange(emails, complete)}
                  handleBusinessAddressChange={(
                    value: string,
                    addressType: addressType
                  ) => this.handleBusinessAddressChange(value, addressType)}
                />
              );
            }}
          </Mutation>
        </div>
      </div>
    );
  }
}

export const Onboarding = withRouter(FactoryBusinessOnboarding);
