import React from 'react';
import { OnboardingProps, businessAddress, addressType } from '../Onboarding';
import {
  Typography,
  Button,
  Icon,
  Input,
  Form,
  Row,
  Col,
  Select,
  InputNumber
} from 'antd';
import './BusinessAddressForm.css';
import countries from '../../../Utils/countries';

interface BusinessAddressFormProps extends OnboardingProps {
  businessAddress: businessAddress;
  businessName: string;
  handleBusinessAddressChange(value: string, addressType: addressType): void;
}

interface StringMap {
  [s: string]: string;
}

const { Option } = Select;
const countryCodes: StringMap = countries;

const isAbleToContinue = (address: businessAddress): boolean => {
  return (
    address.name.length > 0 &&
    address.addressLine1.length > 0 &&
    address.city.length > 0 &&
    address.state.length > 0 &&
    address.postalCode.length > 0 &&
    address.countryCode.length > 0
  );
};

export const BusinessAddressForm = (props: BusinessAddressFormProps) => {
  return (
    <div className="BusinessAddressFormContent">
      <Typography.Title level={1}>
        What's your Business Address?
      </Typography.Title>

      <Typography className="BusinessAddressFormContentSubText">
        Enter an address where you would be able to recieve packages from
        Designers and vice versa.
      </Typography>
      <Form className="ant-advanced-search-form">
        <Row>
          <Form.Item>
            <Input
              size="large"
              placeholder="Business Name"
              aria-label="business-name-input"
              onChange={e =>
                props.handleBusinessAddressChange(
                  e.target.value,
                  addressType.name
                )
              }
              value={props.businessAddress.name}
            />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item>
            <Input
              size="large"
              placeholder="Address line 1"
              aria-label="business-address-line-1-input"
              onChange={e =>
                props.handleBusinessAddressChange(
                  e.target.value,
                  addressType.addressLine1
                )
              }
              value={props.businessAddress.addressLine1}
            />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item>
            <Input
              size="large"
              placeholder="Address line 2 (optional)"
              aria-label="business-address-line-2-input"
              onChange={e =>
                props.handleBusinessAddressChange(
                  e.target.value,
                  addressType.addressLine2
                )
              }
              value={props.businessAddress.addressLine2}
            />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item>
            <Input
              size="large"
              placeholder="City"
              aria-label="business-address-city-input"
              onChange={e =>
                props.handleBusinessAddressChange(
                  e.target.value,
                  addressType.city
                )
              }
              value={props.businessAddress.city}
            />
          </Form.Item>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item>
              <Input
                size="large"
                placeholder="State/Province/Region"
                aria-label="business-address-state-input"
                onChange={e =>
                  props.handleBusinessAddressChange(
                    e.target.value,
                    addressType.state
                  )
                }
                value={props.businessAddress.state}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                size="large"
                placeholder="Postal code"
                aria-label="business-address-postal-code-input"
                onChange={e =>
                  props.handleBusinessAddressChange(
                    e.target.value,
                    addressType.postalCode
                  )
                }
                value={props.businessAddress.postalCode}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Form.Item>
            <Select
              data-testid="address-country-select"
              value={props.businessAddress.countryCode}
              onChange={(value: string) =>
                props.handleBusinessAddressChange(
                  value,
                  addressType.countryCode
                )
              }
              size="large"
            >
              {Object.keys(countryCodes).map(function(
                keyName: string,
                keyIndex: number
              ) {
                return (
                  <Option style={{ fontSize: 14 }} value={keyName}>
                    <img
                      className="flagImg"
                      src={'/images/flags/' + keyName + '.svg'}
                    />

                    {countryCodes[keyName]}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Row>
      </Form>

      <div className="BusinessAddressFormContentButtons">
        <Button
          type="link"
          style={{ marginRight: 15 }}
          size="large"
          onClick={() => props.prev()}
        >
          Back
        </Button>
        <Button
          size="large"
          data-testid="continue-button"
          type="primary"
          disabled={!isAbleToContinue(props.businessAddress)}
          onClick={() => props.next()}
          className="continueStepButton"
        >
          Continue
          <Icon type="arrow-right" />
        </Button>
      </div>
    </div>
  );
};
