import React from 'react';
import { Typography, Button, Input } from 'antd';
import { projectDetails } from '../Submit';
import './ProjectNotesForm.less';

const { TextArea } = Input;

export interface projectNotesFormProps {
  projectDetails: projectDetails;
  submitLoading: boolean;
  handleProjectNotesInput(projectNotes: string): void;
  updateProjectDetails(): void;
  handleBack(): void;
  handleSubmitProject(updateProjectDetails: () => void): void;
}

export const ProjectNotesForm = (props: projectNotesFormProps) => {
  return (
    <div
      data-testid="project-notes-form"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'inherit',
        justifyContent: 'space-between'
      }}
    >
      <span>
        <Typography.Title level={2}>
          Any details we should know ?
        </Typography.Title>
        <Typography.Text className="project-notes-subtext">
          Leave notes/comments below about anything you want us to know about
          the project
        </Typography.Text>
      </span>
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly'
        }}
      >
        <TextArea
          data-testid="project-notes-input"
          placeholder="There's a reason we made this text area so large. The more detail the better!"
          className="project-notes-text-area"
          autosize={{ minRows: 12, maxRows: 12 }}
          value={props.projectDetails.projectNotes}
          onChange={e => props.handleProjectNotesInput(e.target.value)}
        />
      </span>
      <span>
        <Button
          type="link"
          data-testid="new-project-button"
          size="large"
          style={{ width: 100 }}
          onClick={() => props.handleBack()}
        >
          Back
        </Button>
        <Button
          type="primary"
          loading={props.submitLoading}
          data-testid="project-notes-form-next-button"
          size="large"
          className="project-notes-submit-button"
          onClick={() => props.handleSubmitProject(props.updateProjectDetails)}
        >
          Submit Project
        </Button>
      </span>
    </div>
  );
};
