import React, { Component } from 'react';
import { Form, Row, Col, Input, Radio, Button } from 'antd';
import { FormComponentProps, ValidateCallback } from 'antd/lib/form';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  factoryInvite,
  designerInvite,
  GET_FACTORY_INVITES,
  GET_DESIGNER_INVITES
} from '../AdminInvites';
import './CreateInviteDrawerForm.less';

const ROW_GUTTER_SPACING = 16;

export const CREATE_FACTORY_INVITE = gql`
  mutation CreateFactoryInvite(
    $email: String!
    $firstName: String!
    $lastName: String
  ) {
    createFactoryInvite(
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      email
      firstName
      lastName
      expirationDate
      uuid
      isExpired
      userSignedUp
      inviteToken
    }
  }
`;

export const CREATE_DESIGNER_INVITE = gql`
  mutation CreateDesignerInvite(
    $email: String!
    $firstName: String!
    $lastName: String
  ) {
    createDesignerInvite(
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      email
      firstName
      lastName
      expirationDate
      uuid
      isExpired
      userSignedUp
      inviteToken
    }
  }
`;

interface CreateInviteDrawerFormProps {
  onClose(): void;
}

interface MutationVariables {
  email: string;
  firstName: string;
  lastName?: string;
}

interface factoryInvitesData {
  createFactoryInvite: factoryInvite;
}

interface designerInvitesData {
  createDesignerInvite: designerInvite;
}

interface stateProps {
  email: string;
  firstName: string;
  lastName?: string;
}

interface formProps extends stateProps {
  inviteType: 'designer' | 'factory';
}

class CreateInviteDrawerFormComponent extends Component<
  FormComponentProps<formProps> & CreateInviteDrawerFormProps
> {
  public state: stateProps = {
    email: '',
    firstName: ''
  };

  public handleSubmit = (
    e: { preventDefault: () => void },
    factoryMutation: () => void,
    designerMutation: () => void
  ) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values: formProps) => {
      if (!err) {
        this.setState(
          {
            ...values
          },
          () => {
            values.inviteType == 'designer'
              ? designerMutation()
              : factoryMutation();
          }
        );
      } else {
        console.log('ERROR!');
      }
    });
  };

  public render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;

    return (
      <Mutation<factoryInvitesData, MutationVariables>
        onCompleted={() => this.props.onClose()}
        mutation={CREATE_FACTORY_INVITE}
        variables={{
          email: this.state.email,
          firstName: this.state.firstName,
          lastName: this.state.lastName
        }}
        update={(cache, { data: factoryMutationResultData }) => {
          if (factoryMutationResultData) {
            const factoryInvitesData = cache.readQuery<{
              factoryInvites: factoryInvite[];
            }>({ query: GET_FACTORY_INVITES });

            cache.writeQuery({
              data: {
                factoryInvites: [
                  factoryMutationResultData.createFactoryInvite
                ].concat(
                  factoryInvitesData == null
                    ? []
                    : factoryInvitesData.factoryInvites
                )
              },
              query: GET_FACTORY_INVITES
            });
          }
        }}
      >
        {(
          createFactoryInvite: () => void,
          {
            loading: factoryLoading,
            error: factoryError,
            called: factoryCalled
          }
        ) => (
          <Mutation<designerInvitesData, MutationVariables>
            onCompleted={() => this.props.onClose()}
            mutation={CREATE_DESIGNER_INVITE}
            variables={{
              email: this.state.email,
              firstName: this.state.firstName,
              lastName: this.state.lastName
            }}
            update={(cache, { data: designerMutationResultData }) => {
              if (designerMutationResultData) {
                const designerInvitesData = cache.readQuery<{
                  designerInvites: designerInvite[];
                }>({ query: GET_DESIGNER_INVITES });

                cache.writeQuery({
                  data: {
                    designerInvites: [
                      designerMutationResultData.createDesignerInvite
                    ].concat(
                      designerInvitesData == null
                        ? []
                        : designerInvitesData.designerInvites
                    )
                  },
                  query: GET_DESIGNER_INVITES
                });
              }
            }}
          >
            {(
              createDesignerInvite: () => void,
              {
                loading: designerLoading,
                error: designerError,
                called: designerCalled
              }
            ) => {
              return (
                <div data-testid="create-invite-drawer-form">
                  <Form
                    onSubmit={e =>
                      this.handleSubmit(
                        e,
                        createFactoryInvite,
                        createDesignerInvite
                      )
                    }
                    layout="vertical"
                    hideRequiredMark
                  >
                    <Row gutter={ROW_GUTTER_SPACING}>
                      <Form.Item label="Select Invite Type">
                        {getFieldDecorator('inviteType', {
                          rules: [
                            {
                              message: 'Please select a invite type',
                              required: true
                            }
                          ]
                        })(
                          <Radio.Group buttonStyle="solid">
                            <Radio.Button
                              value="designer"
                              data-testid="radio-designer-select"
                            >
                              Designer
                            </Radio.Button>
                            <Radio.Button
                              value="factory"
                              data-testid="radio-factory-select"
                            >
                              Factory
                            </Radio.Button>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </Row>
                    <Row gutter={ROW_GUTTER_SPACING}>
                      <Form.Item label="Email">
                        {getFieldDecorator('email', {
                          rules: [
                            {
                              message: 'Please enter a valid email address',
                              type: 'email'
                            },
                            {
                              message: 'Please input your E-mail!',
                              required: true
                            }
                          ],
                          validateTrigger: 'onBlur'
                        })(
                          <Input
                            data-testid="email-input"
                            placeholder="Please enter an email address"
                          />
                        )}
                      </Form.Item>
                    </Row>
                    <Row gutter={16}>
                      <Form.Item label="First Name">
                        {getFieldDecorator('firstName', {
                          rules: [
                            {
                              message: 'Please enter a first name',
                              required: true
                            }
                          ]
                        })(
                          <Input
                            data-testid="first-name-input"
                            placeholder="Enter a First Name"
                          />
                        )}
                      </Form.Item>
                    </Row>
                    <Row gutter={16}>
                      <Form.Item label="Last Name (Optional)">
                        {getFieldDecorator('lastName', {
                          rules: [
                            {
                              message: 'Please enter a last name',
                              required: false
                            }
                          ]
                        })(
                          <Input
                            data-testid="last-name-input"
                            placeholder="Enter a last name"
                          />
                        )}
                      </Form.Item>
                    </Row>

                    <div className="InviteDrawerButtonFormContainer">
                      <Button
                        data-testid="create-invite-cancel-button"
                        disabled={factoryLoading || designerLoading}
                        onClick={this.props.onClose}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        data-testid="create-invite-submit-button"
                        loading={factoryLoading || designerLoading}
                        htmlType="submit"
                        type="primary"
                      >
                        Create
                      </Button>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export const CreateInviteDrawerForm = Form.create<
  FormComponentProps<formProps> & CreateInviteDrawerFormProps
>()(CreateInviteDrawerFormComponent);
