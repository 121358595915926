import React, { Component } from 'react';
import {
  List,
  Card,
  Icon,
  Avatar,
  Typography,
  Button,
  Drawer,
  Popconfirm
} from 'antd';
import './TeamView.less';
import { graphql, Mutation } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import { BusinessContextInterface } from '../../Routes';
import { OmitProps } from 'antd/lib/transfer/renderListBody';
import { Loading, InlineLoading } from '../../../Utils/Loading';
import { CreateTeamInviteDrawerForm } from './CreateTeamInviteDrawerForm';
import { INVALIDATE_TEAM_INVITE } from '../InviteJoining';
import { SimpleInlineError } from '../../../Utils/Error';
import { ApolloError } from 'apollo-boost';
import { EditTeamMemberDrawer } from './EditTeamMemberDrawer';
import { withRouter, RouteComponentProps } from 'react-router';
import MediaQuery from 'react-responsive';
import { InviteContext } from '../../../Invite/Invite';
import { TeamInviteContext } from '../../../Invite/TeamInvite';
const { Text } = Typography;

const INVITE_MEMBER_DRAWER_WIDTH = 360;

const TEAM_MEMBER_DETAILS_DRAWER_WIDTH = 640;

export const GET_USER_DETAILS = gql`
  query UserDetails($userIds: [String]!) {
    userDetails(userIds: $userIds) {
      email
      firstName
      phoneCountryCode
      lastName
      phoneNumber
      userId
    }
  }
`;

export const GET_TEAM_INVITES = gql`
  query TeamInvites($businessId: String!, $inviteContext: TeamInviteContext!) {
    teamInvites(businessId: $businessId, inviteContext: $inviteContext) {
      email
      isActive
      inviteToken
      uuid
    }
  }
`;

interface cardData extends userDetail, teamInvite {}

export interface userDetail {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneCountryCode: string;
  userId: string;
}

export interface teamInvite {
  uuid: string;
  email: string;
  isActive: boolean;
  inviteToken: string;
}

interface userDetailData {
  userDetails: cardData[];
  loading: boolean;
}

interface teamInviteData {
  teamInvites: cardData[];
  loading: boolean;
  refetch(): void;
}

interface invalidateTeamInviteResult {
  called: boolean;
  error: ApolloError;
  loading: boolean;
}

interface invalidatedTeamInviteData {
  invalidateTeamInvite(): invalidateTeamInviteResult;
}

interface stateProps {
  users: cardData[];
  invites: cardData[];
  visible: boolean;
  teamMemberDetailsVisible: boolean;
  selectedUserDetails: userDetail;
}

interface TeamViewProps {
  businessContext: BusinessContextInterface;
  getUserDetails: userDetailData;
  getTeamInvites: teamInviteData;
  invalidateTeamInviteMutation: invalidatedTeamInviteData;
  teamContext: TeamInviteContext;
}

interface InvalidateInviteMutationVariables {
  businessUuid: string;
  inviteToken: string;
  inviteContext: string;
}

interface InvalidateInviteMutationData {
  invalidateTeamInvite: {
    businessUuid: string;
    inviteToken: string;
    isActive: boolean;
    email: string;
    name: string;
    uuid: string;
  };
}

const NEW_MEMBER_OBJECT: cardData = {
  email: '',
  firstName: '',
  inviteToken: '',
  isActive: false,
  lastName: '',
  phoneCountryCode: '',
  phoneNumber: '',
  userId: 'new member',
  uuid: ''
};

export const getMemberType = (
  userId: string,
  businessContext: BusinessContextInterface
): string => {
  if (businessContext.admins.includes(userId)) {
    return 'Admin';
  }
  return 'Member';
};

const renderUserCardListItem = (
  showTeamMemberDetails: (userDetail: userDetail) => void,
  item: cardData,
  businessContext: BusinessContextInterface
) => (
  <List.Item data-testid="team-card-list-item" className="TeamViewListItem">
    <Card
      data-testid="user-team-card"
      className="TeamViewCard"
      actions={[
        <Icon
          data-testid="user-card-edit-button"
          type="edit"
          onClick={() => showTeamMemberDetails(item)}
          key="edit"
        />
      ]}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
      >
        <Avatar
          style={{
            backgroundColor: '#edfcf8',
            borderColor: '#4ABDAC',
            borderStyle: 'solid',
            borderWidth: 2.2
          }}
          data-testid="user-card-avatar"
          className="DesignerTeamViewAvatar"
          size="large"
        >
          <Text strong className="designerAvatarText">
            {item.firstName.substr(0, 1).toUpperCase()}
          </Text>
        </Avatar>
        <Text strong style={{ marginTop: 15 }}>
          {item.firstName.charAt(0).toUpperCase() + item.firstName.slice(1)}{' '}
          {item.lastName.charAt(0).toUpperCase() + item.lastName.slice(1)}
        </Text>
        <Text>{item.email}</Text>
        <Text>({getMemberType(item.userId, businessContext)})</Text>
      </div>
    </Card>
  </List.Item>
);

const renderInviteNewMemberCardListItem = (showDrawer: () => void) => (
  <List.Item data-testid="team-card-list-item" className="TeamViewListItem">
    <Card
      data-testid="invite-new-member-action-card"
      onClick={() => showDrawer()}
      hoverable
      className="TeamViewInviteCard"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          verticalAlign: 'center',
          textAlign: 'center'
        }}
      >
        <Icon
          type="user-add"
          data-testid="invite-team-member-icon"
          theme="outlined"
          style={{
            color: '#4ABDAC',
            fontSize: 48,
            alignSelf: 'center'
          }}
        />
        <Text strong style={{ marginTop: 15 }}>
          Invite team member
        </Text>
      </div>
    </Card>
  </List.Item>
);

const renderPendingInviteCardListItem = (
  props: TeamViewProps,
  item: cardData
) => (
  <List.Item data-testid="team-card-list-item" className="TeamViewListItem">
    <Card
      data-testid="pending-invite-team-card"
      className="TeamViewCard"
      actions={[rendePendingInviteCardActions(props, item)]}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden'
        }}
      >
        <Icon
          data-testid="pending-invite-card-icon"
          type="mail"
          theme="outlined"
          style={{
            color: '#4ABDAC',
            fontSize: 50,
            alignSelf: 'center'
          }}
        />
        <Text strong style={{ marginTop: 15 }}>
          Invite Pending
        </Text>
        <Text>{item.email}</Text>
        <Text>(Member)</Text>
      </div>
    </Card>
  </List.Item>
);

const rendePendingInviteCardActions = (
  props: TeamViewProps,
  invite: teamInvite
): JSX.Element => {
  return (
    <Mutation<InvalidateInviteMutationData, InvalidateInviteMutationVariables>
      mutation={INVALIDATE_TEAM_INVITE}
      variables={{
        businessUuid: props.businessContext.uuid,
        inviteContext: TeamInviteContext[props.teamContext],
        inviteToken: invite.inviteToken
      }}
      refetchQueries={[
        {
          query: GET_TEAM_INVITES,
          variables: {
            businessId: props.businessContext.uuid,
            inviteContext: TeamInviteContext[props.teamContext]
          }
        }
      ]}
    >
      {(invalidateDesignerInvite: () => void, { loading, error, called }) => {
        if (loading) return <InlineLoading />;
        if (error) return <SimpleInlineError />;
        return (
          <Popconfirm
            okType="default"
            cancelText="No"
            data-test-id="cancel-team-invite-pop-confirm"
            title="Sure you wanna Cancel?"
            onConfirm={() => invalidateDesignerInvite()}
          >
            <a data-testid="cancel-team-invite-link" style={{ fontSize: 14 }}>
              Cancel Invite
            </a>
          </Popconfirm>
        );
      }}
    </Mutation>
  );
};

class TeamViewComponent extends Component<TeamViewProps & RouteComponentProps> {
  public static getDerivedStateFromProps(
    props: TeamViewProps,
    state: stateProps
  ): Partial<stateProps> | null {
    if (
      props.getUserDetails.userDetails !== state.users &&
      props.getUserDetails.userDetails !== undefined
    ) {
      return {
        users: props.getUserDetails.userDetails
      };
    }

    if (
      props.getTeamInvites.teamInvites !== state.invites &&
      props.getTeamInvites.teamInvites !== undefined
    ) {
      return {
        invites: props.getTeamInvites.teamInvites
      };
    }
    return null;
  }

  public state: stateProps = {
    invites: [],
    selectedUserDetails: {} as userDetail,
    teamMemberDetailsVisible: false,
    users: [],
    visible: false
  };

  public showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  public showTeamMemberDetailsDraw = (userDetail: userDetail) => {
    this.setState(
      {
        selectedUserDetails: userDetail
      },
      () => {
        this.setState({
          teamMemberDetailsVisible: true
        });
      }
    );
  };

  public onClose = () => {
    this.setState({
      visible: false
    });
  };

  public onCloseTeamMemberDetails = () => {
    this.setState({
      teamMemberDetailsVisible: false
    });
  };

  public renderTeamView = (isMobile: boolean) => (
    <div>
      <List
        data-testid="team-view-cards-list"
        grid={isMobile ? { gutter: 8, column: 2 } : { gutter: 16, column: 4 }}
        dataSource={this.state.users
          .concat(this.state.invites)
          .concat([NEW_MEMBER_OBJECT])}
        renderItem={item => {
          if (item.userId == undefined) {
            return renderPendingInviteCardListItem(this.props, item);
          }

          if (item.userId == 'new member') {
            return renderInviteNewMemberCardListItem(this.showDrawer);
          }
          return renderUserCardListItem(
            this.showTeamMemberDetailsDraw,
            item,
            this.props.businessContext
          );
        }}
      />
      <Drawer
        destroyOnClose={true}
        placement={isMobile ? 'bottom' : 'right'}
        title="Invite a new team member"
        width={isMobile ? '100%' : INVITE_MEMBER_DRAWER_WIDTH}
        height={isMobile ? '100%' : undefined}
        onClose={this.onClose}
        visible={this.state.visible}
      >
        <CreateTeamInviteDrawerForm
          businessContext={this.props.businessContext}
          onClose={this.onClose}
          teamContext={this.props.teamContext}
        />
      </Drawer>
      <Drawer
        width={isMobile ? '100%' : TEAM_MEMBER_DETAILS_DRAWER_WIDTH}
        height={isMobile ? '100%' : undefined}
        placement={isMobile ? 'bottom' : 'right'}
        title="Team Member Details"
        closable={true}
        onClose={this.onCloseTeamMemberDetails}
        visible={this.state.teamMemberDetailsVisible}
      >
        <EditTeamMemberDrawer
          teamContext={this.props.teamContext}
          onClose={this.onCloseTeamMemberDetails}
          user={this.state.selectedUserDetails}
          businessContext={this.props.businessContext}
          isMobile={isMobile}
        />
      </Drawer>
    </div>
  );

  public render(): JSX.Element {
    if (
      this.props.getUserDetails.loading ||
      this.props.getTeamInvites.loading
    ) {
      return <Loading />;
    }
    return (
      <div>
        <MediaQuery query="(min-device-width: 768px)">
          {this.renderTeamView(false)}
        </MediaQuery>
        <MediaQuery query="(max-device-width: 767px)">
          {this.renderTeamView(true)}
        </MediaQuery>
      </div>
    );
  }
}
export const TeamView = compose(
  graphql(GET_USER_DETAILS, {
    name: 'getUserDetails',
    options: (props: TeamViewProps) => ({
      variables: {
        userIds: props.businessContext.admins.concat(
          props.businessContext.members
        )
      }
    })
  }),
  graphql(GET_TEAM_INVITES, {
    name: 'getTeamInvites',
    options: (props: TeamViewProps) => ({
      variables: {
        businessId: props.businessContext.uuid,
        inviteContext: TeamInviteContext[props.teamContext]
      }
    })
  })
)(withRouter(TeamViewComponent));
