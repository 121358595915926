import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import './DiscussionView.less';
import { useMutation } from 'react-apollo';
import {
  message,
  attachment
} from '../../Sourcing/Projects/ProjectContent/Lifecycle/Review/Review';
import gql from 'graphql-tag';
import {
  List,
  Comment,
  Form,
  Input,
  Button,
  Typography,
  Avatar,
  Icon,
  Modal,
  Upload
} from 'antd';
import { SimpleInlineError } from '../../../Utils/Error';
import DiscussionFilesUpload from './DiscussionFilesUpload';
import { enhancedUploadFile } from '../../Designer/Projects/Lifecycle/Submit/Submit';
import _ from 'lodash';
import { pdSignedStorageUrlObj } from '../../Sourcing/Projects/ProjectContent/ProjectContentView';

const { TextArea } = Input;
const { Text } = Typography;
const TEXT_AREA_ROWS_SIZE = 4;
const AUTOMATED_MESSAGE_USER_ID = 'MAKERSPALM';

export const POST_MESSAGE = gql`
  mutation PostMessage($conversationUuid: String!, $messageText: String!) {
    postMessage(
      conversationUuid: $conversationUuid
      messageText: $messageText
    ) {
      uuid
      messageText
      authorUserId
      authorEmail
      authorFirstName
      authorLastName
    }
  }
`;

const PD_MODIFY_PROJECT_FILES = gql`
  mutation PdModifyProjectFiles(
    $action: PdModifyFilesAction!
    $projectUuid: String!
    $designerBusinessUuid: String!
    $projectFiles: [PdProjectFileInput]!
  ) {
    pdModifyProjectFiles(
      action: $action
      projectUuid: $projectUuid
      designerBusinessUuid: $designerBusinessUuid
      projectFiles: $projectFiles
    ) {
      authorContext
      authorId
      fileName
      storageKey
      uuid
      versionId
      isProjectAvatar
    }
  }
`;

interface discussionViewProps {
  messages: message[];
  conversationUuid: string;
  contextType: string;
  convoTag: string;
  contextUuid: string;
  designerBusinessUuid: string;
  storageUrlObj: pdSignedStorageUrlObj;
  messageUploadCallback?(files: fileAttachment[]): void;
}

export interface fileAttachment {
  uuid: string;
  fileName: string;
  storageKey: string;
  authorContext: string;
  authorId: string;
  versionId: string;
  isProjectAvatar: boolean;
}

const transformToModifyProjectFile = (
  rawProjectfiles: enhancedUploadFile[]
) => {
  const newlist = _.map(rawProjectfiles, (project: enhancedUploadFile) => {
    return {
      fileName: project.name,
      storageKey: project.storageKey
    };
  });
  return newlist;
};

const buildFileUploadNotifMessage = (files: fileAttachment[]): message => {
  const attachmentsList = _.map(files, (file: fileAttachment) => {
    return {
      fileName: file.fileName,
      storageKey: file.storageKey,
      uuid: file.uuid
    };
  });
  return {
    messageText: 'New File(s) were Uploaded',
    authorUserId: 'MAKERSPALM',
    uuid: 'temp',
    attachments: attachmentsList
  } as message;
};

const renderNotificationMessage = (
  item: message,
  storageUrlObj: pdSignedStorageUrlObj
): JSX.Element => (
  <Comment
    author={
      <Text strong style={{ color: 'darkgray', fontStyle: 'italic' }}>
        Notification
      </Text>
    }
    content={
      <div>
        <p>{item.messageText}</p>
        <span>
          {item.attachments.length > 0
            ? renderAttachmentFiles(item.attachments, storageUrlObj)
            : null}
        </span>
      </div>
    }
    avatar={
      <Avatar
        src="/images/MP_logo_notification_avatar.png"
        className="discussion-view-message-avatar-automated"
        size="small"
      />
    }
  />
);

const renderAttachmentFiles = (
  attachments: attachment[],
  storageUrlObj: pdSignedStorageUrlObj
) => (
  <div className="discussion-view-notification-attachment-view">
    <Upload
      style={{ height: '10%', display: 'flex', flexDirection: 'row' }}
      listType="picture-card"
      fileList={_.map(attachments, (attachment: attachment) => {
        return {
          name: attachment.fileName,
          size: 1,
          status: 'done',
          storageKey: attachment.storageKey,
          thumbUrl:
            storageUrlObj.url + attachment.storageKey + storageUrlObj.urlParams,
          type: 'image/jpeg',
          uid: attachment.uuid,
          url:
            storageUrlObj.url + attachment.storageKey + storageUrlObj.urlParams
        };
      })}
    ></Upload>
  </div>
);

const renderUserMessage = (item: message): JSX.Element => (
  <Comment
    author={
      <Text strong style={{ color: '#2F2F2F' }}>
        {item.authorFirstName + ' ' + item.authorLastName}
      </Text>
    }
    content={<p>{item.messageText}</p>}
    avatar={
      <Avatar className="discussion-view-message-avatar-user" size="small">
        <Text strong className="discussion-view-message-avatar-text">
          {item.authorFirstName.charAt(0).toUpperCase()}
        </Text>
      </Avatar>
    }
  />
);

export default function DiscussionView(
  props: discussionViewProps
): JSX.Element {
  const [messageValue, setMessageValue] = useState('');
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [messages, setMessages] = useState(props.messages);
  const messageList: React.RefObject<HTMLDivElement> = useRef(null);

  const scrollToBottom = (): void => {
    const messageListValue = messageList.current;
    if (messageListValue) {
      messageListValue.scrollIntoView({ behavior: 'auto' });
    }
  };

  const [uploadFiles, setUploadFiles] = useState([]);

  const [postMessage, { loading, error }] = useMutation(POST_MESSAGE, {
    onCompleted: data => {
      setMessages(messages => [...messages, data.postMessage]);
      scrollToBottom();
      setMessageValue('');
    }
  });

  const [
    modifyProjectFiles,
    { loading: modifyProjectFilesLoading, error: modifyProjectFilesError }
  ] = useMutation(PD_MODIFY_PROJECT_FILES, {
    onCompleted: data => {
      setIsUploadModalVisible(false);
      setMessages(messages => [
        ...messages,
        buildFileUploadNotifMessage(data.pdModifyProjectFiles)
      ]);
      scrollToBottom();
      if (props.messageUploadCallback) {
        props.messageUploadCallback(data.pdModifyProjectFiles);
      }
    }
  });

  useEffect(() => {
    scrollToBottom();
  }, []);

  const handleSubmit = (): void => {
    postMessage({
      variables: {
        conversationUuid: props.conversationUuid,
        messageText: messageValue
      }
    });
  };

  return (
    <div className="discussion-view-container">
      <List
        className="discussion-view-message-list"
        itemLayout="horizontal"
        dataSource={messages}
        locale={{
          emptyText: <Text strong>Post a message to start a discussion</Text>
        }}
        renderItem={(item: message): JSX.Element => (
          <List.Item>
            {item.authorUserId == AUTOMATED_MESSAGE_USER_ID
              ? renderNotificationMessage(item, props.storageUrlObj)
              : renderUserMessage(item)}
          </List.Item>
        )}
        footer={<div ref={messageList}></div>}
      ></List>

      <div className="discussion-view-message-box">
        <Form style={{ padding: 0 }}>
          <Form.Item>
            <TextArea
              disabled={loading}
              rows={TEXT_AREA_ROWS_SIZE}
              onChange={(e): void => setMessageValue(e.target.value)}
              value={messageValue}
            />
          </Form.Item>
          <Form.Item className="disussion-view-message-box-options">
            {error ? (
              <span>
                <SimpleInlineError /> Posting Message{' '}
              </span>
            ) : (
              <span />
            )}
            <Button
              className="discussion-view-upload-file-button"
              loading={loading}
              onClick={() => setIsUploadModalVisible(true)}
              type="link"
            >
              <Icon type="upload" />
              <Typography.Text underline style={{ color: '#4ABDAC' }}>
                Upload File(s)
              </Typography.Text>
            </Button>
            <Button
              className="discussion-view-post-message-button"
              htmlType="submit"
              loading={loading}
              disabled={messageValue.length < 1}
              onClick={() => handleSubmit()}
              type="link"
            >
              <Typography.Text underline style={{ color: '#4ABDAC' }}>
                Post Message
              </Typography.Text>
            </Button>
          </Form.Item>
        </Form>
        <Modal
          okText="Post"
          closable={false}
          confirmLoading={modifyProjectFilesLoading}
          destroyOnClose={true}
          title="Upload File(s)"
          okButtonProps={{ disabled: uploadFiles.length == 0 }}
          visible={isUploadModalVisible}
          onOk={_e =>
            modifyProjectFiles({
              variables: {
                action: 'ADD_OR_UPDATE',
                projectUuid: props.contextUuid,
                designerBusinessUuid: props.designerBusinessUuid,
                projectFiles: transformToModifyProjectFile(uploadFiles)
              }
            })
          }
          onCancel={_e => setIsUploadModalVisible(false)}
        >
          <DiscussionFilesUpload
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
            contextUuid={props.contextUuid}
          />
        </Modal>
      </div>
    </div>
  );
}
