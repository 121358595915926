import React, { Component } from 'react';
import { Form, Row, Col, Input, Radio, Button, Alert } from 'antd';
import { FormComponentProps, ValidateCallback } from 'antd/lib/form';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import './CreateTeamInviteDrawerForm.less';
import { BusinessContextInterface } from '../../Routes';
import { teamInvite, GET_TEAM_INVITES } from './TeamView';
import { TeamInviteContext } from '../../../Invite/TeamInvite';

export const CREATE_TEAM_INVITE = gql`
  mutation CreateTeamInvite(
    $businessUuid: String!
    $email: String!
    $inviteContext: TeamInviteContext!
  ) {
    createTeamInvite(
      businessUuid: $businessUuid
      email: $email
      inviteContext: $inviteContext
    ) {
      email
      isActive
      inviteToken
      uuid
    }
  }
`;

interface CreateTeamInviteDrawerFormProps {
  businessContext: BusinessContextInterface;
  teamContext: TeamInviteContext;
  onClose(): void;
}

interface stateProps {
  email: string;
}

interface teamInviteData {
  createTeamInvite: teamInvite;
}

interface MutationVariables {
  email: string;
  businessUuid: string;
  inviteContext: string;
}

const ROW_GUTTER_SPACING = 16;

class CreateTeamInviteDrawerFormComponent extends Component<
  FormComponentProps<stateProps> & CreateTeamInviteDrawerFormProps
> {
  public state: stateProps = {
    email: ''
  };

  public handleSubmit = (
    e: { preventDefault: () => void },
    createTeamInviteMutation: () => void
  ) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values: stateProps) => {
      if (!err) {
        this.setState(
          {
            ...values
          },
          () => {
            createTeamInviteMutation();
          }
        );
      } else {
        console.log('ERROR!');
      }
    });
  };

  public render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;

    return (
      <Mutation<teamInviteData, MutationVariables>
        onCompleted={() => this.props.onClose()}
        onError={() => {
          // do nothing
        }}
        mutation={CREATE_TEAM_INVITE}
        variables={{
          businessUuid: this.props.businessContext.uuid,
          email: this.state.email,
          inviteContext: TeamInviteContext[this.props.teamContext]
        }}
        update={(cache, { data: teamInviteMutationResultData }) => {
          if (teamInviteMutationResultData) {
            const teamInviteData = cache.readQuery<{
              teamInvites: teamInvite[];
            }>({
              query: GET_TEAM_INVITES,
              variables: {
                businessId: this.props.businessContext.uuid,
                inviteContext: TeamInviteContext[this.props.teamContext]
              }
            });

            cache.writeQuery({
              data: {
                teamInvites:
                  teamInviteData == null
                    ? []
                    : teamInviteData.teamInvites.concat([
                        teamInviteMutationResultData.createTeamInvite
                      ])
              },
              query: GET_TEAM_INVITES,
              variables: {
                businessId: this.props.businessContext.uuid,
                inviteContext: TeamInviteContext[this.props.teamContext]
              }
            });
          }
        }}
      >
        {(
          createTeamInvite: () => void,
          { loading: loading, error: error, called: inviteCreateCalled }
        ) => (
          <div data-testid="create-invite-drawer-form">
            <Form
              onSubmit={e => this.handleSubmit(e, createTeamInvite)}
              layout="vertical"
              hideRequiredMark
            >
              <Row gutter={ROW_GUTTER_SPACING}>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        message: 'Please enter a valid email address',
                        type: 'email'
                      },
                      {
                        message: 'Please enter a valid email address',
                        required: true
                      }
                    ],
                    validateTrigger: 'onBlur'
                  })(
                    <Input
                      size="large"
                      data-testid="email-input"
                      placeholder="Please enter an email address"
                    />
                  )}
                </Form.Item>
              </Row>
              <Row gutter={ROW_GUTTER_SPACING}>
                {error ? (
                  <Alert
                    message="Oops"
                    data-testid="create-invite-error-dialog"
                    description={
                      error
                        ? error.message.replace('GraphQL error: ', '')
                        : 'something went wrong'
                    }
                    type="warning"
                    closable
                    showIcon
                  />
                ) : null}
              </Row>

              <div className="TeamInviteDrawerButtonFormContainer">
                <Button
                  data-testid="create-invite-cancel-button"
                  disabled={loading}
                  onClick={this.props.onClose}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="create-invite-submit-button"
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                >
                  Create
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Mutation>
    );
  }
}

export const CreateTeamInviteDrawerForm = Form.create<
  FormComponentProps<stateProps> & CreateTeamInviteDrawerFormProps
>()(CreateTeamInviteDrawerFormComponent);
