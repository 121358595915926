import React, { Component } from 'react';
import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
  Redirect
} from 'react-router';
import './Lifecycle.less';

import { Steps, Typography, Select, Progress } from 'antd';
import MediaQuery from 'react-responsive';
import { number, string } from 'prop-types';
import { Review } from './Review/Review';
import { pdSignedStorageUrlObj } from '../ProjectContentView';
import { BusinessContextInterface, pdProject } from '../../../../Routes';
import { Development } from './Development/Development';

const { Step } = Steps;

const TWENTY = 20;

interface LifecycleProps {
  businessContext: BusinessContextInterface;
  project: pdProject;
  storageUrlObj: pdSignedStorageUrlObj;
}

interface matchParams {
  projectUuid: string;
}

interface stateProps {
  current: number;
}

const ProjectStatusToStep: Record<string, number> = {
  REVIEW: 0,
  DEVELOPMENT: 1,
  APPROVAL: 2,
  COMPLETE: 3
};

class LifecycleComponent extends Component<
  RouteComponentProps<matchParams> & LifecycleProps
> {
  public state: stateProps = {
    current: ProjectStatusToStep[this.props.project.projectStatus]
  };

  public static getDerivedStateFromProps(
    props: LifecycleProps,
    state: stateProps
  ): Partial<stateProps> | null {
    if (ProjectStatusToStep[props.project.projectStatus] !== state.current) {
      return {
        current: ProjectStatusToStep[props.project.projectStatus]
      };
    } else return null;
  }

  public onChangeStatus = (current: number) => {
    this.setState({ current });
  };

  public render(): JSX.Element {
    const { current } = this.state;
    return (
      <div className="sourcing-project-lifecycle-container">
        <Switch>
          <Route
            path={`${this.props.match.path}/review`}
            render={props => (
              <Review
                businessContext={this.props.businessContext}
                handleChangeStatus={this.onChangeStatus}
                storageUrlObj={this.props.storageUrlObj}
                project={this.props.project}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/development`}
            render={props => <Development project={this.props.project} />}
          />
          <Route
            render={() => {
              const lifecycleStatus = this.props.project.projectStatus.toLowerCase();
              return (
                <Redirect
                  to={`/sourcing/projects/${this.props.match.params.projectUuid}/lifecycle/${lifecycleStatus}`}
                />
              );
            }}
          />
        </Switch>
        <Steps
          progressDot
          current={current}
          direction="vertical"
          onChange={this.onChangeStatus}
          className="sourcing-project-lifecycle-stepper"
        >
          <Step title="Review" />
          <Step title="Develop" />
          <Step title="Approval" />
          <Step status="wait" title="Complete" />
        </Steps>
      </div>
    );
  }
}

export const Lifecycle = withRouter(LifecycleComponent);
