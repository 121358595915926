import React, { Component } from 'react';
import { Button, Dropdown, Layout, Menu, Icon, Typography, Drawer } from 'antd';
import Auth from '../../Auth/Auth';
import { Data, business } from '../../Home/Home';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import './Dashboard.less';
import { ClickParam } from 'antd/lib/menu';
import MediaQuery from 'react-responsive';
import {
  AuthIdentityConsumer,
  AuthIdentityInterface
} from '../../Auth/AuthIdentityContext';
import { Settings } from './Settings/Settings';

import { BusinessContextInterface } from '../Routes';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

interface FactoryDashboardProps {
  data: Data;
  businessContext: BusinessContextInterface;
  businessContextChange(businessContext: business): void;
}

interface dashboardStateProps {
  collapsed: boolean;
  mobileDrawerVisible: boolean;
}

const sideBarMenu = (props: RouteComponentProps) => (
  <Menu
    data-testid="side-bar-menu"
    className="factorySideBarMenu"
    mode="inline"
    defaultSelectedKeys={['1']}
  >
    <Menu.Item key="1">
      <Icon type="edit" />
      <span>Projects</span>
    </Menu.Item>
    <Menu.Item key="2">
      <Icon type="container" />
      <span>Orders</span>
    </Menu.Item>
    <Menu.Item key="3">
      <Icon type="credit-card" />
      <span>Payments</span>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to={`${props.match.url}/settings`}>
        <Icon type="setting" />
        <span>Settings</span>
      </Link>
    </Menu.Item>
  </Menu>
);

class FactoryDashboard extends Component<
  RouteComponentProps & FactoryDashboardProps
> {
  public state: dashboardStateProps = {
    collapsed: true,
    mobileDrawerVisible: false
  };

  public showMobileDrawer = () => {
    this.setState({
      mobileDrawerVisible: true
    });
  };

  public onMobileDrawerClose = () => {
    this.setState({
      mobileDrawerVisible: false
    });
  };

  public handleBusinessMenuClick = (e: ClickParam) => {
    const selectedBusinessContext = _.find(this.props.data.businesses, {
      uuid: e.key
    });
    if (selectedBusinessContext != undefined) {
      this.props.businessContextChange(selectedBusinessContext);
    }
  };

  public businessMenu = () => {
    const businessMenuItems = this.props.data.businesses.map(
      (biz: business, index: number) => (
        <Menu.Item className="factorySwitchDashboardMenuItem" key={biz.uuid}>
          <Icon
            type={
              biz.businessType == 'FACTORY' || biz.businessType == 'DESIGNER'
                ? 'shop'
                : 'team'
            }
          />
          {biz.name}
        </Menu.Item>
      )
    );

    return (
      <Menu
        data-testid="dashboard-switch-menu"
        onClick={this.handleBusinessMenuClick}
      >
        <Menu.ItemGroup key="g1" title="My Accounts">
          {businessMenuItems}
        </Menu.ItemGroup>
      </Menu>
    );
  };

  public profileHeader = (authContext: AuthIdentityInterface) => (
    <div data-testid="profile-header-container">
      <Text strong>Hello, </Text>
      <Dropdown trigger={['click']} overlay={this.profileMenu}>
        <span>
          <Text
            data-testid="profile-welcome-name"
            underline
            strong
            className="factory-profile-menu"
          >
            {authContext['http://makerspalm.com/user_metadata'].first_name}{' '}
            {authContext['http://makerspalm.com/user_metadata'].last_name}
          </Text>
          <Icon className="factory-profile-menu" type="down" />
        </span>
      </Dropdown>
    </div>
  );

  public profileMenu = () => (
    <Menu
      data-testid="profile-menu-dropdown"
      onClick={this.handleProfileMenuClick}
    >
      <Menu.Item key="1">
        <Text strong>Log out</Text>
      </Menu.Item>
    </Menu>
  );

  public handleProfileMenuClick = (e: ClickParam) => {
    Auth.logout();
  };

  public render(): JSX.Element {
    return (
      <AuthIdentityConsumer>
        {authContext => (
          <Layout className="factoryDashboard">
            <MediaQuery query="(min-device-width: 768px)">
              <Sider
                data-testid="factory-dashboard-sidebar"
                theme="light"
                className="factorySideBar"
                collapsed={true}
              >
                <div>
                  <Dropdown
                    className="factoryBusinessSelect"
                    trigger={['click']}
                    overlay={this.businessMenu}
                  >
                    <Button
                      data-testid="dashboard-switch-button"
                      size="large"
                      shape="circle"
                      className="factoryDashboardSwitchButton"
                    >
                      <Icon type="shop" />
                      <Icon style={{ marginLeft: 1 }} type="down" />
                    </Button>
                  </Dropdown>
                </div>

                {sideBarMenu(this.props)}
              </Sider>
              <Layout style={{ backgroundColor: '#e3e8ee' }}>
                <Header className="factoryDashboardHeader">
                  {this.profileHeader(authContext)}
                </Header>
                <Content>
                  <Switch>
                    <Route
                      path={`${this.props.match.path}/settings`}
                      render={props => (
                        <Settings
                          businessContext={this.props.businessContext}
                        />
                      )}
                    />
                  </Switch>
                </Content>
              </Layout>
            </MediaQuery>

            <MediaQuery query="(max-device-width: 767px)">
              <Layout>
                <Header className="factoryDashboardHeaderMobile">
                  <Button
                    data-testid="mobile-drawer-show-button"
                    size="large"
                    shape="circle"
                    onClick={this.showMobileDrawer}
                  >
                    <Icon type="menu"></Icon>
                  </Button>
                  {this.profileHeader(authContext)}
                </Header>
                <Drawer
                  placement="left"
                  closable={true}
                  bodyStyle={{ background: '#e3e8ee', minHeight: '100%' }}
                  onClose={this.onMobileDrawerClose}
                  visible={this.state.mobileDrawerVisible}
                >
                  <div
                    className="factoryMobileDrawerContent"
                    data-testid="mobile-dashboard-drawer"
                  >
                    <Dropdown
                      className="factoryBusinessSelect"
                      trigger={['click']}
                      overlay={this.businessMenu}
                    >
                      <Button
                        data-testid="dashboard-switch-mobile-button"
                        size="large"
                        style={{ boxShadow: 'none' }}
                      >
                        <Icon type="shop" />
                        {this.props.businessContext.name}
                        <Icon type="down" />
                      </Button>
                    </Dropdown>
                  </div>
                  {sideBarMenu(this.props)}
                </Drawer>
                <Content style={{ backgroundColor: '#e3e8ee' }}>
                  <Switch>
                    <Route
                      path={`${this.props.match.path}/settings`}
                      render={props => (
                        <Settings
                          businessContext={this.props.businessContext}
                        />
                      )}
                    />
                  </Switch>
                </Content>
              </Layout>
            </MediaQuery>
          </Layout>
        )}
      </AuthIdentityConsumer>
    );
  }
}
export const Dashboard = withRouter(FactoryDashboard);
