import React, { Component } from 'react';
import './Dashboard.less';
import {
  Button,
  Typography,
  Layout,
  Menu,
  Icon,
  Badge,
  Dropdown,
  Input,
  Avatar,
  Drawer
} from 'antd';
import Auth from '../../Auth/Auth';
import { Data, business } from '../../Home/Home';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import {
  AuthIdentityConsumer,
  AuthIdentityInterface
} from '../../Auth/AuthIdentityContext';
import MediaQuery from 'react-responsive';
import { BusinessContextInterface } from '../Routes';
import { ClickParam } from 'antd/lib/menu';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Settings } from './Settings/Settings';
import { Projects } from './Projects/Projects';
const { Header, Sider, Content } = Layout;
const { Search } = Input;
const { Text } = Typography;

interface DesignerDashboardProps {
  data: Data;
  businessContext: BusinessContextInterface;
  businessContextChange(business: business): void;
}

const notifMenu = (
  <Menu>
    <Menu.Item key="0">
      <a href="http://www.alipay.com/">1st menu item</a>
    </Menu.Item>
    <Menu.Item key="1">
      <a href="http://www.taobao.com/">2nd menu item</a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3">3rd menu item</Menu.Item>
  </Menu>
);

const sideBarMenu = (props: RouteComponentProps) => (
  <Menu data-testid="side-bar-menu" mode="inline" defaultSelectedKeys={['1']}>
    <Menu.Item key="1">
      <Link to={`${props.match.url}/projects`}>
        <Icon type="edit" />
        <span>Projects</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Icon type="container" />
      <span>Orders</span>
    </Menu.Item>
    <Menu.Item key="3">
      <Icon type="credit-card" />
      <span>Payments</span>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to={`${props.match.url}/settings`}>
        <Icon type="setting" />
        <span>Settings</span>
      </Link>
    </Menu.Item>
  </Menu>
);

class DesignerDashboard extends Component<
  RouteComponentProps & DesignerDashboardProps
> {
  public state = {
    mobileDrawerVisible: false
  };

  public showMobileDrawer = () => {
    this.setState({
      mobileDrawerVisible: true
    });
  };

  public onMobileDrawerClose = () => {
    this.setState({
      mobileDrawerVisible: false
    });
  };

  public searchBar = () => (
    <Search
      size="large"
      placeholder="Search..."
      className="designerDashboardSearch"
      data-testid="search-bar"
    />
  );

  public notifButton = () => (
    <Dropdown overlay={notifMenu} trigger={['click']}>
      <Button
        data-testid="notif-button"
        size="large"
        shape="circle"
        style={{ boxShadow: 'none' }}
      >
        <Badge style={{ backgroundColor: '#FFA464' }} count={2}>
          <Icon type="bell" className="designerNotifIcon"></Icon>
        </Badge>
      </Button>
    </Dropdown>
  );

  public handleBusinessMenuClick = (e: ClickParam) => {
    const selectedBusinessContext = _.find(this.props.data.businesses, {
      uuid: e.key
    });
    if (selectedBusinessContext != undefined) {
      this.props.businessContextChange(selectedBusinessContext);
    }
  };

  public businessMenu = () => {
    const businessMenuItems = this.props.data.businesses.map(
      (biz: business, index: number) => (
        <Menu.Item className="switchDesignerDashboardMenuItem" key={biz.uuid}>
          <Icon
            className="businessIcon"
            type={biz.businessType == 'SOURCING' ? 'team' : 'shop'}
          />
          {biz.name}
        </Menu.Item>
      )
    );

    return (
      <Menu
        data-testid="dashboard-switch-menu"
        onClick={this.handleBusinessMenuClick}
      >
        <Menu.ItemGroup key="g1" title="My Accounts">
          {businessMenuItems}
        </Menu.ItemGroup>
      </Menu>
    );
  };

  public profileAvatar = (authContext: AuthIdentityInterface) => (
    <div>
      <Dropdown
        placement="bottomCenter"
        trigger={['click']}
        overlay={this.profileMenu(authContext)}
      >
        <div
          data-testid="profile-avatar-container"
          className="profileSideSection"
        >
          <Avatar className="designerAvatar" size="large">
            <Text strong className="designerAvatarText">
              {authContext['http://makerspalm.com/user_metadata'].first_name
                .charAt(0)
                .toUpperCase()}
            </Text>
          </Avatar>
          <Icon className="designerProfileMenuOpenIcon" type="down" />
        </div>
      </Dropdown>
    </div>
  );

  public profileMenu = (authContext: AuthIdentityInterface) => (
    <Menu
      data-testid="profile-menu-dropdown"
      onClick={this.handleProfileMenuClick}
    >
      <Menu.ItemGroup
        key="g1"
        title={
          authContext['http://makerspalm.com/user_metadata'].first_name +
          ' ' +
          authContext['http://makerspalm.com/user_metadata'].last_name
        }
      >
        <Menu.Item key="1">
          <Text strong>Log out</Text>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  public handleProfileMenuClick = (e: ClickParam) => {
    Auth.logout();
  };

  public sideBar = (authContext: AuthIdentityInterface) => (
    <div>
      <div className="designerSideBarDashboardSwitch">
        <Dropdown trigger={['click']} overlay={this.businessMenu}>
          <Button
            data-testid="designer-dashboard-switch-button"
            size="default"
            style={{ boxShadow: 'none', border: 'none' }}
          >
            <Icon
              className="selectedDesignerDashboardBusinessIcon"
              type="shop"
            />
            <Text className="selectedDesignerDashboardBizName" strong>
              {' '}
              {this.props.businessContext.name}{' '}
            </Text>
            <Icon
              className="selectedDesignerDashboardBizMenuOpenIcon"
              type="down"
            />
          </Button>
        </Dropdown>
      </div>
      <div className="designerSideBarMenu">
        {this.profileAvatar(authContext)}
        {sideBarMenu(this.props)}
      </div>
    </div>
  );

  public render(): JSX.Element {
    return (
      <div data-testid="designer-dashboard-container">
        <AuthIdentityConsumer>
          {authContext => (
            <Layout className="designerDashboard">
              <MediaQuery query="(min-device-width: 768px)">
                <Sider
                  data-testid="designer-dashboard-sidebar"
                  theme="light"
                  className="designerSideBar"
                >
                  {this.sideBar(authContext)}
                </Sider>

                <Layout className="designerDashboardLayout">
                  <Header className="designerDashboardHeader">
                    {this.searchBar()}
                    {this.notifButton()}
                  </Header>
                  <Content style={{ paddingLeft: 50, paddingRight: 50 }}>
                    <Switch>
                      <Route
                        path={`${this.props.match.path}/projects`}
                        render={props => (
                          <Projects
                            businessContext={this.props.businessContext}
                          />
                        )}
                      />
                      <Route
                        path={`${this.props.match.path}/settings`}
                        render={props => (
                          <Settings
                            businessContext={this.props.businessContext}
                          />
                        )}
                      />
                    </Switch>
                  </Content>
                </Layout>
              </MediaQuery>
              <MediaQuery query="(max-device-width: 767px)">
                <Layout className="designerDashboardLayout">
                  <Header className="designerDashboardHeaderMobile">
                    <Button
                      data-testid="mobile-drawer-show-button"
                      size="large"
                      shape="circle"
                      onClick={this.showMobileDrawer}
                    >
                      <Icon type="menu"></Icon>
                    </Button>
                    {this.searchBar()}
                    {this.notifButton()}
                  </Header>
                  <Drawer
                    placement="left"
                    closable={true}
                    bodyStyle={{ background: '#fffff', minHeight: '100%' }}
                    onClose={this.onMobileDrawerClose}
                    visible={this.state.mobileDrawerVisible}
                  >
                    <div data-testid="mobile-dashboard-drawer">
                      {this.sideBar(authContext)}
                    </div>
                  </Drawer>
                  <Content>
                    <Switch>
                      <Route
                        path={`${this.props.match.path}/projects`}
                        render={props => (
                          <Projects
                            businessContext={this.props.businessContext}
                          />
                        )}
                      />
                      <Route
                        path={`${this.props.match.path}/settings`}
                        render={props => (
                          <Settings
                            businessContext={this.props.businessContext}
                          />
                        )}
                      />
                    </Switch>
                  </Content>
                </Layout>
              </MediaQuery>
            </Layout>
          )}
        </AuthIdentityConsumer>
      </div>
    );
  }
}

export const Dashboard = withRouter(DesignerDashboard);
