import React, { Component } from 'react';
import { Form, Row, Col, Input, Radio, Button, Alert, Modal } from 'antd';
import { FormComponentProps, ValidateCallback } from 'antd/lib/form';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { BusinessContextInterface } from '../../../Routes';
import { unsubmittedProject, GET_UNSUBMITTED_PROJECTS } from '../Projects';

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $designerBusinessUuid: String!
    $projectName: String!
    $projectCategory: String!
  ) {
    createProject(
      designerBusinessUuid: $designerBusinessUuid
      projectName: $projectName
      projectCategory: $projectCategory
    ) {
      designerBusinessUuid
      isSubmitted
      projectCategory
      projectName
      projectContext
      projectNotes
      uuid
      projectFiles {
        fileName
        isProjectAvatar
        storageKey
        uuid
      }
    }
  }
`;

const ROW_GUTTER_SPACING = 16;

interface stateProps {
  projectName: string;
  projectCategory: string;
}

interface CreateProjectFormProps {
  businessContext: BusinessContextInterface;
  visible: boolean;
  onClose: () => void;
}

interface project {
  designerBusinessUuid: string;
  isSubmitted: boolean;
  projectCategory: string;
  projectName: string;
  uuid: string;
}

interface createProjectData {
  createProject: project;
}

interface MutationVariables {
  designerBusinessUuid: string;
  projectName: string;
  projectCategory: string;
}

class CreateProjectFormComponent extends Component<
  FormComponentProps<stateProps> & CreateProjectFormProps
> {
  public state: stateProps = {
    projectCategory: '',
    projectName: ''
  };

  public handleSubmit = (
    e: { preventDefault: () => void },
    createProjectMutation: () => void
  ) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values: stateProps) => {
      if (!err) {
        this.setState(
          {
            ...values
          },
          () => {
            createProjectMutation();
          }
        );
      } else {
        console.log('ERROR!');
      }
    });
  };

  public render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;

    return (
      <Mutation<createProjectData, MutationVariables>
        onCompleted={() => this.props.onClose()}
        onError={() => {
          // do nothing
        }}
        mutation={CREATE_PROJECT}
        variables={{
          designerBusinessUuid: this.props.businessContext.uuid,
          projectCategory: this.state.projectCategory,
          projectName: this.state.projectName
        }}
        update={(cache, { data: createProjectMutationData }) => {
          if (createProjectMutationData) {
            const unsubmittedProjectData = cache.readQuery<{
              projects: unsubmittedProject[];
            }>({
              query: GET_UNSUBMITTED_PROJECTS,
              variables: {
                designerBusinessUuid: this.props.businessContext.uuid
              }
            });

            cache.writeQuery({
              data: {
                projects:
                  unsubmittedProjectData == null
                    ? []
                    : [createProjectMutationData.createProject].concat(
                        unsubmittedProjectData.projects
                      )
              },
              query: GET_UNSUBMITTED_PROJECTS,
              variables: {
                designerBusinessUuid: this.props.businessContext.uuid
              }
            });
          }
        }}
      >
        {(
          createProject: () => void,
          { loading: loading, error: error, called: inviteCreateCalled }
        ) => (
          <Modal
            title="Create a new Project"
            visible={this.props.visible}
            centered
            destroyOnClose
            confirmLoading={loading}
            onOk={e => this.handleSubmit(e, createProject)}
            okText="Create"
            onCancel={() => this.props.onClose()}
          >
            <Form
              layout="vertical"
              data-testid="create-new-project-form"
              hideRequiredMark
            >
              <Row gutter={ROW_GUTTER_SPACING}>
                <Form.Item label="Project name">
                  {getFieldDecorator('projectName', {
                    rules: [
                      {
                        message: "Project name can't be blank",
                        required: true
                      }
                    ],
                    validateTrigger: 'onBlur'
                  })(
                    <Input
                      size="large"
                      data-testid="project-name-input"
                      placeholder="Name it anything you want"
                    />
                  )}
                </Form.Item>
              </Row>
              <Row gutter={ROW_GUTTER_SPACING}>
                <Form.Item label="Project Category">
                  {getFieldDecorator('projectCategory', {
                    rules: [
                      {
                        message: 'Please pick a project category',
                        required: true
                      }
                    ],
                    validateTrigger: 'onBlur'
                  })(
                    <Radio.Group
                      size="large"
                      data-testid="project-category-radio"
                      buttonStyle="solid"
                    >
                      <Radio.Button
                        data-testid="radio-project-bags-category-select"
                        value="BAGS"
                      >
                        Bags
                      </Radio.Button>
                      <Radio.Button
                        data-testid="radio-project-furniture-category-select"
                        value="FURNITURE"
                      >
                        Furniture
                      </Radio.Button>
                      <Radio.Button value="HOME_DECOR">Home decor</Radio.Button>
                      <Radio.Button value="JEWELRY">Jewelry</Radio.Button>
                      <Radio.Button value="SHOES">Shoes</Radio.Button>
                      <Radio.Button value="TEXTILES">Textiles</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    );
  }
}

export const CreateProjectForm = Form.create<
  FormComponentProps<stateProps> & CreateProjectFormProps
>()(CreateProjectFormComponent);
