import React, { Component } from 'react';
import './ProjectsView.less';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  BusinessContextInterface,
  projectCategoryMapping
} from '../../../Routes';
import { List, Card, Typography, Tag, Button, Icon } from 'antd';
import { CreateProjectForm } from './CreateProjectForm';
import {
  submittedOrUnsubmitted,
  isSubmittedProject,
  unsubmittedProjectsData,
  submittedProjectsData
} from '../Projects';
import _ from 'lodash';

const { Meta } = Card;

interface ProjectsViewProps {
  businessContext: BusinessContextInterface;
  unsubmittedProjects: unsubmittedProjectsData;
  submittedProjects: submittedProjectsData;
  isMobile: boolean;
  handleSelectProject(project: submittedOrUnsubmitted): void;
}

interface stateProps {
  visible: boolean;
}

class ProjectsViewComponent extends Component<
  ProjectsViewProps & RouteComponentProps
> {
  public state: stateProps = { visible: false };

  public showModal = () => {
    this.setState({
      visible: true
    });
  };

  public handleOk = () => {
    this.setState({
      visible: false
    });
  };

  public handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  public render(): JSX.Element {
    return (
      <div>
        {this.props.unsubmittedProjects.projects.length == 0 &&
        this.props.submittedProjects.pdDesignerProjects.length == 0 ? (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: '20%'
            }}
          >
            <Typography.Title
              data-testid="first-project-welcome-message"
              level={2}
            >
              Welcome! Create your first Project to get started
            </Typography.Title>

            <Icon
              style={{ marginTop: 15, fontSize: 36 }}
              twoToneColor="#4ABDAC"
              onClick={() => this.showModal()}
              theme="twoTone"
              data-testid="first-project-create-button"
              type="plus-circle"
              className="designerNotifIcon"
            ></Icon>
          </div>
        ) : (
          <div>
            <div className="DesignerProjectsHeaderContainer">
              <Typography.Title
                level={this.props.isMobile ? 3 : 2}
                data-testid="settings-page-header"
              >
                Projects
              </Typography.Title>
              <Button
                type="primary"
                shape="round"
                data-testid="new-project-button"
                icon="plus"
                size="default"
                onClick={this.showModal}
              >
                New Project
              </Button>
            </div>
            <List
              data-testid="projects-view-cards-list"
              size="small"
              className="projects-view-card-list"
              grid={
                this.props.isMobile
                  ? { gutter: 8, column: 2 }
                  : { gutter: 16, column: 4 }
              }
              itemLayout="vertical"
              dataSource={_.concat<submittedOrUnsubmitted>(
                this.props.unsubmittedProjects.projects,
                this.props.submittedProjects.pdDesignerProjects
              )}
              renderItem={item => {
                return (
                  <List.Item>
                    <Card
                      className="ProjectViewCard"
                      hoverable
                      data-testid="project-view-card"
                      size="small"
                      onClick={() => this.props.handleSelectProject(item)}
                      cover={<img alt="example" src="/images/sketch.png" />}
                    >
                      <Meta
                        title={item.projectName}
                        description={
                          <span
                            style={{
                              alignContent: 'center',
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography.Text>
                              {projectCategoryMapping[item.projectCategory]}
                            </Typography.Text>
                            {this.renderProjectTag(item)}
                          </span>
                        }
                      />
                    </Card>
                  </List.Item>
                );
              }}
            />
          </div>
        )}

        <CreateProjectForm
          visible={this.state.visible}
          businessContext={this.props.businessContext}
          onClose={this.handleOk}
        />
      </div>
    );
  }

  private renderProjectTag = (item: submittedOrUnsubmitted): JSX.Element => {
    var tagColor = '#CDD8DC';

    if (isSubmittedProject(item)) {
      switch (item.projectStatus) {
        case 'REVIEW':
          tagColor = '#FFC864';
          break;
        case 'DEVELOPMENT':
          tagColor = '#5880C3';
          break;
        default:
          tagColor = '#CDD8DC';
      }
    }

    return (
      <Tag
        data-testid="project-card-tag"
        style={{ borderRadius: 10 }}
        color={tagColor}
      >
        <Typography.Text style={{ color: 'white', textAlign: 'center' }} strong>
          {isSubmittedProject(item) ? item.projectStatus : 'DRAFT'}
        </Typography.Text>
      </Tag>
    );
  };
}

export const ProjectsView = withRouter(ProjectsViewComponent);
