import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';
import { Dashboard } from './Dashboard';
import { Data, business } from '../../Home/Home';
import { Error } from '../../Utils/Error';
import { BusinessContextInterface } from '../Routes';
import _ from 'lodash';
import { Loading } from '../../Utils/Loading';
import { InviteJoining } from '../components/InviteJoining';
import { TeamInviteContext } from '../../Invite/TeamInvite';

interface sourcingProps {
  businessRefetch(): void;
  businessContextChange(business: business): void;
  data: Data;
  businessContext: BusinessContextInterface;
}

class SourcingComponent extends Component<RouteComponentProps & sourcingProps> {
  public componentDidMount(): void {
    if (
      this.props.businessContext == undefined ||
      _.isEmpty(this.props.businessContext)
    ) {
      // we know that a user specifically requested '/sourcing' path in the url if we are inside this function.
      // this could be a user specifically typing it in the browser url bar or just trying refreshing the sourcing dashboard

      this.handleInitialSourcingBusinessContext();
    }
  }

  public render(): JSX.Element {
    if (this.props.data.businesses.length == 0) {
      // reaching this state means the user was apart of a sourcing business but was later removed.
      // not allowed to create a sourcing Business by themselves
      return (
        <InviteJoining
          context={TeamInviteContext.SOURCING_TEAM}
          businessRefetch={this.props.businessRefetch}
        />
      );
    }

    if (
      this.props.businessContext != undefined &&
      !_.isEmpty(this.props.businessContext)
    ) {
      return <Dashboard {...this.props} />;
    } else {
      return <Loading />;
    }
  }

  private handleInitialSourcingBusinessContext(): void {
    const firstSourcingBusinessFound = this.props.data.businesses.find(function(
      element
    ) {
      return element.businessType == 'SOURCING';
    });

    if (firstSourcingBusinessFound == undefined) {
      const firstAnyBusinessType = this.props.data.businesses[0];
      if (firstAnyBusinessType == undefined) {
        // do nothing. the render method below will handle the 'no business found' state
      } else {
        this.props.businessContextChange(firstAnyBusinessType);
      }
    } else {
      this.props.businessContextChange(firstSourcingBusinessFound);
    }
  }
}

export const Sourcing = withRouter(SourcingComponent);
