import React from 'react';
import { OnboardingProps } from '../Onboarding';
import { Typography, Button, Icon, Radio, Collapse } from 'antd';
import { BusinessPlan } from '../Onboarding';

interface BusinessPlanFormProps extends OnboardingProps {
  selectedBusinessPlan: BusinessPlan;
  handleBusinessPlanChange: any;
}

export const BusinessPlanForm = (props: BusinessPlanFormProps) => {
  const { Panel } = Collapse;

  const genBetaSelection = (props: any) => (
    <Radio
      data-testid="beta-radio-option"
      checked={props.selectedBusinessPlan == BusinessPlan.Beta}
      onClick={event => {
        props.handleBusinessPlanChange(BusinessPlan.Beta);
        event.stopPropagation();
      }}
    />
  );

  const genMemberSelection = (props: any) => (
    <Radio
      data-testid="member-radio-option"
      disabled
      checked={props.selectedBusinessPlan == BusinessPlan.Member}
      onClick={event => {
        props.handleBusinessPlanChange(BusinessPlan.Member);

        event.stopPropagation();
      }}
    />
  );

  const genNonMemberSelection = (props: any) => (
    <Radio
      data-testid="nonmember-radio-option"
      disabled
      checked={props.selectedBusinessPlan == BusinessPlan.Nonmember}
      onClick={event => {
        props.handleBusinessPlanChange(BusinessPlan.Nonmember);

        event.stopPropagation();
      }}
    />
  );

  const genPlanHeader = (planName: string, planText: string) => (
    <Typography.Text strong style={{ fontSize: 16 }}>
      {planName}
      <Typography.Text style={{ fontSize: 14 }} type="secondary">
        {planText}
      </Typography.Text>
    </Typography.Text>
  );

  // we are not using class names here because some stylings dont work
  const customPanelStyle = {
    background: '#ebedef',
    borderRadius: 8,
    marginBottom: 24,
    border: '#f7f7f7',
    overflow: 'hidden',
    textAlign: 'left' as 'left',
    fontSize: 16
  };

  return (
    <div>
      <div className="OnboardingContent">
        <Typography.Title level={1}>
          What Plan works best for your Business?
        </Typography.Title>

        <Typography className="OnboardingContentSubText">
          This would be the part where you decide which plan you want. But since
          we just launched, we only offer one special plan for Beta testing at
          the moment
        </Typography>
      </div>
      <div className="OnboardingContent">
        <Collapse
          bordered={false}
          accordion={true}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel
            header={genPlanHeader(
              'Beta',
              '- Only available plan at the moment'
            )}
            key="1"
            style={customPanelStyle}
            extra={genBetaSelection(props)}
          >
            <p>
              We simply charge a 10% service fee on development and production
              of Products
            </p>
          </Panel>
          <Panel
            disabled
            header={'Member - Best for Designers with immediate demand'}
            key="2"
            style={customPanelStyle}
            extra={genMemberSelection(props)}
          >
            <p>No Service fee but a monthly membership</p>
          </Panel>
          <Panel
            disabled
            header="Non-Member - Best for Designers just starting out"
            key="3"
            style={customPanelStyle}
            extra={genNonMemberSelection(props)}
          >
            <p>We charge extra service fee here</p>
          </Panel>
        </Collapse>
      </div>
      <div className="OnboardingContent">
        <div className="OnboardingContentButtons">
          <Button
            type="link"
            style={{ marginRight: 15 }}
            size="large"
            onClick={() => props.prev()}
          >
            Back
          </Button>
          <Button
            size="large"
            data-testid="continue-button"
            type="primary"
            disabled={props.selectedBusinessPlan == undefined}
            onClick={() => props.next()}
            style={{ marginBottom: 25 }}
          >
            Continue
            <Icon type="arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  );
};
