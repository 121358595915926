import React from 'react';
// Using React Context as a global prop for all components who need to access the auth0 identity values

export interface AuthIdentityInterface {
  'http://makerspalm.com/app_metadata': {
    signupContext: string;
    signupSubContext: string;
    businessOnboardEligible: boolean;
  };
  'http://makerspalm.com/user_metadata': {
    first_name: string;
    last_name: string;
  };
  sub: string;
  [key: string]: string | boolean | object;
}

const AuthIdentityContext = React.createContext({} as AuthIdentityInterface);

export const AuthIdentityProvider = AuthIdentityContext.Provider;
export const AuthIdentityConsumer = AuthIdentityContext.Consumer;
export default AuthIdentityContext;
