import React, { Component, useState, useEffect, useRef } from 'react';
import {
  withRouter,
  useHistory,
  Switch,
  Route,
  useRouteMatch,
  useLocation
} from 'react-router';
import './Projects.less';
import { BusinessContextInterface, pdProjectFile } from '../../Routes';
import { Typography, Button, Modal } from 'antd';
import MediaQuery from 'react-responsive';
import { graphql, useQuery } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';

import { ProjectsView } from './components/ProjectsView';
import { Lifecycle } from './Lifecycle/Lifecycle';
import { Loading } from '../../../Utils/Loading';
import _ from 'lodash';

export const GET_UNSUBMITTED_PROJECTS = gql`
  query Projects($designerBusinessUuid: String!) {
    projects(designerBusinessUuid: $designerBusinessUuid) {
      designerBusinessUuid
      isSubmitted
      projectCategory
      projectName
      projectContext
      projectNotes
      uuid
      projectFiles {
        fileName
        isProjectAvatar
        storageKey
        uuid
      }
    }
  }
`;

export const GET_SUBMITTED_PROJECTS = gql`
  query PdDesignerProjects($designerBusinessUuid: String!) {
    pdDesignerProjects(designerBusinessUuid: $designerBusinessUuid) {
      designerBusinessUuid
      projectCategory
      projectStatus
      projectContext
      projectName
      uuid
      projectNotes
      projectFiles {
        authorContext
        authorId
        fileName
        isProjectAvatar
        storageKey
        uuid
        versionId
      }
    }
  }
`;

interface projectProps {
  businessContext: BusinessContextInterface;
}
export type submittedOrUnsubmitted = unsubmittedProject | submittedProject;

export const isSubmittedProject = (
  toBeDetermined: submittedOrUnsubmitted
): toBeDetermined is submittedProject => {
  if ((toBeDetermined as submittedProject).projectStatus) {
    return true;
  }
  return false;
};

export interface unsubmittedProjectsData {
  projects: unsubmittedProject[];
  loading: boolean;
}

export interface submittedProjectsData {
  pdDesignerProjects: submittedProject[];
  loading: boolean;
}

export enum projectContextEnum {
  SAMPLE = 'SAMPLE',
  FILES = 'FILES',
  SCRATCH = 'SCRATCH'
}

export interface projectFile {
  fileName?: string;
  isProjectAvatar?: boolean;
  storageKey?: string;
  uuid?: string;
}

export interface project {
  uuid: string;
  isSubmitted: boolean;
  projectCategory: string;
  projectName: string;
  designerBusinessUuid: string;
  projectContext?: projectContextEnum;
  projectNotes?: string;
}

export interface unsubmittedProject extends project {
  isSubmitted: boolean;
  projectFiles: projectFile[];
}

export interface submittedProject extends project {
  projectStatus: string;
  projectFiles: pdProjectFile[];
}

interface stateProps {
  selectedProject: unsubmittedProject | submittedProject;
}

export function Projects(props: projectProps): JSX.Element {
  const [selectedProject, setSelectedProject] = useState(
    {} as unsubmittedProject | submittedProject
  );

  const {
    loading: unSubmittedProjectsLoading,
    error: unSubmittedProjectsError,
    data: unSubmittedProjectsData
  } = useQuery(GET_UNSUBMITTED_PROJECTS, {
    variables: {
      designerBusinessUuid: props.businessContext.uuid
    }
  });

  const {
    loading: submittedProjectsLoading,
    error: submittedProjectsError,
    data: submittedProjectsData
  } = useQuery(GET_SUBMITTED_PROJECTS, {
    variables: {
      designerBusinessUuid: props.businessContext.uuid
    }
  });

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const prevSelectedProjectRef = useRef(
    {} as unsubmittedProject | submittedProject
  );

  useEffect(() => {
    prevSelectedProjectRef.current = selectedProject;
  });
  const prevSelectProj = prevSelectedProjectRef.current;

  useEffect(() => {
    // resets selected projects state when navigating back to this location.
    // This is needed to handle the back button browser press
    if (location.pathname == match.path) {
      setSelectedProject({} as submittedProject | unsubmittedProject);
    }
  }, [location]);

  useEffect(() => {
    if (
      submittedProjectsData &&
      submittedProjectsData.pdDesignerProjects &&
      selectedProject
    ) {
      const proj = submittedProjectsData.pdDesignerProjects.find(
        (p: submittedProject) => p.uuid == selectedProject.uuid
      );
      if (proj != selectedProject) {
        setSelectedProject(proj);
      }
    }
  }, [submittedProjectsData]);

  useEffect(() => {
    if (!_.isEmpty(selectedProject)) {
      if (prevSelectProj && prevSelectProj.uuid != selectedProject.uuid) {
        history.push(`${match.url}/${selectedProject.uuid}/lifecycle`);
      } else if (prevSelectProj == undefined) {
        history.push(`${match.url}/${selectedProject.uuid}/lifecycle`);
      }
    }
  }, [selectedProject]);

  const renderProjectsPage = (isMobile: boolean) => {
    if (submittedProjectsLoading || unSubmittedProjectsLoading) {
      return (
        <div style={{ height: '100vh' }}>
          {' '}
          <Loading />
        </div>
      );
    }
    return (
      <div>
        <ProjectsView
          isMobile={isMobile}
          businessContext={props.businessContext}
          handleSelectProject={setSelectedProject}
          submittedProjects={submittedProjectsData}
          unsubmittedProjects={unSubmittedProjectsData}
        />
      </div>
    );
  };

  return (
    <Switch>
      <Route
        path={`${match.path}/:projectUuid/lifecycle`}
        render={otherProps => (
          <Lifecycle
            project={selectedProject}
            businessContext={props.businessContext}
          />
        )}
      />
      <Route
        path={`${match.path}/`}
        render={otherProps => {
          return (
            <div>
              <MediaQuery query="(min-device-width: 768px)">
                {renderProjectsPage(false)}
              </MediaQuery>

              <MediaQuery query="(max-device-width: 767px)">
                {renderProjectsPage(true)}
              </MediaQuery>
            </div>
          );
        }}
      />
    </Switch>
  );
}
