import React, { Component } from 'react';
import { Upload, Icon, message, Typography, Button, Divider } from 'antd';
import './ProjectFilesUploadForm.less';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import gql from 'graphql-tag';
import {
  Query,
  Mutation,
  graphql,
  withApollo,
  ChildProps,
  WithApolloClient
} from 'react-apollo';
import compose from 'lodash.flowright';
import {
  unsubmittedProject,
  projectFile,
  projectContextEnum
} from '../../../Projects';
import { InlineLoading } from '../../../../../../Utils/Loading';
import { SimpleInlineError } from '../../../../../../Utils/Error';
import _ from 'lodash';
import { ApolloError, ApolloClient } from 'apollo-boost';
import { UploadFile } from 'antd/lib/upload/interface';
import { enhancedUploadFile, projectDetails } from '../Submit';
import MediaQuery from 'react-responsive';
const { Dragger } = Upload;

const parser = new DOMParser();

const IMAGE_FILE_EXTENSIONS = ['png', 'gif', 'jpeg', 'jpg'];

export const FILES_CONTEXT_UPLOAD_HEADER_TEXT = 'Upload your Project Files';

export const FILES_CONTEXT_UPLOAD_SUB_HEADER_TEXT =
  'It can be any kind of file from pictures to large CAD/design files';

export const SAMPLE_CONTEXT_UPLOAD_HEADER_TEXT =
  'Upload pictures of your Sample';

export const SAMPLE_CONTEXT_UPLOAD_SUB_HEADER_TEXT =
  'Try to include pictures from the most important angles';

export const SCRATCH_CONTEXT_UPLOAD_HEADER_TEXT = 'Upload your reference files';

export const SCRATCH_CONTEXT_UPLOAD_SUB_HEADER_TEXT =
  'These can be any kind of files (usually pictures and text) that gives us an idea of what you want to make';

export const REQUEST_SIGNED_UPLOAD_URL = gql`
  query RequestSignedUploadUrl(
    $designerBusinessUuid: String!
    $projectUuid: String!
  ) {
    requestSignedUploadUrl(
      designerBusinessUuid: $designerBusinessUuid
      projectUuid: $projectUuid
    ) {
      url
      urlFields
    }
  }
`;

export const REQUEST_SIGNED_STORAGE_URL = gql`
  query RequestSignedStorageUrl(
    $designerBusinessUuid: String!
    $projectUuid: String!
  ) {
    requestSignedStorageUrl(
      designerBusinessUuid: $designerBusinessUuid
      projectUuid: $projectUuid
    ) {
      url
      urlParams
    }
  }
`;

export const MODIFY_PROJECT_FILES = gql`
  mutation ModifyProjectFiles(
    $designerBusinessUuid: String!
    $projectUuid: String!
    $projectFiles: [ProjectFileInput]!
    $action: ModifyProjectFilesAction
  ) {
    modifyProjectFiles(
      designerBusinessUuid: $designerBusinessUuid
      projectUuid: $projectUuid
      projectFiles: $projectFiles
      action: $action
    ) {
      designerBusinessUuid
      isSubmitted
      projectCategory
      projectContext
      projectName
      projectNotes
      uuid
      projectFiles {
        uuid
        isProjectAvatar
        storageKey
        fileName
      }
    }
  }
`;

export interface externalRequiredProjectFilesUploadFormProps {
  projectDetails: projectDetails;
  project: { designerBusinessUuid: string; uuid: string };
  currentUploadFiles: enhancedUploadFile[];
  handleUpdateAndNext(updateProjectDetails: () => void): void;
  handleBack(): void;
  updateProjectDetails(): void;
  handleUpdateFile(updatedFile: enhancedUploadFile): void;
  handleRemoveFile(removableFile: UploadFile): void;
}

interface internalProjectFilesUploadFormProps
  extends externalRequiredProjectFilesUploadFormProps {
  requestSignedStorageUrl: signedStorageUrlData;
  requestSignedUploadUrl: signedUploadUrlData;
}

interface signedUploadUrlData {
  requestSignedUploadUrl: {
    url: string;
    urlFields: string;
  };
  loading: boolean;
  error: ApolloError;
  refetch(): void;
}

interface signedStorageUrlData {
  error: ApolloError;
  loading: boolean;
  networkStatus: number;
  requestSignedStorageUrl: {
    url: string;
    urlParams: string;
  };
  refetch(): void;
}

class ProjectFilesUploadFormComponent extends Component<
  RouteComponentProps &
    internalProjectFilesUploadFormProps &
    WithApolloClient<Record<string, any>>
> {
  public handleChange = (info: { file: UploadFile }) => {
    const { status } = info.file;

    if (status === 'uploading') {
      this.props.handleUpdateFile(info.file as enhancedUploadFile);
    } else if (status === 'done') {
      if (info.file.response != undefined) {
        const xmlResponse = parser.parseFromString(
          info.file.response,
          'text/xml'
        );

        this.props.client
          .mutate({
            mutation: MODIFY_PROJECT_FILES,
            variables: {
              designerBusinessUuid: this.props.project.designerBusinessUuid,
              projectFiles: [
                {
                  fileName: info.file.name,
                  storageKey: xmlResponse.getElementsByTagName('Key')[0]
                    .childNodes[0].nodeValue
                }
              ],
              projectUuid: this.props.project.uuid
            }
          })
          .then(results => {
            const relevantFile = _.find(
              results.data.modifyProjectFiles.projectFiles,
              (f: projectFile) => {
                return f.fileName === info.file.name;
              }
            );

            const updatedFileWithUuid = info.file as enhancedUploadFile;

            updatedFileWithUuid.mpUuid = relevantFile.uuid;
            updatedFileWithUuid.storageKey = relevantFile.storageKey;

            this.props.handleUpdateFile(updatedFileWithUuid);

            message.success(`${info.file.name} file uploaded successfully.`);
          })
          .catch(error => {
            const errorFile = info.file;

            errorFile.status = 'error';

            this.props.handleUpdateFile(errorFile as enhancedUploadFile);

            message.error(`${info.file.name} file upload failed.`);
          });
      }
    } else if (status === 'error') {
      this.props.handleUpdateFile(info.file as enhancedUploadFile);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  public handleRemove = (file: UploadFile) => {
    const relevantFile = _.find(
      this.props.currentUploadFiles,
      (f: enhancedUploadFile) => {
        return f.uid === file.uid;
      }
    );

    if (relevantFile) {
      // intentionally not handling error since if it does fail, user will just try to remove it again
      this.props.client.mutate({
        mutation: MODIFY_PROJECT_FILES,
        variables: {
          action: 'REMOVE',
          designerBusinessUuid: this.props.project.designerBusinessUuid,
          projectFiles: [
            {
              uuid: relevantFile.mpUuid
            }
          ],
          projectUuid: this.props.project.uuid
        }
      });
    }
    this.props.handleRemoveFile(file);

    return true;
  };

  public generateDraggerProps = (signedUrlData: signedUploadUrlData) => {
    return {
      action: signedUrlData.requestSignedUploadUrl.url,
      multiple: true,
      name: 'file'
    };
  };

  public renderUploadContainer = () => {
    if (
      this.props.requestSignedUploadUrl.loading ||
      this.props.requestSignedStorageUrl.loading
    ) {
      return (
        <div className="FilesUploadContentContainer">
          <InlineLoading />
        </div>
      );
    }
    if (
      this.props.requestSignedUploadUrl.error ||
      _.isEmpty(this.props.requestSignedUploadUrl.requestSignedUploadUrl)
    ) {
      return (
        <div className="FilesUploadContentContainer">
          <SimpleInlineError />
          <div>
            <Typography.Text>Uh Oh! something went wrong</Typography.Text>
            <div>
              <Button
                loading={this.props.requestSignedUploadUrl.loading}
                type="primary"
                size="small"
                onClick={() => this.props.requestSignedUploadUrl.refetch()}
              >
                Try Again
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (this.props.requestSignedUploadUrl.requestSignedUploadUrl) {
      return (
        <div data-testid="project-file-upload-form" className="uploadDiv">
          <Dragger
            onChange={info => this.handleChange(info)}
            showUploadList={false}
            fileList={this.props.currentUploadFiles}
            data={(file: UploadFile) => {
              return {
                ...JSON.parse(
                  this.props.requestSignedUploadUrl.requestSignedUploadUrl
                    .urlFields
                ),
                'Content-Type': file.type
              };
            }}
            {...this.generateDraggerProps(this.props.requestSignedUploadUrl)}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
          <MediaQuery query="(max-device-width: 767px)">
            <Divider className="uploadDivider" type="horizontal" />
          </MediaQuery>
          <MediaQuery query="(min-device-width: 768px)">
            <Divider className="uploadDivider" type="vertical" />
          </MediaQuery>
          {this.props.currentUploadFiles.length == 0 ? (
            <div className="FilesUploadListPlaceholder">
              <Icon style={{ color: 'lightgray', fontSize: 18 }} type="file" />
              <Typography.Text strong disabled>
                Uploaded files will show here
              </Typography.Text>
            </div>
          ) : (
            <Upload
              onRemove={file => this.handleRemove(file)}
              listType="picture"
              fileList={this.enhanceWithBucketStorageUrl(
                this.props.currentUploadFiles
              )}
            />
          )}
        </div>
      );
    }
  };

  public render(): JSX.Element {
    return (
      <div
        data-testid="project-file-upload-form"
        style={{
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: 'inherit',
          justifyContent: 'space-evenly'
        }}
      >
        <span data-testid="project-file-upload-form-header">
          <Typography.Title level={2}>
            {this.determineHeaderText()}
          </Typography.Title>
          <Typography.Text className="project-upload-subtext">
            {this.determineHeaderSubText()}
          </Typography.Text>
        </span>
        <span>{this.renderUploadContainer()}</span>
        <span>
          <Button
            type="link"
            data-testid="new-project-button"
            size="large"
            style={{ width: 100 }}
            onClick={() => this.props.handleBack()}
          >
            Back
          </Button>
          <Button
            type="primary"
            data-testid="project-file-upload-form-next-button"
            size="large"
            className="project-upload-next-button"
            onClick={() =>
              this.props.handleUpdateAndNext(this.props.updateProjectDetails)
            }
          >
            Next
          </Button>
        </span>
      </div>
    );
  }

  private enhanceWithBucketStorageUrl = (
    currentUploadFiles: enhancedUploadFile[]
  ) => {
    const new_url = this.props.requestSignedStorageUrl.requestSignedStorageUrl
      .url;
    const params = this.props.requestSignedStorageUrl.requestSignedStorageUrl
      .urlParams;
    const newlist = _.map(currentUploadFiles, (file: enhancedUploadFile) => {
      const isImage = _.includes(
        IMAGE_FILE_EXTENSIONS,
        file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2)
      );

      if (file.status === 'done') {
        file.url = new_url + file.storageKey + params;
        file.thumbUrl = isImage
          ? new_url + file.storageKey + params
          : new_url + file.storageKey; // if not an image then point to 403 http response which converts to file icon as thumbnail
        return file;
      } else return file;
    });
    return newlist;
  };

  private determineHeaderText = (): string => {
    switch (this.props.projectDetails.projectContext) {
      case projectContextEnum.FILES:
        return FILES_CONTEXT_UPLOAD_HEADER_TEXT;
      case projectContextEnum.SAMPLE:
        return SAMPLE_CONTEXT_UPLOAD_HEADER_TEXT;
      case projectContextEnum.SCRATCH:
        return SCRATCH_CONTEXT_UPLOAD_HEADER_TEXT;
      default:
        return FILES_CONTEXT_UPLOAD_HEADER_TEXT;
    }
  };

  private determineHeaderSubText = (): string => {
    switch (this.props.projectDetails.projectContext) {
      case projectContextEnum.FILES:
        return FILES_CONTEXT_UPLOAD_SUB_HEADER_TEXT;
      case projectContextEnum.SAMPLE:
        return SAMPLE_CONTEXT_UPLOAD_SUB_HEADER_TEXT;
      case projectContextEnum.SCRATCH:
        return SCRATCH_CONTEXT_UPLOAD_SUB_HEADER_TEXT;
      default:
        return FILES_CONTEXT_UPLOAD_SUB_HEADER_TEXT;
    }
  };
}
export const ProjectFilesUploadForm = compose(
  graphql(REQUEST_SIGNED_STORAGE_URL, {
    name: 'requestSignedStorageUrl',
    options: (props: internalProjectFilesUploadFormProps) => ({
      variables: {
        designerBusinessUuid: props.project.designerBusinessUuid,
        projectUuid: props.project.uuid
      }
    })
  }),
  graphql(REQUEST_SIGNED_UPLOAD_URL, {
    name: 'requestSignedUploadUrl',
    options: (props: internalProjectFilesUploadFormProps) => ({
      variables: {
        designerBusinessUuid: props.project.designerBusinessUuid,
        projectUuid: props.project.uuid
      }
    })
  })
  //@ts-ignore
)(withRouter(withApollo(ProjectFilesUploadFormComponent)));
